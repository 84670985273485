import React from 'react';
import {connect} from 'react-redux';
import * as ExternalApi from '../services/ExternalApi';
import {constructFilterProjectDetails, constructParamsProjectDetails, queryStringFormatter} from '../helpers/helper';
import {setFilterProjectDetail, openFilterProjectDetail} from '../components/ProjectFilter/action';
import PropertyModal from '../components/ProjectDetail/Property/PropertyModal';
// import ResultsTable from '../components/ProjectDetail/ImageMaps/ResultsTable';
import BasicContactForm from '../components/BasicContactForm/BasicContactForm';
import {
  createMarketingMedia,
} from '../components/ProjectDetail/Promotion/action';
import {createContactFormInformation} from '../components/BasicContactForm/action';
import {createCoordinateMaps} from '../components/DetailMaps/action';
// import * as DrupalApi from '../services/DrupalApi';
import {
  createProjectDetail,
  createLabelTranslate,
  createGetProjectPropertiesDetail,
  createTemplateConfig,
} from './action';
import PropTypes from 'prop-types';
import {BubleLoading} from '../helpers/loading';
import {ENTITY_TPHOME} from '../helpers/constants';
import ImageMapsNew from '../components/ProjectDetail/ImageMaps/ImageMapsNew';
import {
  openPropertyModal,
} from '../components/ProjectDetail/Property/action';
import {withTranslation} from 'react-i18next';

/**
 * Page For Minisite
 */
class PageMinisite extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      projectName: '',
      projectAddress: '',
      projectDescription: '',
      projectID: '',
      language: '',
      imageLinks: [],
      mediaId: [],
      media: {},
      contactInformation: {},
      coordinate: {},
      floorImageDetails: [],
      mainImageDetails: {},
      getProjectDetail: {},
      projectPropertiesDetail: {},
      getProjectPropertiesDetail: {},
      getMediaBannerDataAll: {},
      paramsProjectProperties: {},
      classLoading: 'project-detail-container',
      isLoading: true,
      displayProperty: 'unset',
      listShowhouse: [],
      options: [],
      templateCode: '',
      tabViews: {},
      isLoadFilter: false,
      locationUrl: this.props.location,
      phase: '',
      lockList: false,
      listOnly: true,
    };
    this.updateProjectProperties=this.updateProjectProperties.bind(this);
    this.filterProjectProperties=this.filterProjectProperties.bind(this);
    this.handleTabViews=this.handleTabViews.bind(this);
  };
  /**
  * Load Componnet
  */
  async componentDidMount() {
    const [mediaBanner] = await Promise.all([
      ExternalApi.getPromotionBanner(),
    ]);
    const getMediaBannerDataAll = mediaBanner.data;
    this.setState({
      getMediaBannerDataAll: getMediaBannerDataAll,
      templateCode: this.props.templateConfig.template,
    });

    const language = this.props.i18n.language;

    const searchParams = queryStringFormatter(window.location.search);

    const dtProject = await ExternalApi.getIdProjectFromPrEpl(searchParams.PrEpl, language);

    this.setState({
      projectID: dtProject.data.projectInformation.id,
      projectName: dtProject.data.projectInformation.name,
    });

    await this.props.createProjectDetail({
      _project_id: dtProject.data.projectInformation.id,
      _language: language,
    });

    const paramProjectDetail = this.props.getProjectDetail;

    if (paramProjectDetail.language !== '' &&
    paramProjectDetail.projectDetails.projectId !== '' ) {
      let filteringPrice = {
        priceMin: '',
        priceMax: '',
      };
      let filteringRoom = {
        numberOfRoomsMin: '',
        numberOfRoomsMax: '',
      };
      let filteringSurface = {
        minNumberOfM2: '',
        maxNumberOfM2: '',
      };
      let filterTypeProject = [1, 2, 3, 4, 5];
      let filterDetailProject = {};
      let filterAvailable = [];
      let filterImmediatelyAvailable = false;

      // Get data and try to overwrite
      const res = await ExternalApi.getProjectDetails(
          paramProjectDetail,
      );

      // #352718 : No need to set any price if there is no filter active
      // Api is receive null value base on #316842
      filteringPrice = {
        priceMin: null,
        priceMax: null,
      };


      // this value is represent the filter from the front page
      const filterParams = await this.props.getFilterProjectDetail.entities;
      const storageFilterPrice = filterParams[res.data.projectDetails.projectId];
      // try to overwrite price if already on front page
      if (filterParams[res.data.projectDetails.projectId] !== undefined &&
        filterParams[res.data.projectDetails.projectId].filterPrice != null) {
        filteringPrice = {
          priceMin: storageFilterPrice.filterPrice.min,
          priceMax: storageFilterPrice.filterPrice.max,
        };
      }

      if (filterParams[res.data.projectDetails.projectId] !== undefined &&
        filterParams[res.data.projectDetails.projectId].filterRoom != null) {
        filteringRoom = {
          numberOfRoomsMin: storageFilterPrice.filterRoom.Min,
          numberOfRoomsMax: storageFilterPrice.filterRoom.Max,
        };
      }

      if (filterParams[res.data.projectDetails.projectId] !== undefined &&
        filterParams[res.data.projectDetails.projectId].filterSurface != null) {
        filteringSurface = {
          minNumberOfM2: storageFilterPrice.filterSurface.Min,
          maxNumberOfM2: storageFilterPrice.filterSurface.Max,
        };
      }

      if (filterParams[res.data.projectDetails.projectId] !== undefined &&
        filterParams[res.data.projectDetails.projectId].filterType != null) {
        filterTypeProject = storageFilterPrice.filterType;
      }

      if (filterParams[res.data.projectDetails.projectId] !== undefined &&
        filterParams[res.data.projectDetails.projectId].filterAvailable != null) {
        filterAvailable = storageFilterPrice.filterAvailable;
      }

      if (filterParams[res.data.projectDetails.projectId] !== undefined &&
        filterParams[res.data.projectDetails.projectId].filterImmediatelyAvailable != null) {
        filterImmediatelyAvailable = storageFilterPrice.filterImmediatelyAvailable;
      }

      await this.props.createGetProjectPropertiesDetail({
        _filtering_baseProject: paramProjectDetail.projectDetails.projectId,
        _filtering_page: '',
        _filtering_price: filteringPrice,
        _filtering_room: filteringRoom,
        _filtering_surface: filteringSurface,
        _filtering_type_general: '',
        _filtering_type: filterTypeProject,
        _filtering_buildingCode: '',
        _filtering_country_code: this.props.templateConfig.country,
        _filtering_available: filterAvailable,
        isImmediatelyAvailable: filterImmediatelyAvailable,
        language: language,
      });

      const paramProjectPropertiesDetail = this.props.getProjectPropertiesDetail;
      paramProjectPropertiesDetail.paging.pageSize = 999;

      if (Object.keys(paramProjectPropertiesDetail).length != 0) {
        if (paramProjectPropertiesDetail.projectId !== '') {
          const projectPropertiesDetail = await ExternalApi.getProjectProperties(
              paramProjectPropertiesDetail,
          );
          paramProjectPropertiesDetail.paging.currentPage = 1;
          projectPropertiesDetail.data;

          this.setState({
            getProjectPropertiesDetail: projectPropertiesDetail.data,
            projectPropertiesDetail: projectPropertiesDetail.data,
          });

          filterDetailProject = constructFilterProjectDetails({
            generalizedTypeOfProperty:
              typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
              typeof (filterParams[paramProjectPropertiesDetail.projectId].filterType) === 'undefined'||
              filterParams[paramProjectPropertiesDetail.projectId].filterType === null?
              []:filterParams[paramProjectPropertiesDetail.projectId].filterType,
            minNumberOfM2:
              typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
              typeof (filterParams[paramProjectPropertiesDetail.projectId].filterSurface) === 'undefined'||
              filterParams[paramProjectPropertiesDetail.projectId].filterSurface === null?
              null:filterParams[paramProjectPropertiesDetail.projectId].filterSurface.Min,
            maxNumberOfM2:
              typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
              typeof (filterParams[paramProjectPropertiesDetail.projectId].filterSurface) === 'undefined'||
              filterParams[paramProjectPropertiesDetail.projectId].filterSurface === null?
              null:filterParams[paramProjectPropertiesDetail.projectId].filterSurface.Max,
            priceMin:
              typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
              typeof (filterParams[paramProjectPropertiesDetail.projectId].filterPrice) === 'undefined'||
              filterParams[paramProjectPropertiesDetail.projectId].filterPrice === null?
              null:filterParams[paramProjectPropertiesDetail.projectId].filterPrice.min,
            priceMax:
              (typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
              typeof (filterParams[paramProjectPropertiesDetail.projectId].filterPrice) === 'undefined')||
              filterParams[paramProjectPropertiesDetail.projectId].filterPrice === null?
              null:filterParams[paramProjectPropertiesDetail.projectId].filterPrice.max,
            numberOfRoomsMin:
              projectPropertiesDetail.data &&
              projectPropertiesDetail.data.globalFilters &&
              'numberOfRooms' in projectPropertiesDetail.data.globalFilters &&
              'min' in projectPropertiesDetail.data.globalFilters.numberOfRooms &&
              projectPropertiesDetail.data.globalFilters.numberOfRooms.min !== null?
              projectPropertiesDetail.data.globalFilters.numberOfRooms.min: null,
            numberOfRoomsMax:
              projectPropertiesDetail.data &&
              projectPropertiesDetail.data.globalFilters &&
              'numberOfRooms' in projectPropertiesDetail.data.globalFilters &&
              'max' in projectPropertiesDetail.data.globalFilters.numberOfRooms &&
              projectPropertiesDetail.data.globalFilters.numberOfRooms.max !== null?
              projectPropertiesDetail.data.globalFilters.numberOfRooms.max: null,
            projectId: paramProjectPropertiesDetail.projectId,
            availableProperties: projectPropertiesDetail.data.avaibleProperties,
            count: projectPropertiesDetail.data.count,
            minSurface: projectPropertiesDetail.data.filters ? projectPropertiesDetail.data.globalFilters.surfaces.min : null,
            maxSurface: projectPropertiesDetail.data.filters ? projectPropertiesDetail.data.globalFilters.surfaces.max : null,
            minPrice: projectPropertiesDetail.data.filters ? projectPropertiesDetail.data.filters.prices.min : null,
            maxPrice: projectPropertiesDetail.data.filters ? projectPropertiesDetail.data.filters.prices.max : null,
            isImmediatelyAvailable: (typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
              typeof (filterParams[paramProjectPropertiesDetail.projectId].isImmediatelyAvailable) === 'undefined')||
            filterParams[paramProjectPropertiesDetail.projectId].isImmediatelyAvailable === null ?
              false : filterParams[paramProjectPropertiesDetail.projectId].isImmediatelyAvailable,
            language: language,
          });
        }
      }

      // Data `res` get from query project detail
      const result = res.data;
      if (result.projectDetails) {
        filterDetailProject = constructFilterProjectDetails({
          generalizedTypeOfProperty:
            typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterType) === 'undefined'||
            filterParams[paramProjectPropertiesDetail.projectId].filterType === null?
            []:filterParams[paramProjectPropertiesDetail.projectId].filterType,
          minNumberOfM2:
            typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterSurface) === 'undefined'||
            filterParams[paramProjectPropertiesDetail.projectId].filterSurface === null?
            null:filterParams[paramProjectPropertiesDetail.projectId].filterSurface.Min,
          maxNumberOfM2:
            typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterSurface) === 'undefined'||
            filterParams[paramProjectPropertiesDetail.projectId].filterSurface === null?
            null:filterParams[paramProjectPropertiesDetail.projectId].filterSurface.Max,
          priceMin:
            typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterPrice) === 'undefined'||
            filterParams[paramProjectPropertiesDetail.projectId].filterPrice === null?
            null:filterParams[paramProjectPropertiesDetail.projectId].filterPrice.min,
          priceMax:
            (typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterPrice) === 'undefined')||
            filterParams[paramProjectPropertiesDetail.projectId].filterPrice === null?
            null:filterParams[paramProjectPropertiesDetail.projectId].filterPrice.max,
          numberOfRoomsMin:
            typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterRoom) === 'undefined'||
            filterParams[paramProjectPropertiesDetail.projectId].filterRoom === null?
            null:filterParams[paramProjectPropertiesDetail.projectId].filterRoom.Min,
          numberOfRoomsMax:
            typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].filterRoom) === 'undefined'||
            filterParams[paramProjectPropertiesDetail.projectId].filterRoom === null?
            null:filterParams[paramProjectPropertiesDetail.projectId].filterRoom.Max,
          projectId: paramProjectPropertiesDetail.projectId,
          availableProperties: typeof (filterDetailProject.availableProperties) === 'undefined'?
            0:filterDetailProject.availableProperties,
          count: typeof (filterDetailProject.count) === 'undefined'?
            0:filterDetailProject.count,
          minSurface: typeof (filterDetailProject.surfaceOptions) === 'undefined'? null: filterDetailProject.surfaceOptions.minNumberOfM2,
          maxSurface: typeof (filterDetailProject.surfaceOptions) === 'undefined'? null: filterDetailProject.surfaceOptions.maxNumberOfM2,
          minPrice: result.projectDetails.priceMin ? result.projectDetails.priceMin : null,
          maxPrice: result.projectDetails.priceMax ? result.projectDetails.priceMax : null,
          buildingCode: '',
          isImmediatelyAvailable: (typeof (filterParams[paramProjectPropertiesDetail.projectId]) === 'undefined' ||
            typeof (filterParams[paramProjectPropertiesDetail.projectId].isImmediatelyAvailable) === 'undefined')||
          filterParams[paramProjectPropertiesDetail.projectId].isImmediatelyAvailable === null ?
            false : filterParams[paramProjectPropertiesDetail.projectId].isImmediatelyAvailable,
          language: language,
        });
      }
      await this.props.setFilterProjectDetail(filterDetailProject);
      this.props.openFilterProjectDetail(paramProjectPropertiesDetail.projectId);

      let contactInformation = result.projectDetails.projectContact;
      let contactState = {
        showModal: false,
      };
      if (contactInformation == null) {
        contactInformation = {};
      }
      contactInformation.companyName = result.projectDetails.companyName;
      contactInformation.entity = result.projectDetails.entity;
      contactInformation.sociCode = result.projectDetails.sociCode;
      contactInformation.projectName = result.projectDetails.projectName;
      contactInformation.projectId = result.projectDetails.projectId;
      contactInformation.projectCoordinate =
          result.projectDetails.projectCoordinate;

      //  if there was param contact, show contact form
      const urlString = window.location.href;
      const url = new URL(urlString);
      const c = url.searchParams.get('contact');
      if (c) {
        contactState = {
          showModal: true,
          purpose:
            'visit_demonstration_apartment',
          ...contactInformation,
        };
      }

      if (typeof result !== 'undefined' &&
          result !== null) {
        // dispatch proccess
        this.props.createMarketingMedia(result.projectDetails.mediaTemplates);
        this.props.createContactFormInformation(contactState);
        await this.props.createCoordinateMaps({
          lat: result.projectDetails.projectCoordinate.lat,
          lng: result.projectDetails.projectCoordinate.lon,
        });

        this.setState({
          projectID: paramProjectPropertiesDetail.projectId,
          project: result.projectDetails,
          imageLinks: result.projectDetails.imageLinks,
          projectName: result.projectDetails.projectName,
          projectAddress: result.projectDetails.address,
          projectDescription: result.projectDetails.description,
          mediaId: result.projectDetails.mediaTemplates,
          media: result.projectDetails.media,
          contactInformation: contactInformation,
          contactFormInformation: contactState,
          coordinate: {
            lat: result.projectDetails.projectCoordinate.lat,
            lng: result.projectDetails.projectCoordinate.lon,
          },
          floorImageDetails: result.projectDetails.floorImageDetails,
          mainImageDetails: result.projectDetails.mainImageDetails,
          getProjectDetail: result.projectDetails,
          paramsProjectProperties: paramProjectPropertiesDetail,
          displayProperty: (result.projectDetails.noPropertyProject) ? 'none' : 'unset',
          sitePlansDetails: result.projectDetails.sitePlansDetails ?? {},
        });
        if (result.projectDetails.entity === ENTITY_TPHOME) {
          const resultContactPoint = await ExternalApi.getShowHouseContactPoints({
            language: language,
          });
          const listShowhouse = [];
          const options = [];
          for (const item of resultContactPoint.data.projectContactPoints) {
            listShowhouse[item.id] = {
              'id': item.id,
              'title': item.title,
              'type': item.type,
              'openingHoursInfo': item.openingHoursInfo,
              'coordinates': item.coordinates,
              'address': item.address,
              'phoneNumber': item.phoneNumber,
            };
            options.push({
              'value': item.id,
              'label': item.title,
            });
          };
          this.setState({
            options,
            listShowhouse,
          });
        }
      }

      this.setState({
        classLoading: 'project-detail-container',
        isLoading: false,
      });

      const activeTab = searchParams.activeTab;

      const pathUrl = window.location.pathname;
      if (pathUrl.indexOf('liste_lot') > -1) {
        this.setState({
          listOnly: true,
        });
        this.handleTabViews('list', false);
      } else if (pathUrl.indexOf('implantation') > -1) {
        const hasPlan = res.data.projectDetails.sitePlansDetails && Object.keys(res.data.projectDetails.sitePlansDetails).length > 0;
        this.setState({
          listOnly: !(hasPlan),
        });

        if (activeTab === 'list') {
          this.handleTabViews('list', hasPlan);
        } else {
          this.handleTabViews(hasPlan ? 'image' : 'list', hasPlan);
        }
      }
    }
  }
  /**
   * update Componnet\
   *     @param {*} buildingCode
   *     @param {*} floorCode
   *     @param {*} propertiesId
   */
  async updateProjectProperties(buildingCode='', floorCode='', propertiesId='') {
    const paramProjectDetail = this.props.getProjectDetail;
    const str = [];
    str[0]= (propertiesId);
    if (paramProjectDetail.language !== '' &&
      paramProjectDetail.projectId !== '' ) {
      const filteringPrice = {
        priceMin: '',
        priceMax: '',
      };
      const filteringRoom = {
        numberOfRoomsMin: '',
        numberOfRoomsMax: '',
      };
      const filteringSurface = {
        minNumberOfM2: '',
        maxNumberOfM2: '',
      };
      let filteringBuilding = {
        // projectPropertyCode: buildingCode,
        shapeCode: buildingCode,
      };
      if (buildingCode===''&&floorCode===''&&str[0]==='') {
        filteringBuilding='';
      }
      if (str[0]!=='') {
        filteringBuilding = {
          // projectPropertyCode: buildingCode,
          floorCode: floorCode,
          propertiesId: str,
          shapeCode: buildingCode,
        };
      }
      if (floorCode!==''&&str[0]==='') {
        filteringBuilding = {
          // projectPropertyCode: buildingCode,
          shapeCode: buildingCode,
          floorCode: floorCode,
        };
      }
      if (str[0]!==''&&buildingCode===''&&floorCode==='') {
        filteringBuilding = {
          propertiesId: str,
        };
      }

      await this.props.createGetProjectPropertiesDetail({
        _filtering_baseProject: paramProjectDetail.projectDetails.projectId,
        _filtering_page: '',
        _filtering_price: filteringPrice,
        _filtering_room: filteringRoom,
        _filtering_surface: filteringSurface,
        _filtering_type_general: '',
        _filtering_type: '',
        _filtering_buildingCode: filteringBuilding,
        _filtering_country_code: this.props.templateConfig.country,
        isImmediatelyAvailable: false,
        language: this.props.i18n.language,
      });
      await this.props.setFilterProjectDetail({id: paramProjectDetail.projectDetails.projectId, buildingCode: filteringBuilding});
      const paramProjectPropertiesDetail =
        this.props.getProjectPropertiesDetail;

      paramProjectPropertiesDetail.paging.pageSize = 999;
      paramProjectPropertiesDetail.paging.currentPage = 1;

      if (Object.keys(paramProjectPropertiesDetail).length != 0) {
        if (paramProjectPropertiesDetail.projectId !== '') {
          const projectPropertiesDetail =
            await ExternalApi.getProjectProperties(
                paramProjectPropertiesDetail,
            );
          this.setState({
            getProjectPropertiesDetail: projectPropertiesDetail.data,
            projectPropertiesDetail: projectPropertiesDetail.data,
            paramsProjectProperties: paramProjectPropertiesDetail,
          });
          // todo save to props filter
        }
      }

      const res = await ExternalApi.getProjectDetails(
          paramProjectDetail,
      );
      const result = res.data;
      let contactInformation = result.projectDetails.projectContact;
      const contactState = {
        showModal: false,
      };
      if (contactInformation == null) {
        contactInformation = {};
      }
      contactInformation.companyName = result.projectDetails.companyName;
      contactInformation.entity = result.projectDetails.entity;
      contactInformation.sociCode = result.projectDetails.sociCode;
      contactInformation.projectName = result.projectDetails.projectName;
      contactInformation.projectId = result.projectDetails.projectId;
      contactInformation.projectCoordinate =
            result.projectDetails.projectCoordinate;

      if (typeof result !== 'undefined' &&
            result !== null) {
        // dispatch proccess
        this.props.createMarketingMedia(result.projectDetails.mediaTemplates);
        this.props.createContactFormInformation(contactState);
        await this.props.createCoordinateMaps({
          lat: result.projectDetails.projectCoordinate.lat,
          lng: result.projectDetails.projectCoordinate.lon,
        });

        this.setState({
          ...this.state,
          project: result.projectDetails,
          imageLinks: result.projectDetails.imageLinks,
          projectName: result.projectDetails.projectName,
          projectAddress: result.projectDetails.address,
          projectDescription: result.projectDetails.description,
          mediaId: result.projectDetails.mediaTemplates,
          media: result.projectDetails.media,
          contactInformation: contactInformation,
          contactFormInformation: contactState,
          coordinate: {
            lat: result.projectDetails.projectCoordinate.lat,
            lng: result.projectDetails.projectCoordinate.lon,
          },
          floorImageDetails: result.projectDetails.floorImageDetails,
          mainImageDetails: result.projectDetails.mainImageDetails,
          getProjectDetail: result.projectDetails,
          paramsProjectProperties: paramProjectPropertiesDetail,
        });
      }
    }
  }
  /**
   * update result table from filter
   * @param {func} callback
   */
  async filterProjectProperties(callback) {
    if (this.state.isLoadFilter) {
      const filterParams = await this.props.getFilterProjectDetail.entities;

      if (filterParams.hasOwnProperty(this.state.projectID)) {
        filterParams[this.state.projectID].page = 1;
        const params = constructParamsProjectDetails(filterParams[this.state.projectID]);
        if (params.countries == undefined ) {
          params.countries = [this.props.templateConfig.country];
        }
        if (params.projectPropertiesSearchCriteria.countries[0] == null || params.projectPropertiesSearchCriteria.countries[0] == undefined ) {
          params.projectPropertiesSearchCriteria.countries = [this.props.templateConfig.country];
        }
        params.paging.currentPage=1;
        params.paging.pageSize=999;

        const projectPropertiesDetail =
            await ExternalApi.getProjectProperties(
                params,
            );
        params.paging.currentPage=1;
        this.setState({
          getProjectPropertiesDetail: projectPropertiesDetail.data,
          projectPropertiesDetail: projectPropertiesDetail.data,
          paramsProjectProperties: params,
        });
        if (callback) callback();
      }
    }
    this.setState({isLoadFilter: true});
  }
  /**
   * handle to show view plan or list, in minisite will only trigger once
   *     @param {string} mode ['list' or 'image']
   *     @param {bool} isHavePlan
  **/
  handleTabViews(mode, isHavePlan) {
    if (mode !== 'list') {
      const classForImage = !isHavePlan ?
        'collapse-navbar-list-item hide' :
        'collapse-navbar-list-item is-active';

      const classForList = !isHavePlan ?
        'collapse-navbar-list-item is-active' :
        'collapse-navbar-list-item';

      this.setState({
        tabViews: {
          imageTabClass: classForImage,
          listTabClass: classForList,
          display: 'image',
          firstLoad: false,
        },
      });
    } else {
      const classForImage = !isHavePlan ?
        'collapse-navbar-list-item hide' :
        'collapse-navbar-list-item';

      this.setState({
        tabViews: {
          imageTabClass: classForImage,
          listTabClass: 'collapse-navbar-list-item is-active',
          display: 'list',
          firstLoad: false,
        },
        isLoadFilter: true,
      });
    }
  }

  /**
   * Render component.
   * @return {any}
   */
  render() {
    const contactForm = <BasicContactForm
      contactInformation={this.state.contactInformation}
    />;
    return (this.state.isLoading || !this.state.templateCode || this.state.project.length === 0) ? (
      <BubleLoading key = {Math.random()} text="Loading" />
    ) : (
      <div className="project-detail-container mini-site-filter-wrapper">
        <div className="project-implantation-react" id="FilterProjects">
          <div className="project-implantation-react__container">
            { this.state.displayProperty && (<div style={{display: this.state.displayProperty}}>
              <ImageMapsNew
                floorImageDetails={this.state.floorImageDetails}
                mainImageDetails={this.state.mainImageDetails}
                updateProjectProperties={this.updateProjectProperties}
                filterProjectProperties={this.filterProjectProperties}
                getProjectDetail={this.state.getProjectDetail}
                createGetProjectPropertiesDetail={this.props.createGetProjectPropertiesDetail}
                project={this.state.project}
                tabViews={this.state.tabViews}
                handleTabViews={this.handleTabViews}
                getProjectPropertiesDetail={this.state.getProjectPropertiesDetail}
                projectPropertiesDetail={this.state.projectPropertiesDetail}
                getMediaBannerDataAll={this.state.getMediaBannerDataAll}
                paramsProjectProperties = {this.state.paramsProjectProperties}
                contactInformation={this.state.contactInformation}
                location={this.state.locationUrl}
                setFilterProjectDetail={this.props.setFilterProjectDetail}
                sitePlansDetails={this.state.sitePlansDetails}
                openPropertyModal={this.props.openPropertyModal}
                templateConfig={this.props.templateConfig}
                listOnly={this.state.listOnly}
                hideImmediatelyAvailableFilter={true}
              />
            </div>)}
          </div>
          <PropertyModal
            getMediaBannerDataAll={this.state.getMediaBannerDataAll}
            mediaId={this.state.mediaId}
            templateCode={this.state.templateCode}
            templateConfig={this.props.templateConfig}
          />
          {contactForm}
        </div>
      </div>
    );
  };
}

PageMinisite.propTypes = {
  match: PropTypes.object,
  projectID: PropTypes.object,
  createProjectDetail: PropTypes.func,
  createGetProjectPropertiesDetail: PropTypes.func,
  getProjectDetail: PropTypes.object,
  getProjectPropertiesDetail: PropTypes.object,
  mediaId: PropTypes.array,
  projectAddress: PropTypes.string,
  dataMarketingMedia: PropTypes.func,
  floorImageDetails: PropTypes.array,
  mainImageDetails: PropTypes.object,
  createMarketingMedia: PropTypes.func,
  template: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  contactInformation: PropTypes.object,
  createContactFormInformation: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  getMediaBannerDataAll: PropTypes.object,
  getPurchasePageLastUrl: PropTypes.string,
  getFilterProjectDetail: PropTypes.objectOf(PropTypes.any),
  setFilterProjectDetail: PropTypes.func,
  openFilterProjectDetail: PropTypes.func,
  templateConfig: PropTypes.object,
  createTemplateConfig: PropTypes.func,
  location: PropTypes.object,
  createCoordinateMaps: PropTypes.func,
  openPropertyModal: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createProjectDetail,
      createLabelTranslate,
      createGetProjectPropertiesDetail,
      createTemplateConfig,
      setFilterProjectDetail,
      openFilterProjectDetail,
      createMarketingMedia,
      createCoordinateMaps,
      createContactFormInformation,
      openPropertyModal,
    },
)(withTranslation()(PageMinisite));
