export const OPEN_PROPERTY_MODAL = '[PROPERTY MODAL] OPEN';
export const CLOSE_PROPERTY_MODAL = '[PROPERTY MODAL] CLOSE';

/**
 *
 * @return {object}
 */
export const closePropertyModal = () => {
  return {
    type: CLOSE_PROPERTY_MODAL,
  };
};

/**
 *
 * @param {*} options
 * @return {object}
 */
export const openPropertyModal = (options) => {
  return {
    type: OPEN_PROPERTY_MODAL,
    options,
  };
};
