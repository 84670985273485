export const HIDE_MESSAGE = '[MESSAGE] CLOSE';
export const SHOW_MESSAGE = '[MESSAGE] SHOW';

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

/**
 *
 * @param {*} options
 *
 * @return {object}
 */
export const showMessage = (options) => {
  return {
    type: SHOW_MESSAGE,
    options,
  };
};
