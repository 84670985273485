export const OPEN_PROJECT_FILTER = '[FILTER] OPEN PROJECT FILTER';
export const SET_PROJECT_FILTER = '[FILTER] SET PROJECT FILTER';
export const RESET_PROJECT_FILTER = '[FILTER] RESET PROJECT FILTER';

export const createMapLocation = (params) =>{
  const data = {
    type: 'MAP_LOCATION',
    payload: params,
  };
  return data;
};

export const toggleFilter = (params) => {
  return {
    type: 'TOGGLE_FILTER',
    payload: params,
  };
};

export const openFilterProjectDetail = (params) => {
  return {
    type: OPEN_PROJECT_FILTER,
    payload: params,
  };
};

export const setFilterProjectDetail = (params) => {
  return {
    type: SET_PROJECT_FILTER,
    payload: params,
  };
};

export const resetFilterProjectDetail = () => {
  return {
    type: RESET_PROJECT_FILTER,
  };
};

export const toggleFilterMobile = (params) => {
  return {
    type: 'TOGGLE_FILTER_MOBILE',
    payload: params,
  };
};
