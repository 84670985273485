import * as DrupalApi from '../services/DrupalApi';

/* eslint-disable */
/**
 * @return {any}
 */
export function projectIdFromUrl() {
  return window.location.pathname.split('/').pop();
};

/**
* @return {any}
* @param {number} min .
* @param {number} max .
*/
export function rangePriceLabel(min, max) {
  const minPrice = (typeof (min) === 'undefined') ? 0 : min;
  const maxPrice = (typeof (max) === 'undefined') ? 0 : max;

  let labelPrice = `€ ${minPrice} - € ${maxPrice} `;
  if (!minPrice && !maxPrice) {
    labelPrice = '€ -';
  }
  return labelPrice;
}

/**
* @return {any}
* @param {number} number .
*/
export const formatNumber = (number) =>{
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

/**
* @return {any}
* @param {string} query .
*/
export const queryStringFormatter = (query) =>{
  let result = {};
  query.split('&').forEach(val => {
    let value = Array.from(val.split(/=(.*)/).slice(0, 2)).reduce((key, val) => {
      let decodeId = key.replace(/\?/, '').trim();
      return {
        [decodeId]: decodeURIComponent(val),
      }
    });
    result = { ...result, ...value };
  });
  return result;
};

/**
* @return {any}
* @param {country} country
* reference : https://stackoverflow.com/questions/37774259/google-maps-js-api-get-the-north-west-south-east-bounds
*/
export const countryBounding = (country) => {
  const boundingBox = {
    BE: {
      north: 51.5516667, 
      south: 49.4969821,
      east: 6.408097,
      west: 2.3889137,
    },
    FR: {
      north: 51.66675846534492,
      south: 49.114506869524725,
      east: 4.994341869669396,
      west: 0.35373403580709173
    },
    LU: {
      north: 50.21669661863678,
      south: 49.177016315842316,
      east: 6.971836566505414,
      west: 5.23160862880705
    }
  }
  return boundingBox[country];
};

export function getBoundDefault(map) {
  const aNorth= map.getBounds().getNorthEast().lat();
  const aEast= map.getBounds().getNorthEast().lng();
  const aSouth= map.getBounds().getSouthWest().lat();
  const aWest= map.getBounds().getSouthWest().lng();
  const area = {
    north: aNorth,
    south: aSouth,
    east: aEast,
    west: aWest,
  };
  return area;
}

export const constructFilterProjectDetails = (params) => {
  return {
    id: params.projectId,
    availableProperties: (typeof (params.availableProperties) === 'undefined') ? 0 : params.availableProperties,
    count: (typeof (params.count) === 'undefined') ? 0 : params.count,
    filterType: (typeof (params.generalizedTypeOfProperty) === 'undefined') ? [] : params.generalizedTypeOfProperty,
    filterSurface: (typeof (params.minNumberOfM2) === 'undefined' || params.minNumberOfM2 === null) ? null : {Min: params.minNumberOfM2, Max: params.maxNumberOfM2},
    filterPrice: (typeof (params.priceMin) === 'undefined' || params.priceMin === null) ? null : {min: params.priceMin, max: params.priceMax},
    filterRoom: (typeof (params.numberOfRoomsMin) === 'undefined'|| params.numberOfRoomsMin === null) ? null : {Min: params.numberOfRoomsMin, Max: params.numberOfRoomsMax},
    roomOptions: {
      numberOfRoomsMin: (typeof (params.minRooms) === 'undefined') ? 0 : params.minRooms,
      numberOfRoomsMax: (typeof (params.maxRooms) === 'undefined') ? 5 : params.maxRooms,
    },
    surfaceOptions: {
      minNumberOfM2: (typeof (params.minSurface) === 'undefined') ? 0 : params.minSurface,
      maxNumberOfM2: (typeof (params.maxSurface) === 'undefined') ? 3185: params.maxSurface,
    },
    priceOptions: {
      priceMin: (typeof (params.minPrice) === 'undefined') ? 0 : params.minPrice,
      priceMax: (typeof (params.maxPrice) === 'undefined') ? 900000 : params.maxPrice
    },
    buildingCode: (typeof (params.buildingCode) === 'undefined') ? '' : params.buildingCode,
    isImmediatelyAvailable: (typeof (params.isImmediatelyAvailable) === 'undefined') ? '' : params.isImmediatelyAvailable,
    language: params.language,
  }
}

export const constructParamsProjectDetails = (params) => {
  if (params._filtering_iframe!==undefined) {
    return {
      'projectId': params._filtering_baseProject,
      'paging': {
        'currentPage': params._filtering_page.page,
        'pageSize': 7,
      },
      'projectPropertiesSearchCriteria': {
        'priceMin': params._filtering_price.priceMin,
        'priceMax': params._filtering_price.priceMax,
        'numberOfRoomsMin': params._filtering_room.numberOfRoomsMin,
        'numberOfRoomsMax': params._filtering_room.numberOfRoomsMax,
        'minNumberOfM2': params._filtering_surface.minNumberOfM2,
        'maxNumberOfM2': params._filtering_surface.maxNumberOfM2,
        'typeOfProperty': params._filtering_type_general,
        'generalizedTypeOfProperty': params._filtering_type,
        'isImmediatelyAvailable': (typeof (params.isImmediatelyAvailable) === 'undefined') ? false : params.isImmediatelyAvailable,
        'countries': [
          params._filtering_country_code,
        ],
      },
      'buildingCode': params._filtering_buildingCode,
      'iFrameCode': {
        'iFrameEnseCode': params._filtering_iframe.iFrameEnseCode,
        "iFrameCptEplCode": params._filtering_iframe.iFrameCptEplCode,
      },
      'language': params.language ? params.language : 'fr',
    }
  } else {
    return {
      'projectId': params.id,
      'paging': {
        'currentPage': params.page!==undefined?1:params.page,
        'pageSize': 7,
      },
      'projectPropertiesSearchCriteria': {
        'priceMin': params.filterPrice!==undefined && params.filterPrice!== null?params.filterPrice.min:'',
        'priceMax': params.filterPrice!==undefined && params.filterPrice!== null?params.filterPrice.max:'',
        'numberOfRoomsMin': params.filterRoom!==undefined && params.filterRoom!== null?params.filterRoom.Min:'',
        'numberOfRoomsMax': params.filterRoom!==undefined && params.filterRoom!== null?params.filterRoom.Max:'',
        'minNumberOfM2': params.filterSurface!==undefined && params.filterSurface!== null?params.filterSurface.Min:'',
        'maxNumberOfM2': params.filterSurface!==undefined && params.filterSurface!== null?params.filterSurface.Max:'',
        'typeOfProperty': params._filtering_type_general!==undefined?params._filtering_type_general:'',
        'generalizedTypeOfProperty': params.filterType!==undefined?params.filterType:[],
        'isImmediatelyAvailable': (typeof (params.isImmediatelyAvailable) === 'undefined') ? false : params.isImmediatelyAvailable,
        'countries': [
          params._filtering_country_code,
        ],
        'propertiesAvailableSoldOption': params.filterAvailable!==undefined && params.filterAvailable!==null?params.filterAvailable:[],
      },
      'buildingCode': params.buildingCode!==undefined?params.buildingCode:'',
      'language': params.language ? params.language : 'fr',
      "sortByPropertyColumn": params.sortedProps!==undefined && params.sortedProps!==null ? params.sortedProps.sortByPropertyColumn:null,
      "sortByAscOrDesc": params.sortedProps!==undefined && params.sortedProps!==null ? params.sortedProps.sortByAscOrDesc:"asc",
    }
  }
}

/**
* @return {any}
* @param {file} file .
*/
export const iconURL = (file) => {
  if (!file) {
    return
  }
 return `${process.env.REACT_APP_HOST}/static/assets/images/icons/${file}`
}


export async function localStorageTemplate() {
  if(localStorage.getItem('template')) {
    const template = JSON.parse(localStorage.getItem('template'));
    const validDate = Date.parse(template.valid_until);
    const now = Date.parse(Date('now'));

    if(validDate > now){
      localStorage.removeItem('template')
      localStorageTemplate();
    }else{
      window.$COUNTRY = template.country
      window.$COUNTRY_CODE = template.country_code
      window.$TEMPLATE_CODE = template.template
    }

  }
  else{
    const [template] = await Promise.all([
      DrupalApi.getTemplateConfig(),
    ]);
    window.$COUNTRY = template.data.country
    window.$COUNTRY_CODE = template.data.country_code
    window.$TEMPLATE_CODE = template.data.template
    localStorage.setItem('template',JSON.stringify(template.data));
  }

}


/**
 * Remove selected cluster
 */
export function removeSelectedCluster(){
  const pins = document.getElementsByClassName('pin');
  for (let i = 0; i < pins.length; i++) {
    pins[i].classList.remove('pin-selected');
  };
}

/**
 * Generate title from project details
 */
export function createTitleFromProject(project) {
  let alt,type;

  if(project.countOfTypeOfPropertiesInProject){
    try{
      type = project.countOfTypeOfPropertiesInProject[0].typeOfPropertyTranslation;
      alt =  `${type} à ${project.projectProvince}, ${project.projectName}`;
    }catch(error){
      alt = project.projectName;
    }
  }

  if (project.countOfGeneralizedPropertyTypes) {
    try {
      if(project.countOfGeneralizedPropertyTypes !== undefined && Object.keys(project.countOfGeneralizedPropertyTypes).length > 0 ){
        let url = []
        Object.keys(project.countOfGeneralizedPropertyTypes).map((v)=>{
          let value = v.replace(/\s/g,'-');
          return url.push(value)
        })
        type = url.join('-et-')
      } else {
        type = 'Project';
      }

      alt =  `${type} à ${project.provinceName}, ${project.projectName}`
    } catch (error) {
      alt = 'Project Detail';
    }
  }

  return alt;
}

/**
 * Reorder project images
 */
export function orderImages(params) {
  let refImages = [];
  let otherImages = [];
  let orderedImages = [];
  let checkImageOrder = params.find((element) => element.imageOrder !== 0 );

  params.map((image, i) => {
    const filePosition = image.url.split("_").pop().split(".").shift();
    if (image.isRef || image.url.includes('REF')) {
      refImages.push(image);
    } else {
      image.imageOrder = (image.imageOrder !== 0) ? image.imageOrder : parseInt(filePosition)
      otherImages.push(image);
    }
  });

  let imageOrder = [];
  let sortedImageOrder = Object.keys(otherImages).sort(function(keyA, keyB) {
    return otherImages[keyA].imageOrder - otherImages[keyB].imageOrder;
  });
  sortedImageOrder.map((order, i) => {
    imageOrder.push(otherImages[order]);
  });
  otherImages = imageOrder;

  orderedImages = [...refImages, ...otherImages];

  return orderedImages;
}

/**
 * validator for price range input filter
 */
export function validatorPriceRange(min, max){
  if (!min && !max) {
    return false;
  }
  return true;
}

/**
 * get screen width browser / device
 */
export function getRecomendedResolution(){
  const currentWidth = window.screen.width;
  let poin = 0;
  // resolusi kelipatan 400, 800, 1200, 1600
    if(currentWidth < 450) {
        poin = 400; // mobile
    }else if(currentWidth >= 450 && currentWidth < 920) {
        poin = 800; // tablet
    }else if(currentWidth >= 920 && currentWidth < 1400) {
        poin = 1200; // old monitor
    }else if(currentWidth >= 1400){
        poin = 1600; //full hd above monitor
    }
    return poin;
}

export function checkEmptyImage(dt, source){
  let isEmpty = false;
  if (source=='details') {
    isEmpty = dt.projectDetails.imageLinks.length == 0 ? true : false;
  }
  return isEmpty;
}

/**
* @return {string}
* @param {number} money .
*/
export function priceHelper(money){
  if (money === null){
    return '-';
  } else if (money === 0) {
    return '-';
  } else if (money === '0') {
    return '-';
  }
  return money?.toLocaleString('nl-be',) + '€';
}

/**
 * @return {array}
 * @param {object} sitePlan .
 */
export function constructSitePlan(
  sitePlan,
  level=0,
  index=0,
  parentLevel=null,
  levelType='Project',
  name='',
  codeToShape='',
  idToShape='',
  status='',
  ){
  if (!sitePlan &&
    typeof sitePlan !== 'object' &&
    !('mainImageShapeCoordinates' in sitePlan) &&
    !('mainInteractiveImageLinks' in sitePlan)){
    return []
  }
  let result = []
  if ('mainImageShapeCoordinates' in sitePlan && sitePlan.mainImageShapeCoordinates.length > 0){
    const result = [{
      ...sitePlan,
      levelType:levelType,
      level: level? parentLevel !== '0'?parentLevel+'-'+String(level)+'-'+String(index):String(level)+'-'+String(index):String(level),
      parentLevel: parentLevel,
      codeToShape: codeToShape,
      idToShape: idToShape,
      name: name,
      status: status,
    }];
    const nextLevel = level+1;
    const nextParentLevel = level? parentLevel !== '0'?parentLevel+'-'+String(level)+'-'+String(index):String(level)+'-'+String(index):String(level);
    sitePlan.mainImageShapeCoordinates.map((item, idx)=>{
      if (item.childDetails && item.childDetails.length > 0){
        const childResult = constructSitePlan(
          item.childDetails[0],
          nextLevel,
          idx,
          nextParentLevel,
          item.levelType,
          item.propertyNameConnectedToShape,
          item.propertyCodeConnectedToShape,
          item.propertyESIdsConnectedToShape,
          item.propertyStatusConnectedToShape,
        );
        if (childResult && childResult.length > 0){
          result.push(...childResult)
        }
      }
    })
    return result
  } else {
    return [{
      ...sitePlan,
      levelType:levelType,
      level: level? parentLevel !== '0'?parentLevel+'-'+String(level)+'-'+String(index):String(level)+'-'+String(index):String(level),
      parentLevel: parentLevel,
      codeToShape: codeToShape,
      idToShape: idToShape,
      name: name,
      status: status,
    }]
  }
}