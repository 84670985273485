import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';


const ProjectVideo = ({videoSrc}) => {
  if (videoSrc.mainVideo) {
    return (
      <div className="project-video" id="ProjectVideo">
        <div className="project-video__container">
          <div className="project-video__content">
            <div className="project-video__video-wrap">
              {/* <video src={videoSample} className="project-video__video" ref={videoRef} controls={isVideoPlay ? true : false} onPause={setVideoPauseEvent}></video> */}
              <ReactPlayer
                className="react-player"
                controls={true}
                url={videoSrc.url}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};


ProjectVideo.propTypes = {
  videoSrc: PropTypes.string,
};


export default ProjectVideo;
