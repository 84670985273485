import React from 'react';
import PropTypes from 'prop-types';
import FormControl from './FormControl';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';

/**
 * FormInput component renders a select element with specified properties.
 *
 * @param {string} name - The name of the select element.
 * @param {Array} options - The options to be rendered within the select element.
 * @param {string} [label] - The display label of the select element.
 * @param {string} [errorMsg] - The error message to be displayed for the select element.
 * @param {boolean|string} [required=false] - Whether the select element is required or not.
 * @param {any} [defaultValue] - The initial value of the select element.
 * @param {...Object} [attributes={}] - Additional props to be spread onto the select element.
 *
 * @return {JSX.Element} The rendered select element.
 */
const FormSelect = ({name, options, label, errorMsg, required, isSearchable, defaultValue, ...attributes}) => {
  const {t} = useTranslation();

  const styles = {
    control: (styles) => ({
      'display': 'flex',
      'alignItems': 'center',
    }),
    menu: (styles) => ({
      ...styles,
      'zIndex': 10,
    }),
  };

  return (
    <FormControl name={name} type='select' label={label} errorMsg={errorMsg} required={required}>
      <Select
        value={options.find((option) => option.value === defaultValue)}
        isSearchable={isSearchable}
        className='form-select'
        classNamePrefix='tp-form-control'
        styles={styles}
        id={name}
        name={name}
        required={required}
        aria-describedby={`${name}-validation`}
        defaultValue={defaultValue}
        options={options}
        placeholder={attributes.placeholder ?? ''}
        noOptionsMessage={() => t('No results')}
        {...attributes}
      />
    </FormControl>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool || PropTypes.string,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.any,
  attributes: PropTypes.object,
};

FormSelect.defaultProps = {
  isSearchable: false,
  required: false,
  attributes: {},
};

export default FormSelect;
