import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createContactInformation} from './action';
import {iconURL} from '../../helpers/helper';
import {useTranslation} from 'react-i18next';


const ProjectContactInfoBat = ({project, project: {
  projectContact,
},
createContactInformation,
getContactFormInformation,
contactInformation,
}) => {
  const openModal = () => {
    getContactFormInformation.showModal = true;
    getContactFormInformation.sociCode =
      contactInformation.sociCode;
    getContactFormInformation.contactId =
      contactInformation.id;
    getContactFormInformation.pointContactType =
      contactInformation.pointContactType;
    getContactFormInformation.projectName =
      contactInformation.projectName;
    getContactFormInformation.purpose =
      'visit_demonstration_apartment';
    getContactFormInformation.projectId =
      contactInformation.projectId;
    getContactFormInformation.contactUs = false;
    getContactFormInformation.isAcheter = false;
    createContactInformation(getContactFormInformation);
  };

  const [title, setTitle] = useState('Visit our apartment on display');
  const {t} = useTranslation();

  useEffect(() => {
    if (contactInformation.pointContactType != null) {
      setTitle(contactInformation.pointContactType);
    }
  }, []);

  /**
   * click buttom map.
   */
  const dataLayerClickMap = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'locateOnGoogleMap',
      'location': projectContact.address,
    });
  };
  return <>
    {projectContact !== null &&
      <div className="project-detail-contact" id="projectDetailContact">
        <div className="project-detail-contact__container">
          <h2 className="project-detail-contact__title">{title}</h2>
          {/*  */}
          <div className="project-detail-contact__grid">
            <div className="project-detail-contact__grid-item">
              <div className="project-detail-contact__icon">
                <img src={iconURL('icon-pin-alt.svg')} alt="pin" />
              </div>
              <h3 className="project-detail-contact__grid-title" style={{textTransform: 'uppercase'}}>{t('Location')}</h3>
              <div className="project-detail-contact__text" onClick={dataLayerClickMap}>
                <a href={`https://www.google.com/maps?q=${projectContact.projectCoordinate.lat},${projectContact.projectCoordinate.lon}`} target="_blank" rel="noreferrer" className="project-detail-contact__link">{projectContact.address}</a>
              </div>
            </div>
            <div className="project-detail-contact__grid-item">
              <div className="project-detail-contact__icon">
                <img src={iconURL('icon-hour.svg')} alt="hour" />

              </div>
              <h3 className="project-detail-contact__grid-title" style={{textTransform: 'uppercase'}}>{t('Opening hours')}</h3>
              <div className="project-detail-contact__text">
                <ul dangerouslySetInnerHTML=
                  {{__html: projectContact.openDoorsInfo}}></ul>
              </div>
            </div>
            <div className="project-detail-contact__grid-item">
              <div className="project-detail-contact__icon">
                <img src={iconURL('icon-phone.svg')} alt="phone" />
              </div>
              <h3 className="project-detail-contact__grid-title" style={{textTransform: 'uppercase'}}>{t('Contact')}</h3>
              <div className="project-detail-contact__text">
                <div className='project-detail-contact__phone' dangerouslySetInnerHTML=
                  {{__html: projectContact.phoneContact}}></div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="project-detail-contact__cta-wrap">
            <button className="project-detail-contact__cta" onClick={openModal}>{t('Request an appointment')}</button>
          </div>
        </div>
      </div>
    }
  </>;
};

ProjectContactInfoBat.propTypes = {
  project: PropTypes.object,
  projectContact: PropTypes.object,
  createContactInformation: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  contactInformation: PropTypes.any,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createContactInformation},
)(ProjectContactInfoBat);
