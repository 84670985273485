import React from 'react';
import PropTypes from 'prop-types';
import ProjectProximiteChild from './ProjectProximiteChild';
import {useTranslation} from 'react-i18next';

const ProjectProximite = ({project}) => {
  const {t} = useTranslation();
  const sortedFacility = project.facilities?.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
  if (sortedFacility.length !== 0) {
    return <div className="project-detail-proximite">
      <div className="project-detail-proximite__container">
        <h2 className="project-detail-proximite__title">
          {t('Local advantages')}
        </h2>
        <div className="project-detail-proximite__box">
          {sortedFacility.map((item, index) => {
            const sortedItems = item.items?.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            return <ProjectProximiteChild
              key={index}
              icon={item.image}
              list={sortedItems}
              title={item.facilityType}
            />;
          })}
        </div>
      </div>
    </div>;
  }
  return <></>;
};

ProjectProximite.propTypes = {
  project: PropTypes.object,
};

export default ProjectProximite;
