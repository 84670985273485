import React from 'react';
import PropTypes from 'prop-types';

/**
 * FormControl component renders an input element with specified properties.
 *
 * @param {string} name - The name of the input element.
 * @param {string} label - The display label of the input element.
 * @param {boolean} required - Whether the input element is required or not.
 * @param {string} defaultValue - The initial value of the input element.
 * @param {ReactNode} children - The child elements to be rendered inside the component.
 *
 * @return {JSX.Element} The rendered input element.
 */
const FormControl = ({name, type, label, errorMsg, required, children}) => {
  return (
    <div className={`tp-form-control tp-form-control--${type}`}>
      <label className='form-label' htmlFor={name}>
        <span className='form-label__text'>{label}</span>
        {required === true || required === 'true' ? <span aria-hidden className='form-label__required'>*</span> : null }
      </label>
      {children}
      { errorMsg ? <small id={`${name}-validation`} className='form-error'>{errorMsg}</small> : null }
    </div>
  );
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool || PropTypes.string,
};

FormControl.defaultProps = {
  type: 'text',
  required: false,
  attributes: {},
};

export default FormControl;
