/**
 * @param {string} countryCode
 * @return {object}
 */
export const defaultMapDisplay = (countryCode) => {
  return {
    zoom: (countryCode === 'LU') ? 9 : 7,
    center: defaultCenterOfCountry(countryCode),
  };
};

/**
 * @param {string} countryCode
 * @return {object}
 */
function defaultCenterOfCountry(countryCode) {
  const arrCenter = {
    BE: {
      lat: 50.503887,
      lng: 4.4699359999999615,
    },
    FR: {
      lat: 49.81799012804771,
      lng: 3.167074878851883,
    },
    LU: {
      lat: 49.79196679962982,
      lng: 6.10172259765622,
    },
  };
  return arrCenter[countryCode];
};


/**
 *
 * @param {*} response
 * @param {*} reset
 * created only form the request
 *
 * @return {object} {type,payload}
 */
export const createMarkerCollection = (response) =>{
  const markerDTO = response === null ? null : response.data.projectMarkerDTO === undefined ?
  [] : response.data.projectMarkerDTO;

  return {
    type: 'MARKER_COLLECTION',
    payload: markerDTO,
  };
};


/**
 *
 * @param {*} data
 *
 * @return {object}
 */
export const createMarkerInBounds = (data)=>{
  return {
    type: 'MARKER_IN_BOUNDS',
    payload: data,
  };
};


export const createPOIData = (params)=>{
  const data =
  [
    {
      'featureType': 'transit',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': params.poiBus,
        },
      ],
    },
    {
      'featureType': 'landscape',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'administrative',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': params.poiShop,
        },
      ],
    },
    {
      'featureType': 'poi.medical',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': params.poiMedical,
        },
      ],
    },
    {
      'featureType': 'poi.school',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': params.poiGradCap,
        },
      ],
    },
    {
      'featureType': 'poi.sports_complex',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': params.poiGym,
        },
      ],
    },
    {
      'featureType': 'poi.government',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.place_of_worship',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'water',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'on',
        },
      ],
    },
  ];
  return {
    type: 'POI_DATA',
    payload: data,
  };
};

export const createLockSearch =(state)=>{
  return {
    type: 'LOCK_SEARCH',
    payload: state,
  };
};

export const createMapLocation = (params) =>{
  const data = {
    type: 'MAP_LOCATION',
    payload: params,
  };
  return data;
};

export const createPopupProjectId = (params) =>{
  const data = {
    type: 'POPUP_PROJECTID',
    payload: params,
  };
  return data;
};

export const toggleMapMobile =(state)=>{
  return {
    type: 'TOGGLE_MAP_MOBILE',
    payload: state,
  };
};

export const toggleMap = (params) => {
  return {
    type: 'TOGGLE_MAP',
    payload: params,
  };
};
