import {searchProjects} from '../../../services/ExternalApi';
/**
   * Load more list.
   * @param {object} searchParams The first number val.
   * @param {string} language The first number val.
   * @return {void}
   */
export function loadList(searchParams) {
  const dataSearch = searchProjects(searchParams)
      .then((res) => {
        if (res.data.projects && res.data.projects.length > 0) {
          const projects = [];
          res.data.projects.forEach((project) => {
            projects.push(project);
          });
          return {
            projects: projects,
            count: res.data.propertiesCount,
          };
        } else {
          return {
            projects: [],
            count: 0,
          };
        }
      }).catch((err) => {
        return err;
      });
  return dataSearch;
}
