import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toggleFilter} from '../action';
import {withTranslation} from 'react-i18next';

/**
 * Class Available Filter.
*/
class AvailableFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      isAvailable: false,
    };
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    const filterParams = props.getFilterProjectDetail;
    if (filterParams.filterId) {
      const filterAvailable = filterParams.entities[filterParams.filterId].filterAvailable;
      if (filterAvailable) {
        const isAvailable = filterAvailable.includes('Available');
        state.isAvailable = isAvailable;
      }
    }
    return state;
  }

  /**
   * Change Available Filter.
   * @param {object} e valye output checkbox.
   */
  setAvailableCheckbox(e) {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'availableFilter': e.target.checked,
    });
    this.setState({
      isAvailable: e.target.checked,
    });
    this.props.submitHandle(e.target.checked);
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="checkbox-group-container-filter">
        <div className="form-container">
          <div className="form-group inline form-full form-group-checkbox visually-hidden">
            <input className="form-control"
              type="checkbox"
              name="checkbox-available"
              id={this.props.showOption}
              value="Available"
              checked={this.state.isAvailable}
              onChange={this.setAvailableCheckbox.bind(this)}
            />
            <label htmlFor={this.props.showOption}>
              {this.props.t('Property for sale only')}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

AvailableFilter.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  getFilterProjectDetail: PropTypes.objectOf(PropTypes.any),
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  showOption: PropTypes.func,
  t: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps, {
  toggleFilter,
},
)(withTranslation()(AvailableFilter));
