import React from 'react';
import {connect} from 'react-redux';
import * as ExternalApi from '../services/ExternalApi';
import ProjectInvestFilter from '../components/ProjectInvestFilter/ProjectInvestFilter';
import PropTypes from 'prop-types';
import ProjectInvestList from '../components/ProjectInvestList/ProjectInvestList';
import {
  createParamsInvestFiltering,
  createRequestSearchInvestProjects,
  createGetProjectPropertiesDetail,
  createProjectInvestList, createProjectInvestFilters,
} from '../pages/action';
import ProjectInvestDetail from '../components/ProjectInvestDetail/ProjectInvestDetail';
import PropertyModal from '../components/ProjectDetail/Property/PropertyModal';
import {withTranslation} from 'react-i18next';
// import {queryStringFormatter} from '../helpers/helper';

/**
 * Page Purchase Element
 */
class PageInvestir extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      activeProject: null,
      templateCode: 'THOEU',
      getMediaBannerDataAll: {},
      mediaId: [],
      isSearching: false,
      paging: {
        total: 1,
        current: 1,
        itemsPerPage: 9,
      },
    };
  }

  /**
   * Load Componnent
   */
  async componentDidMount() {
    await this.loadInitialData();
  }

  /**
   * Load translate
   */
  async loadInitialData() {
    this.setState({isSearching: true});
    const paramsInitial = await this.props.getRequestSearchInvestProjects;
    const params = this.props.createRequestSearchInvestProjects(paramsInitial);
    const projectToSelect = new URLSearchParams(this.props.history.location.search).get('project');
    if (projectToSelect) {
      this.setState({
        activeProject: projectToSelect,
      });
    }

    await ExternalApi.searchInvestProjects(params.payload).then((res) => {
      let projectCount = 0;
      if (res.data.projects && res.data.projects.length > 0) {
        const projects = [];
        res
            .data
            .projects
            .forEach((project) => {
              projects.push(project);
              // countFromAPI++;
            });

        this
            .props
            .createProjectInvestList({'projects': projects, 'loading': false});

        this
            .props
            .createProjectInvestFilters({
              'provinces': res.data.provinces,
              'minPrice': res.data.minPrice,
              'maxPrice': res.data.maxPrice,
            });

        projectCount = res.data.projectCount;
      }


      this.setState({
        isSearching: false,
        paging: {
          ...this.state.paging,
          total: Math.ceil(projectCount / this.state.paging.itemsPerPage),
          current: 1,
        },
      });
    }).catch((err) => {
      console.log('err', err);
    });
  }

  /**
   * Search for new items.
   * @param {number} page
   * @return {Promise<void>}
   */
  async loadNewData(page) {
    this.setState({isSearching: true});
    let savedParams = await this.props.getParamsInvestFiltering;

    savedParams = {
      ...savedParams,
      paging: {
        ...savedParams.paging,
        currentPage: page,
      },
    };

    const params = this.props.createRequestSearchInvestProjects(savedParams);

    await ExternalApi.searchInvestProjects(params.payload).then((res) => {
      let projectCount = 0;
      if (res.data.projects && res.data.projects.length > 0) {
        const projects = []; // this.props.getProjectInvestList.projects; // load more functionality
        // let countFromAPI = 0;
        res
            .data
            .projects
            .forEach((project) => {
              projects.push(project);
              // countFromAPI++;
            });

        this
            .props
            .createProjectInvestList({'projects': projects, 'loading': false, 'initial': false});

        this.scrollToTop();

        projectCount = res.data.projectCount;
      } else {
        projectCount = 0;

        this
            .props
            .createProjectInvestList({'projects': [], 'loading': false, 'initial': false});
      }

      this.setState({
        isSearching: false,
        paging: {
          ...this.state.paging,
          total: Math.ceil(projectCount / this.state.paging.itemsPerPage),
          current: page,
        },
      });
    }).catch((err) => {
      console.log('err', err);
    });
  }

  /**
   * Select an active project
   * @param {*} project
   * @return {Promise<void>}
   */
  async setActiveProject(project) {
    const newURL = new URL(window.location.href);

    newURL.search = '';
    if (project !== null) {
      newURL.search = '?project=' + project.projectId;
    }

    this.props.history.push({search: newURL.search});

    this.setState({
      activeProject: project,
    });
  }

  /**
   * Scrolls to list
   * @return {Promise<void>}
   */
  async scrollToTop() {
    this.myRef.current.scrollIntoView();
  }

  /** @return {JSX} */
  render() {
    return (
      <main id="main" className="investir-main">
        <div className={(this.state.activeProject !== null ? 'd-none' : '' )}>
          <div className="purchase-filter-title-container-react">
            <h3>
              <span className="black-title">{this.props.t('investTitleBlack')}</span> {this.props.t('investTitleBlack')}
            </h3>
          </div>
          <div className="scroll-pointer" ref={this.myRef}></div>
          <ProjectInvestFilter
            history={this.props.history}
            handleSearch={this.loadNewData.bind(this)}
            isSearching={this.state.isSearching}
            paging={this.state.paging}
          />
          <ProjectInvestList
            history={this.props.history}
            handleSearch={this.loadNewData.bind(this)}
            handleActiveProject={this.setActiveProject.bind(this)}
            isSearching={this.state.isSearching}
            paging={this.state.paging}
          />
        </div>
        <ProjectInvestDetail
          history={this.props.history}
          project={this.state.activeProject}
          handleActiveProject={this.setActiveProject.bind(this)}
        />
        <PropertyModal
          getMediaBannerDataAll={this.state.getMediaBannerDataAll}
          mediaId={this.state.mediaId}
          templateCode={this.state.templateCode}
          rendementTab={true}
          templateConfig={this.props.templateConfig}
        />
      </main>
    );
  }
}

PageInvestir.propTypes = {
  history: PropTypes.object,
  templateConfig: PropTypes.object,
  getParamsInvestFiltering: PropTypes.object,
  createProjectInvestList: PropTypes.func,
  createProjectInvestFilters: PropTypes.func,
  getRequestSearchInvestProjects: PropTypes.object,
  getProjectInvestFilters: PropTypes.object,
  createRequestSearchInvestProjects: PropTypes.func,
  t: PropTypes.func,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps,
    {
      createParamsInvestFiltering,
      createGetProjectPropertiesDetail,
      createRequestSearchInvestProjects,
      createProjectInvestList,
      createProjectInvestFilters,
    },
)(withTranslation()(PageInvestir));
