import React from 'react';
import PropTypes from 'prop-types';


/**
 *
 * @param {*} props
 *
 * @return {JSX}
 */
export function BubleLoading(props) {
  return (
    <div className="loader-react" key={0}>
      <div className="multi-ripple">
        <div></div>
        <div></div>
      </div>
      <p>{props.text} ...</p>
    </div>
  );
}
BubleLoading.propTypes={
  text: PropTypes.string,
};
