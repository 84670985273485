import React from 'react';
import PropTypes from 'prop-types';
import {getRecomendedResolution} from '../../helpers/helper';
export const collectImage = (params, resolution) =>{
  resolution = getRecomendedResolution();
  const collection = [];
  if (params != null) {
    if (params) {
      params.map((v)=>{
        const exImg = v.imageLink.split('/');
        if (exImg.includes(String(resolution))) {
          collection.push(v.imageLink);
        }
      });
    }
  }
  return collection;
};

export const collectImageWithAlt = (params, resolution, projectName = '', skipDynamic) =>{
  const collection = [];
  if (!skipDynamic) {
    resolution = getRecomendedResolution();
  }
  if (params != null) {
    if (params) {
      params.map((v)=>{
        const exImg = v.imageLink.split('/');
        const fileName = exImg[exImg.length - 1];
        if (v.imageLink.includes(String(`/${resolution}/`)) || fileName.includes(String(`_${resolution}_`))) {
          collection.push({
            'url': v.imageLink,
            'isRef': v.isRef,
            'alt': (v.alt) ? v.alt : projectName,
            'imageOrder': v.imageOrder,
          });
        }
      });
    }
  }
  return collection;
};

// since API is already dynamic we no need to filter by resolution
export const collectImageWithAltDynamic = (params, resolution, projectName = '', skipDynamic) =>{
  const collection = [];
  if (params != null) {
    if (params) {
      params.map((v)=>{
        if (resolution !== undefined) {
          if (v.imageLink.indexOf(String(`/${resolution}/`)) != -1 || v.imageLink.indexOf(String(`_${resolution}_`)) != -1) {
            collection.push({
              'url': v.imageLink,
              'isRef': v.isRef,
              'alt': (v.alt) ? v.alt : projectName,
              'imageOrder': v.imageOrder,
            });
          }
        } else {
          collection.push({
            'url': v.imageLink,
            'isRef': v.isRef,
            'alt': (v.alt) ? v.alt : projectName,
            'imageOrder': v.imageOrder,
          });
        }
      });
    }
  }
  return collection;
};
/**
 * Create Image Primary
 * @param {props} props Image.
 * @return {JSX.Element}
 */
export const ImagePrimary = (props) => {
  const imageLinks = props.images;
  const sourceMainImage = collectImage(imageLinks, 1200);
  let sourceLink = null;
  sourceMainImage.reverse().map((link, i)=>{
    sourceLink = link;
  });

  return (
    <div className="col col-6">
      <div className="image-wrapper" key={'main-image-block'}>
        <img src={sourceLink} key={'main-image'}></img>
      </div>
    </div>
  );
};

/**
 * Create Image Second
 * @param {props} props Image.
 * @return {JSX.Element}
 */
export const ImageSecond = (props) => {
  const maxImage = 4;
  const arrImage = [];
  const imageLinks = props.images;
  const sourceSecondaryImage = collectImage(imageLinks, 400);
  sourceSecondaryImage.map((link, i)=>{
    if ( i <= maxImage && i !== 0) {
      arrImage.push(link);
    }
  });
  return (
    <div className="col col-6">
      {arrImage.map((link, i)=>
        <div className="grid" key={i}>
          <img src={link} key={i}></img>
        </div>,
      )}
    </div>
  );
};

/**
 * Create Count image
 * @param {props} props Image.
 * @return {JSX.Element}
 */
export const CountImage = (props) => {
  const range = [];
  if (props.images != null) {
    if (props.images.length > 0) {
      const imageLinks = props.images;
      const sourceMainImage = collectImage(imageLinks, 1200);
      sourceMainImage.map((link, i)=>{
        range.push(1);
      });
    }
  }
  return range.length;
};

ImagePrimary.propTypes = {
  images: PropTypes.array,
};

ImageSecond.propTypes = {
  images: PropTypes.array,
};

CountImage.propTypes = {
  images: PropTypes.array,
};
