import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import JobDetailCard from '../JobCard/JobDetailCard';

/**
 * Main Component
 * @return {JSX.Element} The rendered component.
 */
const JobDetailHeading = ({job, countries}) => {
  const {t} = useTranslation();
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${job.title}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
  const mailToUrl = `mailto:?subject=${job.title}&body=${window.location.href}`;

  return (
    <div className='job-detail-heading'>
      <nav className='job-detail-heading__share' aria-labelledby='share'>
        <i className="fas fa-share-alt" aria-hidden />
        <span id='share'>{t('Share this offer')}</span>
        <a href={linkedinUrl} target='_blank' rel='noreferrer'>
          <span className='sr-only'>LinkedIn</span>
          <i className="fab fa-linkedin-in" aria-hidden />
        </a>
        <a href={facebookUrl} target='_blank' rel='noreferrer'>
          <span className='sr-only'>Facebook</span>
          <i className="fab fa-facebook-f" aria-hidden />
        </a>
        <a href={mailToUrl}>
          <span className='sr-only'>Mail</span>
          <i className="fas fa-envelope" aria-hidden />
        </a>
      </nav>
      <JobDetailCard job={job} countries={countries}></JobDetailCard>
      <div className='job-detail-heading__content'>
        <a href='#postuler' className='job-detail-heading__apply'>{t('Send us your application using the form below!')}</a>
      </div>
    </div>
  );
};

JobDetailHeading.propTypes = {
  job: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export default JobDetailHeading;
