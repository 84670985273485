import httpClient from './httpClient';
import axios from 'axios';
import {getRecomendedResolution} from '../helpers/helper';
import i18n from '../i18n';

const baseProject = process.env.REACT_APP_PROJECT_VERSION || 'Project';

export const getIdProjectFromPrEpl = async (PrEpl, country) => {
  const apiUrl = process.env.REACT_APP_BASE_API_URL +'/'+ process.env.REACT_APP_BASE_PROJECT_VERSION+'/CptEplInfo';
  const data = {
    language: country, // note: this is actually the country, and not the language. API needs to change this.
    cptEPL: PrEpl,
  };
  const res = await axios.post(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(res);
};

export const searchProjects = async (params = {}) => {
  const resolution = getRecomendedResolution();
  params.imageSize = resolution == 1600 ? 1200 : resolution; // QA request since most common size is 1200
  params.language = i18n.language;
  const result = await httpClient.post(
      `/${baseProject}/SearchProjects`,
      params,
  );
  return Promise.resolve(result);
};

export const getProjectDetails = async (params= {}) =>{
  const url = process.env.REACT_APP_BASE_API_URL+'/'+
    process.env.REACT_APP_BASE_PROJECT_VERSION+
    '/GetProjectDetails';

  params.projectDetails.imageSizes = [1600, 1200, 800, 400];
  params.language = i18n.language;
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getInitialProjectSearchCriteria = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL+'/'+
    process.env.REACT_APP_BASE_PROJECT_VERSION+
    '/GetInitialProjectSearchCriteria';

  params.language = i18n.language;
  const data = JSON.stringify(params);

  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};

export const getProjectProperties = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/' +
    process.env.REACT_APP_BASE_PROJECT_VERSION +
    '/GetProjectProperties';
  // 347881
  params.imageSizes = [1200, 800, 400];
  params.language = i18n.language;
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};

export const countProjects = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/' +
    process.env.REACT_APP_BASE_PROJECT_VERSION +
    '/CountProjects';
  params.language = i18n.language;
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};


export const getProjectMarkers = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
  '/' +
  process.env.REACT_APP_BASE_PROJECT_VERSION +
  '/GetProjectMarkers';
  params.language = i18n.language;
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};

export const getShowHouseContactPoints = async (params = {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactPoint/GetShowHouseContactPoints';
  params.language = i18n.language;
  const data = JSON.stringify({
    'language': params.language,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getPromotionBanner = async () => {
  const result = await httpClient.get(
      '/Media/MarketingMedia',
      {
        params: {
          language: i18n.language,
        },
      },
  );
  return Promise.resolve(result);
};

export const getContactPoints = async (params= {}, endPoint) => {
  const url = process.env.BASE_URL_API +
    '/api/ContactPoint/' +
    endPoint;
  params.language = i18n.language;
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getCalendarAvailabelSlot = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactPoint/GetFirstAvailabelSlot';
  params.language = i18n.language;
  const data = JSON.stringify({
    'contactPointId': params.id,
    'language': params.language,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
    data: data,
  });
  return Promise.resolve(result);
};

export const getCalendarAvailabelSlotbyMonthYear = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactPoint/GetAvailableSlots';
  params.language = i18n.language;
  const data = JSON.stringify({
    'contactPointId': params.id,
    'year': params.year,
    'month': params.month,
    'language': params.language,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};


export const getCities = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/ContactForm/GetCities';
  params.language = i18n.language;
  const data = JSON.stringify({
    'criteria': {
      name: params.name,
      filterId: params.id,
    },
    'paging': {
      'currentPage': 0,
      'pageSize': 99,
    },
    'language': params.language,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getZipCodes = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/ContactForm/GetZipCodes';
  params.language = i18n.language;
  const data = JSON.stringify({
    'criteria': {
      name: params.name,
      filterId: params.id,
    },
    'paging': {
      'currentPage': 0,
      'pageSize': 1,
    },
    'language': params.language,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getCitiesForZipCode = async (params= {}, country = process.env.REACT_APP_COUNTRY_NAME) => {
  const url = process.env.REACT_APP_BASE_API_URL +
  '/ContactForm/GetCitiesForZipCode';
  params.language = i18n.language;
  const data = {
    'zipcode': params.id,
    'country': country,
    'language': params.language,
  };

  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};

export const getPostalCodeDrupal = async (params= {}) => {
  if (params.length < 3) {
    return Promise.resolve(false);
  }
  const url = process.env.REACT_APP_BASE_DRUPAL_URL +
      '/odoo-form/get-cities-by-name';

  const data = {
    'params': {
      'keyword': params,
    },
    'language': params.language,
  };
  const result = await axios.get(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getPostalCodeApi = async (params= {}, country = process.env.REACT_APP_COUNTRY_NAME, language = 'fr') => {
  if (params.length < 3) {
    return Promise.resolve(false);
  }
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactForm/GetCities';

  const data = {
    'criteria': {
      'name': params,
      'filterId': 0,
    },
    'paging': {
      'currentPage': 0,
      'pageSize': 99,
    },
    'language': language,
  };

  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getZipCodeByCity = async (params= {}, language = 'fr') => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/ContactForm/GetZipCodes';

  const data = {
    'criteria': {
      'filterId': parseInt(params),
    },
    'paging': {
      'currentPage': 0,
      'pageSize': 99,
    },
    'language': language,
  };

  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const searchInvestProjects = async (params= {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/' +
      process.env.REACT_APP_BASE_PROJECT_VERSION +
      '/SearchInvestorProjects';
  params.language = i18n.language;
  const result = await httpClient.post(
      url,
      params,
  );
  return Promise.resolve(result);
};
