import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const ProjectIframe = ({project}) => {
  const {t} = useTranslation();

  if (project.iframeLink != null) {
    return <div className="project-detail-iFrame" id="3d-model">
      <div className="project-detail-iFrame__container">
        <iframe src={project.iframeLink} title={t('Project detail')}></iframe>
      </div>
    </div>;
  }
  return <></>;
};

ProjectIframe.propTypes = {
  project: PropTypes.object,
  t: PropTypes.func,
};

export default ProjectIframe;
