import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import {connect} from 'react-redux';
import {toggleFilter} from '../action';
import {withTranslation} from 'react-i18next';

/**
 * Class Price Filter.
*/
class RoomFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      selectRooms: false,
      buttonBadgeNumber: null,
      isFilter: false,
      numberOfRooms: {
        Min: 0,
        Max: 0,
      },
      roomDefaultMin: 0,
      roomDefaultMax: 5,
    };
  }


  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.filterValue !== null && state.numberOfRooms.Min === 0) {
      state.selectRooms = true;
      state.isFilter = true;
      state.buttonBadgeNumber = props.filterValue.Min;
      state.numberOfRooms = {
        Min: Number(props.filterValue.Min),
        Max: Number(props.filterValue.Max),
      };
    }
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.filterValue !== prevProps.filterValue) {
      if (!this.props.filterValue) {
        this.setState({
          buttonBadgeNumber: null,
          isFilter: false,
          NumberOfM2: {
            Min: 0,
            Max: 0,
          },
        });
      }
    }
  }

  /**
   * Show Room Filter.
   */
  showRoomFilter() {
    const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
    if (_getFaramFiltering.numberOfRoomsMin !== undefined) {
      this.setState({
        selectRooms: this.state.selectRooms,
        roomDefaultMin: _getFaramFiltering.numberOfRoomsMin,
        roomDefaultMax: _getFaramFiltering.numberOfRoomsMax,
      });

      if (this.state.numberOfRooms.Min===0) {
        this.setState({
          numberOfRooms: {
            Min: _getFaramFiltering.numberOfRoomsMin,
            Max: _getFaramFiltering.numberOfRoomsMax,
          },
        });
      }
      this.props.toggleFilter({
        ...this.props.getFilter,
        'filterType': false,
        'filterPrice': false,
        'filterRoom': !this.props.getFilter.filterRoom,
        'filterSurface': false,
      });
    }
  }

  /**
   * Change Room Filter.
   * @param {number} value text output val.
   */
  handleChangeRoom(value) {
    this.setState({
      numberOfRooms: {
        Min: value,
      },
    });
  }

  /**
   * Submit Room Filter.
   * @param {number} value text output val.
   */
  async submitRoomFilter() {
    this.setState({
      selectRooms: true,
      isFilter: true,
      buttonBadgeNumber: this.state.numberOfRooms.Min,
      numberOfRooms: {
        Min: this.state.numberOfRooms.Min,
        Max: this.props.getParamsFiltering.numberOfRoomsMax,
      },
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterRoom': !this.props.getFilter.filterRoom,
    });
    this.props.submitHandle({
      Min: this.state.numberOfRooms.Min,
      Max: this.props.getParamsFiltering.numberOfRoomsMax,
    });
  }

  /**
   * Clear Room Filter.
   * @param {number} value text output val.
   */
  async cancelRoomFilter() {
    this.setState({
      buttonBadgeNumber: null,
      selectRooms: false,
      isFilter: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterRoom': !this.props.getFilter.filterRoom,
    });
    this.props.cancelHandle();
  }
  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': false,
      'filterPrice': false,
      'filterRoom': !this.props.getFilter.filterRoom,
      'filterSurface': false,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-container">
        <button
          className=
            {`btn bn-small btn-filter btn-filter-room btn-width-large 
            ${!this.props.getFilter.filterRoom ? ' ' : 'is-collapsed'} 
            ${!this.state.isFilter ? ' ' : 'is-filter'}
            ${this.props.getFilter.filterRoom ? 'is-up' : ''}
            `}
          onClick={this.showRoomFilter.bind(this)}
        >
          <span className="span-price-filter">
            {this.props.t('Rooms')}
          </span>
          {(() => {
            if (this.state.selectRooms === true ||
                this.setState.isFilter === true) {
              return (
                <div className="badge">
                  <span>{this.state.buttonBadgeNumber}</span>
                </div>
              );
            }
          })()}
          <i className="fas fa-chevron-down"></i>
        </button>
        <div className="filter-box-container filter-room">
          <div className="filter-body-container-react">
            <p className="label-room">
              {this.props.t('Minimum of bedrooms')} :
              <span className="highlight room-number">
                {this.state.numberOfRooms.Min}+
              </span>
            </p>
            <div className="slider-container">
              <form className="form">
                <InputRange
                  maxValue={this.state.roomDefaultMax}
                  minValue={this.state.roomDefaultMin}
                  formatLabel={(value) => ``}
                  value={this.state.numberOfRooms.Min}
                  allowSameValues={true}
                  onChange={this.handleChangeRoom.bind(this)} />
              </form>
            </div>
          </div>
          <div className="filter-action-container">
            <button className="btn-filter-cancel"
              onClick={this.cancelRoomFilter.bind(this)}>
              {this.props.t('Cancel')}
            </button>
            <button className="btn-filter-submit"
              onClick={this.submitRoomFilter.bind(this)}>
              {this.props.t('Apply')}
            </button>
          </div>
        </div>

        {/* eslint-disable-next-line max-len */}
        { this.props.getFilter.filterRoom && ( <div className="is-collapsed" onClick={ ()=> this.handleClickBackDrop()}/>) }

      </div>
    );
  }
}

RoomFilter.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
  t: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect( mapsStateToProps, {
  toggleFilter,
},
)(withTranslation()(RoomFilter));
