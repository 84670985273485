import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {openPropertyModal} from './action';
import {iconURL} from '../../../helpers/helper';
import {PropertyStatus, TYPE_TERRAIN_A_BATIR} from '../../../helpers/constants';
import {useTranslation} from 'react-i18next';
// import queryString from 'query-string'; // in case residence is need to show, just un block these code below

const PropertyItemNew = (props) => {
  // TODO: Hidden for now
  const [residence, setResidence] = useState(false);
  const {t} = useTranslation();
  // const searchParams = queryString.parse(window.location.search);
  // const listCountryWithoutPhase = ['france'];
  // const country = searchParams.country?.toLocaleLowerCase();
  // const withoutPhase = listCountryWithoutPhase.indexOf(country) > -1 ? 'hidden' : '';
  const withoutPhase = 'hidden';

  // handller residence item
  const onClickResidenceItem = () => {
    if (!residence) {
      setResidence(!residence);
    }
  };
  const residenceClass = residence ? 'residence-item--clicked' : '';
  const residenceClassMobile = residence ? 'clicked' : '';

  const handleModal = () => {
    props.openPropertyModal({project: props.project, property: props.property});
    onClickResidenceItem();
  };

  const pricingCondition = props.property.conditions;
  let pricingConditionPrice = pricingCondition.filter(function(el) {
    return el.price === true;
  });
  let pricingConditionPriceAllIn = pricingCondition.filter(function(el) {
    return el.priceAllIn === true;
  });

  if (props.property.marketingConditions !== []) {
    pricingConditionPrice = props.property.marketingConditions;
    pricingConditionPriceAllIn = props.property.marketingConditions;
  }

  const availableClass = props.property.status === PropertyStatus.SOLD ? 'residence-item--sold' : 'residence-item';
  const availableClassMobile = props.property.status === PropertyStatus.SOLD ? 'sold' : '';

  let price = props.property.status === PropertyStatus.SOLD ?
      `<p class="price">${t('Sold')}</p>` :
      `<p class="price--orange">${props.property.price?.toLocaleString('nl-be')}€ <span class="price--note">${pricingConditionPrice.map((item) => (`(${item.symbol})`))}</span></p>`;
  let priceAllIn = props.property.status === PropertyStatus.SOLD ?
  `<p class="price">${t('Sold')}</p>` :
  `<p class="price--black">-</p>`;

  if (props.property.priceAllIn?.toLocaleString('nl-be') !== undefined && props.property.priceAllIn?.toLocaleString('nl-be').toString() !== '0') {
    priceAllIn = props.property.status === PropertyStatus.SOLD ?
      `<p class="price">${t('Sold')}</p>` :
      `<p class="price--black">${props.property.priceAllIn?.toLocaleString('nl-be')}€ <span class="price--note">${pricingConditionPriceAllIn.map((item) => (`(${item.symbol})`))}</span></p>`;
  }
  let priceStatus = props.property.status === PropertyStatus.SOLD ? PropertyStatus.SOLD : '';
  let priceAllInStatus = props.property.status === PropertyStatus.SOLD ? PropertyStatus.SOLD : '';
  let surface = `${props.property.surfaceM2} m<sup>2</sup>`;
  let floor = '<span className="no-table-data">-</span>';
  let numberOfRooms = '<span className="no-table-data">-</span>';

  if (props.property.floor !== undefined) {
    floor = props.property.floor < 1 ? '-' : props.property.floor;
  }

  if (props.property.numberOfRooms !== undefined) {
    numberOfRooms = props.property.numberOfRooms < 1 ? '-' : (props.property.numberOfRooms == 1 ? `${props.property.numberOfRooms} ${t('Room')}` : `${props.property.numberOfRooms} ${t('Rooms')}`);
  }

  if (props.property.projectPropertyType === TYPE_TERRAIN_A_BATIR) {
    surface = '<span className="no-table-data">-</span>';
  }

  const mediaTemplateIds= [];
  let mediaTemplate= {};
  const htmlBanner=[];
  const htmlBannerAllIn=[];
  // const classForFloor = props.hideFloor ? 'floor-item hide' : 'floor-item';
  // const classForRoom = props.hideRoom ?'room-item hide': 'room-item';

  // if price have option
  if (props.property.option !== undefined && props.property.option.toLocaleLowerCase() !=='any') {
    price =`<p class='price option'> ${t('Option')} </p>`;
    priceAllIn =`<p class='price option'> ${t('Option')} </p>`;
    priceStatus = PropertyStatus.OPTION;
    priceAllInStatus = PropertyStatus.OPTION;
  }

  // disable price if 0, null, undefined
  if ((props.property.price < 1 || props.property.price === undefined || props.property.price === null) && props.property.status !== PropertyStatus.SOLD) {
    price =`<p class="price"> - </p>`;
    priceStatus = '-';
  }

  // disable priceAllIn if 0
  if ((props.property.priceAllIn < 1 || props.property.priceAllIn === undefined || props.property.priceAllIn === null) && props.property.status !== PropertyStatus.SOLD) {
    priceAllIn =`<p class="price"> - </p>`;
    priceAllInStatus = '-';
  }
  // Update Price Columns
  if (props.property.hasActionOnGoing && props.property.status !== PropertyStatus.SOLD) {
    props.property.mediaTemplates.map((value)=>{
      if (value.mediaType === 'SpecialPrice') {
        mediaTemplateIds.push(value.mediaId);
        mediaTemplate = value;
      }
    });

    const priceConditionSymbol = pricingConditionPrice.map((item) => {
      const itemColor = (item.symbolColor) ? `style="color: ${item.symbolColor}"` : '';
      return `<span ${itemColor}>(${item.symbol})</span>`;
    });
    props.mediaBanner.medias.map((value)=>{
      if (mediaTemplateIds.includes(value.id)) {
        const showedPrice = (props.property.price < 1 || props.property.price === undefined || props.property.price === null) ? '-' : `${props.property.price.toLocaleString('nl-be')}€<span class="price--note">${priceConditionSymbol}</span>`;
        let div = value.div
            .replace('[[Price]]', `${showedPrice}`)
            .replace('[[OpenningType]]', `${mediaTemplate.openningType}`)
            .replace('[[OpenningContent]]', `${mediaTemplate.openningContent}`)

        // todo: need fix later
        // OverWrite css

            .replace(
                'class="campaign-banner-price"',
                'class="campaign-banner-price" style= "display: inherit;"',
            )

            .replace(
                'class="campaign-banner-price-top maison"',
                'class="campaign-banner-price-top maison" style= "display: inherit;"',
            )

            .replace(
                'class="campaign-banner-price-bottom maison"',
                'class="campaign-banner-price-bottom maison" style= "display: grid;"',
            );
        if (props.property.oldPrice == props.property.price) {
          div = div.replace('[[OldPrice]]', ``);
        } else {
          div = div.replace('[[OldPrice]]', `${props.property.oldPrice?.toLocaleString('nl-be')}€`);
        }
        const showedPriceAllIn = (props.property.priceAllIn < 1 || props.property.priceAllIn === undefined || props.property.priceAllIn === null) ? '-' : `${props.property.priceAllIn.toLocaleString('nl-be')}€ <span class="price--note">${priceConditionSymbol}</span>`;
        let divAllIn = value.div
            .replace('[[Price]]', `${showedPriceAllIn}`)
            .replace('[[OpenningType]]', `${mediaTemplate.openningType}`)
            .replace('[[OpenningContent]]', `${mediaTemplate.openningContent}`)
            .replace(
                'class="campaign-banner-price"',
                'class="campaign-banner-price" style= "display: inherit;"',
            )

            .replace(
                'class="campaign-banner-price-top maison"',
                'class="campaign-banner-price-top maison" style= "display: inherit;"',
            )

            .replace(
                'class="campaign-banner-price-bottom maison"',
                'class="campaign-banner-price-bottom maison" style= "display: grid;"',
            );
        if (props.property.oldPriceAllIn == props.property.priceAllIn) {
          divAllIn = divAllIn.replace('[[OldPrice]]', ``);
        } else {
          divAllIn = divAllIn.replace('[[OldPrice]]', `${props.property.oldPriceAllIn?.toLocaleString('nl-be')}€`);
        }

        htmlBanner.push(`<style>${value.css}</style>${div}`);
        htmlBannerAllIn.push(`${divAllIn}`);
      }
    });

    price = htmlBanner.join('');
    priceStatus = PropertyStatus.MEDIA;
    priceAllIn = htmlBannerAllIn.join('');
    priceAllInStatus = PropertyStatus.MEDIA;
    if (!price) {
      price = `<p class="price--orange">${props.property.price?.toLocaleString('nl-be')}€ <span class="price--note">${pricingConditionPrice.map((item) => (`(${item.symbol})`))}</span></p>`;
      priceStatus = '';

      // disable price if 0, null, undefined
      if ((props.property.price < 1 || props.property.price === undefined || props.property.price === null) && props.property.status !== PropertyStatus.SOLD) {
        price =`<p class="price"> - </p>`;
        priceStatus = '-';
      }
    }
    if (!priceAllIn) {
      priceAllIn = `<p class="price--black">-</p>`;
      if (props.property.priceAllIn?.toLocaleString('nl-be') !== undefined && props.property.priceAllIn?.toLocaleString('nl-be').toString() !== '0') {
        priceAllIn = `<p class="price--black">${props.property.priceAllIn?.toLocaleString('nl-be')}€ <span class="price--note">${pricingConditionPrice.map((item) => (`(${item.symbol})`))}</span></p>`;
      }
      priceAllInStatus = '';

      // disable priceAllIn if 0, null, undefined
      if ((props.property.priceAllIn < 1 || props.property.priceAllIn === undefined || props.property.priceAllIn === null) && props.property.status !== PropertyStatus.SOLD) {
        priceAllIn =`<p class="price"> - </p>`;
        priceAllInStatus = '-';
      }
    }
    // if price have option
    if (props.property.option !== undefined && props.property.option.toLocaleLowerCase() === 'reservation') {
      price =`<p class='price option'> ${t('Option')} </p>`;
      priceAllIn =`<p class='price option'> ${t('Option')} </p>`;
      priceStatus = PropertyStatus.OPTION;
      priceAllInStatus = PropertyStatus.OPTION;
    }
  }

  if (props.mobile) {
    return (
      <li>
        <div className="project-property-mobile">
          {/* TODO: Hidden for now  */}
          <div className={`${props.columns.includes('js-project-phase')? ' ' :'d-none'} project-property-mobile__residence`}>
            <span dangerouslySetInnerHTML={{__html: props.property.projectPhase == null ? '<span className="no-table-data">-</span>' : props.property.projectPhase}} className={`project-property-mobile__residence-title ${availableClassMobile}`}></span>
            <span className={`project-property-mobile__residence-validation ${residenceClassMobile}`}>{t('seen')} <img src={iconURL('icon-checkmark.svg')} alt="checkmark" /> </span>
          </div>
          <div className="project-property-mobile__body">
            <div className={`${props.columns.includes('js-project-property-name')? ' ' :'d-none'} project-property-mobile__wrapper`} >
              <div className="project-property-mobile__item">
                <p className="project-property-mobile__label">{props.isTPHome ? t('Unit'):t('Main unit')}</p>
                <p className="project-property-mobile__value">{props.property.projectPropertyName}</p>
              </div>
            </div>
            <div className={`${props.columns.includes('js-description')? ' ' :'d-none'} project-property-mobile__wrapper`} >
              <div className="project-property-mobile__item">
                <p className="project-property-mobile__label">{t('Description')}</p>
                <p className="project-property-mobile__value">{props.property.description}</p>
              </div>
            </div>
            <div className="project-property-mobile__wrapper project-property-mobile__wrapper--two-column">
              {props.columns.includes('js-number-of-rooms') ?
                  (<div className={`project-property-mobile__item`}>
                    <p className="project-property-mobile__label">{t('Rooms')}</p>
                    <p dangerouslySetInnerHTML={{__html: numberOfRooms}} className="project-property-mobile__value"></p>
                  </div>):
                  ('')
              }

              {props.columns.includes('js-floor') ?
                  (<div className={`project-property-mobile__item`}>
                    <p className="project-property-mobile__label">{t('Floor')}</p>
                    <p dangerouslySetInnerHTML={{__html: floor}} className="project-property-mobile__value"></p>
                  </div>):
                  ('')
              }

              {props.columns.includes('js-livable') ?
                  (<div className={`project-property-mobile__item`}>
                    <p className="project-property-mobile__label">{t('Living space')}</p>
                    <p dangerouslySetInnerHTML={{__html: surface}} className="project-property-mobile__value"></p>
                  </div>):
                  ('')
              }

              {props.columns.includes('js-balcony-garden') ?
                  (<div className={`project-property-mobile__item`}>
                    <p className="project-property-mobile__label">{t('Land surface')}</p>
                    <p className="project-property-mobile__value">{props.property.surfaceOfBlaconyAndGardenM2} m<sup>2</sup></p>
                  </div>):
                  ('')
              }

              {props.columns.includes('js-terrain') ?
                  (<div className={`project-property-mobile__item`}>
                    <p className="project-property-mobile__label">{t('Land surface')}</p>
                    <p className="project-property-mobile__value">{props.property.terrain} m<sup>2</sup></p>
                  </div>):
                  ('')
              }
            </div>

            <div className="project-property-mobile__wrapper project-property-mobile__wrapper--price">
              <div className="project-property-mobile__item project-property-mobile__item--price">
                <p className="project-property-mobile__label">{(props.templateConfig.country_code === 'BE') ? (<>{t('Main property price')}</>):(<>{t('Property price')}</>)} <span className="project-property-mobile__conditions">(1)</span></p>
                {(priceStatus === '' && props.property.oldPrice && props.property.oldPrice > props.property.price) && <p className="project-property-mobile__value project-property-mobile__value--small">{props.property.oldPrice?.toLocaleString('nl-be') || '-'}€</p>}
                <div className={`project-property-mobile__value ${priceStatus === PropertyStatus.OPTION ? 'project-property-mobile__value--darkgray':'project-property-mobile__value--orange'}`} dangerouslySetInnerHTML={{__html: price}}></div>
              </div>
              {(props.templateConfig.country_code === 'BE') ? (
              <div className="project-property-mobile__item">
                <p className="project-property-mobile__label">{t('Full price')} <span className="project-property-mobile__conditions">(2)</span></p>
                {(priceAllInStatus === '' && props.property.oldPriceAllIn && props.property.oldPriceAllIn > props.property.priceAllIn) && <p className="project-property-mobile__value project-property-mobile__value--small">{props.property.oldPriceAllIn?.toLocaleString('nl-be') || '-'}€</p>}
                <div className={`project-property-mobile__value ${priceAllInStatus === PropertyStatus.SOLD ? 'project-property-mobile__value--orange':'project-property-mobile__value--darkgray'}`} dangerouslySetInnerHTML={{__html: priceAllIn}}></div>
              </div>
                  ):(<></>)}
            </div>

            {props.isTPHome ? (
                  <div className="list-block-cta">
                    <button className="btn btn-large btn-primary btn-border-only btn-modal-absolute-react"
                      onClick={handleModal}
                      projectproperyid={props.property.projectPropertyId}
                      cptcode={props.property.cptCode}
                      projectsocicode={props.property.projectSociCode}
                      unitcode={props.property.unitCode}
                      floor={props.property.floor}
                    >{t('More information')}</button>
                  </div>
              ) : (
                  <button
                    onClick={handleModal}
                    projectproperyid={props.property.projectPropertyId}
                    cptcode={props.property.cptCode}
                    projectsocicode={props.property.projectSociCode}
                    unitcode={props.property.unitCode}
                    floor={props.property.floor}
                    className="btn btn-modal-absolute-react project-property-mobile__cta">
                    {t('Detailed price & pictures')}
                  </button>
              )}

          </div>
        </div>
      </li>
    );
  }

  return (
    <tr>
      <td className={`${props.columns.includes('js-project-phase')? ' ' :'d-none'} ${availableClass} ${residenceClass} ${withoutPhase}`}>
        <p>{props.property.projectPhase == null ? '-' : props.property.projectPhase }  <span>vu <i className="icon-validation"></i> </span></p>
      </td>
      <td className={`${props.columns.includes('js-project-property-name')? ' ' :'d-none'} ${props.isTPHome? 'new-unit-item--bat': 'new-unit-item' }`}>
        <p>{props.property.projectPropertyName}</p>
      </td>
      <td className={`${props.columns.includes('js-description')? ' ' :'d-none'} new-description-item`}>
        <p >{props.property.description}</p>
      </td>
      <td className={`${props.columns.includes('js-number-of-rooms')? ' ' :'d-none'} new-room-item`}>
        <p dangerouslySetInnerHTML={{__html: numberOfRooms}}></p>
      </td>
      <td className={`${props.columns.includes('js-floor')? ' ' :'d-none'} floor-item`}>
        <p dangerouslySetInnerHTML={{__html: floor}}></p>
      </td>
      <td className={`${props.columns.includes('js-livable')? ' ' :'d-none'} new-area-item`}>
        <p dangerouslySetInnerHTML={{__html: surface}}></p>
      </td>
      <td className={`${props.columns.includes('js-balcony-garden')? ' ' :'d-none'} new-area-item`}>
        <p>{props.property.surfaceOfBlaconyAndGardenM2} m<sup>2</sup></p>
      </td>
      <td className={`${props.columns.includes('js-terrain')? ' ' :'d-none'} new-area-item`}>
        <p>{props.property.terrain} m<sup>2</sup></p>
      </td>

      <td className="js-price main-price-item">
        <div className="price-promo-container">
          {(priceStatus === '' && props.property.oldPrice && props.property.oldPrice > props.property.price) && <p className="price--old">{props.property.oldPrice?.toLocaleString('nl-be') || '-' }€</p>}
          <div className="campaign-price-promo" dangerouslySetInnerHTML={{__html: price}}></div>
        </div>
      </td>
      {(props.templateConfig.country_code === 'BE') && (
        <td className="js-full-price full-price-item">
          <div>
            {(() => {
              if (priceAllInStatus === '-' && (props.property.option !== 'Any') && props.property.option === 'Reservation') {
                return (
                  <p className="price option">{t('Option')}</p>
                );
              } else {
                return (
                  <>{(priceAllInStatus === '' && props.property.oldPriceAllIn > props.property.priceAllIn) && <p className="price--old">{props.property.oldPriceAllIn?.toLocaleString('nl-be') || '-'}€</p>}
                    <div dangerouslySetInnerHTML={{__html: priceAllIn}}></div></>
                );
              }
            })()}
          </div>
        </td>
      )}
      <td className="js-action new-action-item">
        <button
          onClick={handleModal}
          projectproperyid={props.property.projectPropertyId}
          cptcode={props.property.cptCode}
          projectsocicode={props.property.projectSociCode}
          unitcode={props.property.unitCode}
          floor={props.property.floor}
          className="btn btn-large btn-secondary btn-modal-absolute-react">
          {t('Detailed price & pictures')}
        </button>
      </td>
    </tr>
  );
};

PropertyItemNew.propTypes = {
  property: PropTypes.object,
  project: PropTypes.object,
  hideFloor: PropTypes.bool,
  hideRoom: PropTypes.bool,
  mediaBanner: PropTypes.object,
  openPropertyModal: PropTypes.func,
  mobile: PropTypes.bool,
  isTPHome: PropTypes.bool,
  columns: PropTypes.array,
  templateConfig: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps, {
  openPropertyModal,
},
)(PropertyItemNew);
