import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  createParamsInvestFiltering,
  createRequestSearchInvestProjects,
} from '../../pages/action';
import PriceFilter from './control/PriceFilter';
import TypeFilter from './control/TypeFilter';
import ProvinceFilter from './control/ProvinceFilter';
import {toggleLeftFilter, toggleRightFilter} from './action';
import {withTranslation} from 'react-i18next';


/**
 * Class for filtering project.
 */
class ProjectInvestFilter extends React.Component {
  /**
   * @param {*} props props object.
   */
  constructor(props) {
    super(props);
    this.state = {
      provinces: {},
      minPrice: 0,
      maxPrice: 0,
      filterPrice: null,
      btnLoading: false,
      showLeftFilter: true,
      showRightFilter: true,
      resetTimer: 1,
      isSearching: false,
    };
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.provinces = props.getProjectInvestFilters.provinces;
    state.minPrice = props.getProjectInvestFilters.minPrice;
    state.maxPrice = props.getProjectInvestFilters.maxPrice;
    state.isSearching = props.isSearching;
    return state;
  }

  /**
   * Clear  filters
   */
  clearFilters() {
    this.setState({
      resetTimer: Date.now(),
    });

    let params = this.props.getParamsInvestFiltering;

    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        generalizedTypeOfProperty: [
          'House',
          'Appartment',
        ],
        priceMin: null,
        priceMax: null,
        provinceIds: [],
      },
    };
    this.props.createParamsInvestFiltering(params);
  }

  /**
   * Toggle Left Filter.
   */
  async showLeftFilter() {
    this.setState({
      showLeftFilter: !this.state.showLeftFilter,
    });
  }

  /**
   * Toggle Right Filter.
   */
  async showRightFilter() {
    this.setState({
      showRightFilter: !this.state.showRightFilter,
    });
  }

  /**
   * Call parent search function
   */
  searchItems() {
    if (!this.state.isSearching) {
      this.setState({
        isSearching: true,
      });
      this.props.handleSearch(1);
    }
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    return (
      <Fragment>
        <div className='investir-filter-container-react filterContainer'>

          <div className="row">
            <div className="col-6">
              <button className=
                {`title-col 
                ${this.state.showLeftFilter ? ' ' : 'is-collapsed'}
                `}
              onClick={() => this.showLeftFilter()}
              >
                <span className="title-col-text">{this.props.t('Where to invest?')}</span>
                <i className="fas fa-chevron-down"></i>
              </button>
              <div className="white-col">
                <ProvinceFilter history={this.props.history} resetTimer={this.state.resetTimer} />
              </div>
            </div>
            <div className="col-6">
              <button className=
                {`title-col 
                ${this.state.showRightFilter ? ' ' : 'is-collapsed'}
                `}
              onClick={() => this.showRightFilter()}>
                <span className="title-col-text">{this.props.t('Amount of investment?')}</span>
                <i className="fas fa-chevron-down"></i>
              </button>
              <div className="white-col">
                <PriceFilter history={this.props.history} resetTimer={this.state.resetTimer}/>
                <TypeFilter history={this.props.history} resetTimer={this.state.resetTimer}/>

                <div className="btn-filter-container clear-filter">
                  <button className="btn btn-clear-filter" onClick={ () => this.clearFilters() }>
                    {this.props.t('Erase')}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="btn-filter-container">
                <button className="btn btn-invest-search" onClick={ () => this.searchItems() }>
                  { this.state.isSearching ? (<i className="fas fa-spinner fa-spin"></i>) : this.props.t('Search') }
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ProjectInvestFilter.propTypes = {
  history: PropTypes.object,
  getRequestSearchInvestProjects: PropTypes.objectOf(PropTypes.any),
  createRequestSearchInvestProjects: PropTypes.func,
  projectPropertiesSearchCriteria: PropTypes.array,
  toggleLeftFilter: PropTypes.func,
  toggleRightFilter: PropTypes.func,
  getLeftFilter: PropTypes.object,
  getRightFilter: PropTypes.object,
  getProjectInvestFilters: PropTypes.object,
  getParamsInvestFiltering: PropTypes.object,
  templateConfig: PropTypes.object,
  handleSearch: PropTypes.func,
  createParamsInvestFiltering: PropTypes.func,
  t: PropTypes.func,
  isSearching: PropTypes.bool,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createParamsInvestFiltering,
      createRequestSearchInvestProjects,
      toggleLeftFilter,
      toggleRightFilter,
    },
)(withTranslation()(ProjectInvestFilter));
