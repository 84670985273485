import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Trans, useTranslation} from 'react-i18next';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {BubleLoading} from '../helpers/loading';
import JobCardWrapper from '../components/JobCard/JobCardWrapper';
import * as JobApi from '../services/JobApi';
import {COUNTRY_LANGUAGE_MAPPING, EMPLOYE_VACANCY_TYPE_IDS, OUVRIER_VACANCY_TYPE_IDS, STAGAIRE_VACANCY_TYPE_IDS} from '../helpers/constants';

/**
 * Page Job Category Element
 * @return {JSX.Element} The rendered component.
 */
const PageJobCategory = () => {
  const ALL_COUNTRIES = 'ALL_COUNTRIES';
  const {t, i18n} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [vacancyTypeIds, setVacancyTypeIds] = useState([]);
  const [jobCountryIds, setJobCountryIds] = useState([]);
  const [isSpontaniousApplication, setIsSpontaniousApplication] = useState(false);
  const [countries, setCountries] = useState([{
    id: 0,
    countryFR: 'Toutes les offres',
    countryNL: 'Alle jobs',
    countryEN: 'All jobs',
    countryCode: ALL_COUNTRIES,
  }]);
  const [jobs, setJobs] = useState([]);

  // Effect to load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);

      const url = window.location.href;
      const vacancyType = decodeURI(url.substring(url.lastIndexOf('/') + 1)).toLowerCase();

      // Determine the vacancy type IDs
      let determinedVacancyTypeIds = [];
      switch (vacancyType) {
        case 'employé':
          determinedVacancyTypeIds = EMPLOYE_VACANCY_TYPE_IDS;
          break;
        case 'ouvrier':
          determinedVacancyTypeIds = OUVRIER_VACANCY_TYPE_IDS;
          break;
        case 'stagiaire et apprenti':
          determinedVacancyTypeIds = STAGAIRE_VACANCY_TYPE_IDS;
          break;
        case 'spontanément':
          setIsSpontaniousApplication(true);
          setIsLoading(false);
          return;
        default:
          determinedVacancyTypeIds = [];
          break;
      }
      setVacancyTypeIds(determinedVacancyTypeIds);

      // Get all initial application (filter) values & get the vacancy type ID
      const initialValues = await JobApi.getInitialApplicationValues();

      // Now that we have the initial application values, get the countries for which we have jobs
      const jobSearchResults = await JobApi.searchJobs(i18n.language, {
        vacancyTypeIds: [],
        jobCountryIds: [],
      });

      // Compare res.jobs jobCountryCode with initialApplicationValues jobCountries countryCode
      const countriesWithJobs = initialValues.jobCountries?.filter((country) => {
        return jobSearchResults.jobs.some((job) => (job.jobCountryCode === country.countryCode) && (country.countryCode !== ''));
      });
      setCountries((prevCountries) => [...prevCountries, ...countriesWithJobs]);

      setIsLoading(false);
    };

    loadInitialData();
  }, []);

  // Effect to update jobs and countries when vacancyTypeIds or jobCountryIds change
  useEffect(() => {
    const updateJobs = async () => {
      setIsLoading(true);

      // Get all jobs for this vacancy type
      const jobSearchResults = await JobApi.searchJobs(i18n.language, {
        vacancyTypeIds,
        jobCountryIds,
      });

      // Update the state with the filtered countries
      setJobs(jobSearchResults.jobs);
      setIsLoading(false);
    };

    if (vacancyTypeIds.length > 0) {
      updateJobs();
    }
  }, [vacancyTypeIds, jobCountryIds]);

  const handleTabClick = (countryId) => {
    if (!countryId) return;

    const selectedCountries = countryId === '' ? [] : [countryId];
    setJobCountryIds(selectedCountries);
  };

  return (
    isSpontaniousApplication ? null : (
    !isLoading ? (
      <Tabs>
        <TabList>
          {countries.map((country, i) => (
            <Tab key={i} onSelect={() => handleTabClick(country.id)}>
              {country[COUNTRY_LANGUAGE_MAPPING[i18n.language] ?? COUNTRY_LANGUAGE_MAPPING['fr']]}
            </Tab>
          ))}
        </TabList>
        {countries.map((country, i) => {
          const filteredJobs = (country.countryCode === ALL_COUNTRIES) ? jobs : jobs.filter((j) => j.jobCountryCode === country.countryCode);
          return (
            <TabPanel key={i}>
              <JobCardWrapper
                heading={
                  <p className='job-card-wrapper__head'>
                    <Trans
                      i18nKey="We have found <span>{{amount}}</span> job offers matching your criteria."
                      values={{amount: filteredJobs.length}}
                      components={{span: <span className='job-card-wrapper__head__highlight' />}}
                    />
                  </p>
                }
                jobs={filteredJobs}
                countries={countries}
              />
            </TabPanel>
          );
        })}
      </Tabs>
    ) : (
      <div className="preloader">
        <BubleLoading key={Math.random()} text={t('Loading')} />
      </div>
    )
  ));
};

PageJobCategory.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  history: PropTypes.object,
};

export default PageJobCategory;
