import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {iconURL} from '../../helpers/helper';


const ProjectProximiteChild = ({icon, list, title}) => {
  const [isShow, setShow] = useState(false);
  return <div className="project-detail-proximite__box-child">
    <div className="project-detail-proximite__box-img-wrap">
      <img src={icon || iconURL('icon-image-default.svg')} alt="icon" />
    </div>
    <div className="project-detail-proximite__box-content-wrap">
      <div className="project-detail-proximite__box-content-header">
        <div className="project-detail-proximite__box-content-title">{title}</div>
        <div className="project-detail-proximite__box-content-vehicle">
          <div><img src={iconURL('icon-people.svg')} alt="icon people" /></div>
          <div><img src={iconURL('icon-bicycle.svg')} alt="icon bicycle" /></div>
          <div><img src={iconURL('icon-car.svg')} alt="icon car" /></div>
        </div>
      </div>
      <div className="project-detail-proximite__cta-wrap">
        <div className={`project-detail-proximite__cta ${isShow ? '' : 'is-hide'}`} onClick={() => setShow(!isShow)}><i className="fas fa-chevron-down"></i></div>
      </div>
      <ul className={`project-detail-proximite__list ${isShow ? '' : 'is-hide'}`}>
        {list.map((value, index) => {
          return (
            <li key={index} className="project-detail-proximite__list-item">
              <div>
                <p className="project-detail-proximite__text">{value.name}</p>
              </div>
              <div className="project-detail-proximite__duration">
                <span>{(value.durationByFoot > 0) ? `${value.durationByFoot} min` : '-'}</span>
                <span>{(value.durationByBike > 0) ? `${value.durationByBike} min` : '-'}</span>
                <span>{(value.durationByCar > 0) ? `${value.durationByCar} min` : '-'}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  </div>;
};

ProjectProximiteChild.propTypes = {
  icon: PropTypes.string,
  list: PropTypes.array,
  title: PropTypes.string,
};


export default ProjectProximiteChild;
