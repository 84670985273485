import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import JobCard from './JobCard';
import Pager from 'react-pager';

/**
 * Main Component
 * @return {JSX.Element} The rendered component.
 */
const JobCardWrapper = ({heading, jobs, countries}) => {
  const MAX_VISIBLE_PAGES = 5;
  const ITEMS_PER_PAGE = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const [visibleJobs, setVisibleJobs] = useState([]);

  useEffect(() => {
    setVisibleJobs(jobs.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE));
  }, [jobs, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.round(pageNumber));
  };

  return (
    <div className='job-card-wrapper'>
      { heading ? <p>{heading}</p> : null }
      <div className='job-card-wrapper__container'>
        {visibleJobs?.map((job, i) => (<JobCard key={i} job={job} countries={countries} />))}
      </div>
      {jobs.length > ITEMS_PER_PAGE ? (
        <Pager
          className='job-card-wrapper__pager'
          total={Math.ceil(jobs.length / ITEMS_PER_PAGE)}
          current={currentPage}
          visiblePages={MAX_VISIBLE_PAGES}
          titles={{first: '<', last: '>'}}
          onPageChanged={handlePageChange}
        />
      ) : null }
    </div>
  );
};

JobCardWrapper.propTypes = {
  heading: PropTypes.object,
  jobs: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
};

export default JobCardWrapper;
