import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {createContactInformation} from '../../components/ProjectImageFooter/action';
import {iconURL} from '../../helpers/helper';
import {useTranslation} from 'react-i18next';


const PriceTooltip = ({contactInformation, details, property, createContactInformation, getContactFormInformation}) => {
  const {t} = useTranslation();
  const [tooltip, setTooltip] = useState(false);
  const tooltipClass = tooltip ? 'is-show' : '';

  const openTooltip = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTooltip(true);
  };

  const closeTooltip = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTooltip(false);
  };

  const openModal = () => {
    getContactFormInformation.showModal = true;
    getContactFormInformation.sociCode =
      contactInformation.sociCode;
    getContactFormInformation.contactId =
      contactInformation.id;
    getContactFormInformation.pointContactType =
      contactInformation.pointContactType;
    getContactFormInformation.projectName =
      contactInformation.projectName;
    getContactFormInformation.purpose =
      'visit_demonstration_apartment';
    getContactFormInformation.projectId =
      contactInformation.projectId;
    getContactFormInformation.contactUs = false;
    getContactFormInformation.isAcheter = false;
    createContactInformation(getContactFormInformation);
  };

  return (property && property.conditions && property.conditions.length > 0) ?
    <React.Fragment>
      <div className="tooltip-wrap-nd">
        {(property.noIcon === undefined) && <div className="tooltip-content-nd">
          <button className={`tooltip-content-nd__btn ${ tooltipClass}`} onClick={ openTooltip }>
            <img className="banner-triangle-icon" src={iconURL('icon-info-nd.svg')} />
          </button>
          <div className={`tooltip-content-nd__wrap ${ tooltipClass }`} id="tooltipProjectDetail">
            <div className="tooltip-content-nd__content-header">
              <button className="tooltip-content-nd__content-header-btn close-tooltip" onClick={ closeTooltip }><span></span><span></span></button>
            </div>
            <div className={`tooltip-content-nd__content-wrap`}>
              {property.conditions.map((item, index) => (
                <div key={`tooltip-content-nd-${index}`} dangerouslySetInnerHTML={{__html: item.description}}></div>
              ))}
            </div>
            <div className="tooltip-content-nd__cta">
              <button className="tooltip-content-nd__cta-btn close-tooltip" onClick = { closeTooltip }>{t('I understood')}</button>
              <button className="tooltip-content-nd__cta-btn" onClick = { openModal }>
                {t('Contact us')}</button>
            </div>
          </div>
        </div>}

        <div className="tooltip-wrap-nd__text">
          {property.conditions.map((item, index) => (
            <p key={`tooltip-title-${index}`}>({item.symbol}) {item.title}</p>
          ))}
          {tooltip}
        </div>

      </div>
    </React.Fragment>:
    <React.Fragment>
      <div className="tooltip-wrap-nd"></div>
    </React.Fragment>;
};
PriceTooltip.propTypes = {
  contactInformation: PropTypes.object,
  details: PropTypes.object,
  property: PropTypes.object,
  createContactInformation: PropTypes.func,
  getContactFormInformation: PropTypes.object,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps,
    {createContactInformation},
)(PriceTooltip);
