import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-contexify/dist/ReactContexify.css';
import {withTranslation} from 'react-i18next';

/**
 * class
 */
class ProjectInvestItem extends React.Component {
  /**
   * @param {*} props props object.
   */
  constructor(props) {
    super(props);

    this.state = {
      propertyText: '',
    };
  }

  /**
   * Genereate string for proprety intro
   */
  calculateProperties() {
    let propText = '';

    if ( this.props.project.typeOfPropertiesCount !== undefined) {
      this.props.project.typeOfPropertiesCount.forEach((prop) => {
        if (propText !== '') {
          propText += ' & ';
        }
        propText += prop.count + ' ' + prop.typeOfPropertyTranslation;
      });

      this.setState({
        propertyText: propText,
      });
    }
  }

  /** @return {void} */
  async componentDidUpdate() {
    this.calculateProperties();
  }

  /**
   *
   * @param {*} value
   */
  handleProjectClick(value) {
    this.props.handleActiveProject(value);
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    let availableText = 'disponibles';
    if (this.props.project.typeOfPropertiesCount.length === 1 && this.props.project.typeOfPropertiesCount[0].count === 1) {
      availableText = 'disponible';
    }

    return (
      <div className="invest-card-wrapper">
        <a href="javascript:void(0)" className="invest-card" onClick={() => this.handleProjectClick(this.props.project)}>
          <div className="invest-card-header">
            <img src={this.props.project.projectImages[0].imageLink} alt={this.props.project.projectImages[0].alt}/>
            <div className="invest-card-header-info">
              <div className="invest-card-city">{this.props.project.city}</div>
              <div className="invest-card-name">{this.props.project.projectName}</div>
              <div className="invest-card-province">{this.props.project.province}</div>
            </div>
          </div>
          <div className="invest-card-body">
            <div className="invest-card-intro-wrapper">
              <div className={'invest-intro'}>{this.state.propertyText} <span className={'invest-black'}>{availableText}</span></div>
              <div className={'invest-address'}>{this.props.project.address}</div>
            </div>
            <div className="invest-card-price-wrapper">
              <div className="invest-card-price-container">
                <div className="invests-price-intro">À partir de</div>
                <div className="invest-price-big">{new Intl.NumberFormat('de-DE').format(Math.round(this.props.project.cheapestPropPrice))} €</div>
                {this.props.project.cheapestPropOriginalPrice !== 0 && this.props.project.cheapestPropOriginalPrice !== this.props.project.cheapestPropPrice && (
                  <div className="invest-price-small">{new Intl.NumberFormat('de-DE').format(Math.round(this.props.project.cheapestPropOriginalPrice))} €</div>
                )}
              </div>
              <button className="btn btn-invest-card" onClick={ () => {} }>
                {this.props.t('View the properties')}
              </button>
            </div>
          </div>
        </a>
      </div>
    );
  };
}

ProjectInvestItem.propTypes = {
  history: PropTypes.object,
  project: PropTypes.object,
  handleActiveProject: PropTypes.func,
  t: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {},
)(withTranslation()(ProjectInvestItem));
