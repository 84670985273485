import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createContactInformation} from './action';
import {getShowHouseContactPoints} from '../../services/ExternalApi';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import {SlideDown} from 'react-slidedown';
import {isMobileOnly} from 'react-device-detect';
import {withTranslation} from 'react-i18next';

/**
 * Main Component
 */
class ShowhouseAppointment extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        maisonExpo: {
          message: props.t('Please indicate your exhibition house'),
          rule: (val, params, validator) => {
            return ( val === {} );
          },
        },
      },
    });
    this.state = {
      listShowhouse: [],
      options: [],
      step: 1,
      currentShowhouse: {},
      closed: false,

    };
  }

  /**
   * @return {void}
   *
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);
    const res = await getShowHouseContactPoints();
    const result = res.data;
    const listShowhouse = [];
    const options = [];
    for (const item of result.projectContactPoints) {
      listShowhouse[item.id] = {
        'id': item.id,
        'title': item.title,
        'type': item.type,
        'openingHoursInfo': item.openingHoursInfo,
        'coordinates': item.coordinates,
        'address': item.address,
        'phoneNumber': item.phoneNumber,
      };
      options.push({
        'value': item.id,
        'label': item.title,
      });
    };
    this.setState({
      options,
      listShowhouse,
    });
    if (typeof this.props.expand === 'undefined' && isMobileOnly) {
      this.setState({
        closed: true,
      });
    }
  }

  /**
   *
   * @param {*} coordinate;
   */
  handleClick = (coordinate) => {
    window.open('https://maps.google.com?q='+coordinate.lat+','+coordinate.lon );
  }

  /**
   *
   * @param {*} coordinate;
   */
  openModal() {
    this.props.getContactFormInformation.showModal = true;
    this.props.getContactFormInformation.sociCode =
        this.props.contactInformation.sociCode;
    this.props.getContactFormInformation.contactId =
        this.state.currentShowhouse.id;
    this.props.getContactFormInformation.pointContactType =
        this.state.currentShowhouse.type;
    this.props.getContactFormInformation.projectName =
        this.props.contactInformation.projectName;
    this.props.getContactFormInformation.showhouseName =
        this.state.currentShowhouse.title;
    this.props.getContactFormInformation.purpose =
        'visit_demonstration_apartment';
    this.props.getContactFormInformation.projectId =
        this.props.contactInformation.projectId;
    this.props.getContactFormInformation.contactUs = false;
    this.props.getContactFormInformation.isAcheter = false;
    this.props.createContactInformation(this.props.getContactFormInformation);
  }

  /**
   *
   * @return {void}
   */
  nextStep() {
    if (this.state.currentShowhouse.id) {
      this.validator.hideMessages();
      this.setState({
        step: 2,
      });
    } else {
      this.validator.showMessages();
    }
    this.forceUpdate();
  }

  /**
   *
   * @return {void}
   */
  prevStep() {
    this.setState({
      step: 1,
    });
  }

  /**
   *
   * @return {void}
   */
  handleChange() {
    const inputValue = document.getElementsByName('select-location')[0].value;
    let currentShowhouse = {};
    if (inputValue !== '') {
      currentShowhouse = this.state.listShowhouse[inputValue];
    }
    this.setState({
      currentShowhouse,
    });
  }

  /**
   *
   * @return {void};
   */
  openMap() {
    const coordinate = this.state.currentShowhouse.coordinates;
    window.open('https://maps.google.com?q='+coordinate.lat+','+coordinate.lon );
  }

  /**
   * toggle
   * @param {boolean} expand;
   */
  toggle(expand) {
    if (expand) return;
    this.setState({closed: !this.state.closed});
  }


  /**
   *
   * @return {JSX.Element}
   */
  render() {
    const showhouseSelect = <Select
      id="selectLocation"
      className="select-location"
      classNamePrefix="select-location"
      name="select-location"
      placeholder={this.props.t('Postal code, city...')}
      onInputChange={this.handleChange.bind(this)}
      options={this.state.options}
    />;
    const phase1 = {
      display: (this.state.step == 1) ?
          'block' : 'none',
    };
    const phase2 = {
      display: (this.state.step == 2) ?
          'block' : 'none',
    };
    const showhouse = this.state.currentShowhouse;
    return (
      <div className="card-maisonexpo-container">
        <div className="card-maisonexpo">
          <div className="card-maisonexpo-header" onClick={ ()=> this.toggle(this.props.expand) }>
            <div className="header-title-wrapper">
              <h4>{this.props.t('Meet up')}</h4>
              <h5>{this.props.t('in the exhibition house')}</h5>
            </div>
            <div className="image-wrapper">
              <img src={`${process.env.REACT_APP_BASE_DRUPAL_URL}/themes/thomaspiron/images/booking/tp-mobile-header.png`} />
            </div>
          </div>
          <SlideDown className={`card-maisonexpo-body ${this.state.closed ? 'is-close' : ''}`} closed={ this.state.closed }>
            <div id="stepOne"
              className="wrapper"
              style={phase1}
            >
              <div className="card-maisonexpo-body-text step">
                <h4>{this.props.t('in the exhibition house')}</h4>
                <p>{this.props.t(`Let's meet in one of our exhibition houses to present to you in detail the advantages of this property.`)}</p>
                <div className="card-maison-expo-select">
                  {showhouseSelect}
                </div>
                {this.validator.message(
                    'maisonexpo', this.state.currentShowhouse,
                    'maisonExpo',
                )}
              </div>
              <div className="card-maisonexpo-body-cta">
                <button className="btn btn-secondary btn-large btn-full"
                  onClick={this.nextStep.bind(this)}
                >
                  {this.props.t('Continue')}
                </button>
              </div>
            </div>
            <div id="stepTwo"
              className="wrapper"
              style={phase2}
            >
              <div className="card-maisonexpo-body-text step-last">
                <h3 className="title-maisonexpo" id="showhouse-type">
                  {showhouse.type}
                </h3>
                <div className="field-body">
                  <p className="top-title">
                    <i className="fas fa-map-marker-alt"></i>
                    <span>{this.props.t('Location').toUpperCase()}</span>
                  </p>
                  <p className="bottom-title" id="showhouse-localisation">
                    {showhouse.address}
                  </p>
                </div>
                <div className="field-body"><p className="top-title">
                  <i className="fas fa-clock"></i>
                  <span>{this.props.t('Opening hours').toUpperCase()}</span></p>
                <ul
                  className="bottom-list"
                  id="showhouse-opening-hour"
                  dangerouslySetInnerHTML={{__html: showhouse.openingHoursInfo}}
                />
                </div>
                <div className="field-body">
                  <p className="top-title">
                    <i className="fas fa-phone"></i>
                    <span>{this.props.t('Contact').toUpperCase()}</span>
                  </p>
                  <a href={'tel:'+ showhouse.phoneNumber}
                    id="showhouse-contact"
                    className="bottom-anchor"
                  >{showhouse.phoneNumber}</a>
                </div>
              </div>
              <div className="card-maisonexpo-body-cta">
                <button
                  className="btn btn-large btn-border-only btn-primary btn-full"
                  onClick={this.prevStep.bind(this)}
                >
                  {this.props.t('Change exhibition house')}
                </button>
                <a
                  className="btn btn-large btn-border-only
                  btn-primary btn-full btn-direction"
                  onClick={this.openMap.bind(this)}
                >
                  {this.props.t('Locate the exhibition house')}
                </a>
                <a
                  className="btn btn-large btn-primary
                  btn-modal-absolute-react btn-full"
                  data-modal="modal-contact-form-react"
                  data-cat-modal="dropdown"
                  onClick={this.openModal.bind(this)}
                >
                  {this.props.t('Request an appointment')}
                </a>
              </div>
            </div>
          </SlideDown>
        </div>
      </div>
    );
  }
}

ShowhouseAppointment.propTypes = {
  contactInformation: PropTypes.any,
  createContactInformation: PropTypes.func,
  t: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  expand: PropTypes.bool,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createContactInformation},
)(withTranslation()(ShowhouseAppointment));
