import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isMobileOnly} from 'react-device-detect';

import {
  createProjectList,
  createRequestGetProjectMarkers,
  createRequestSearchProjects,
  createPurchasePageLastUrl,
  createTotalProjects} from '../../pages/action';
import {createMarkerCollection, createPopupProjectId} from '../MainMap/action';
import {hideMessage} from '../InfoMessage/action';
import {createMapLocation} from './action';
import * as ExternalApi from '../../services/ExternalApi';
import {loadList} from './control/LoadListProject';
import {queryStringFormatter} from '../../helpers/helper';

import PriceFilter from './control/PriceFilter';
import SurfaceFilter from './control/SurfaceFilter';
import RoomFilter from './control/RoomFilter';
import TypeFilter from './control/TypeFilter';
import LocationFilter from './control/LocationFilter';
import {searchLocation} from '../../services/GoogleMapApi';
import TypeFilterMobile from './control/TypeFilterMobile';
import PriceFilterMobile from './control/PriceFilterMobile';
import SurfaceFilterMobile from './control/SurfaceFilterMobile';
import RoomFilterMobile from './control/RoomFilterMobile';
import {toggleFilterMobile} from '../ProjectFilter/action';
import {toggleProjectListMobile, toggleInvestor, toggleAvailable} from '../ProjectList/action';
import {withTranslation} from 'react-i18next';

/**
 * Class for filtering project.
 */
class ProjectFilter extends React.Component {
  /**
   * @param {*} props props object.
   */
  constructor(props) {
    super(props);
    this.state = {
      filterLocation: null,
      filterType: [],
      filterSurface: null,
      filterPrice: null,
      filterRoom: null,
      filterInvestor: false,
      filterImmediatelyAvailable: false,
      btnLoading: false,
      templateConfig: '',
      manageableContents: [],
    };
    this.investorToggler = this
        .investorToggler
        .bind(this);
    this.availableToggler = this
        .availableToggler
        .bind(this);
  }

  /** @return {void} */
  async availableToggler() {
    this
        .props
        .toggleAvailable();
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    this.setState({
      filterImmediatelyAvailable: this.props.getToggleAvailable,
    });
    if (this.props.getToggleInvestor) {
      querySearch = {...querySearch, 'immediately-available': this.props.getToggleAvailable};
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      delete querySearch['immediately-available'];
      if (Object.keys(querySearch).length > 0) {
        const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
        this.props.history.push({search: `?${queryString}`});
      } else {
        this.props.history.push({});
      }
    }
    this.props.createPurchasePageLastUrl(document.URL);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        isImmediatelyAvailable: this.props.getToggleInvestor,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    // this.getProjectCount(params);
    if (isMobileOnly) {
      const searchParams = await this.props.getRequestSearchProjects;
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(searchParams);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
  }

  /** @return {void} */
  async investorToggler() {
    this
        .props
        .toggleInvestor();
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    this.setState({
      filterInvestor: this.props.getToggleInvestor,
    });
    if (this.props.getToggleInvestor) {
      querySearch = {...querySearch, investor: this.props.getToggleInvestor};
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      delete querySearch.investor;
      if (Object.keys(querySearch).length > 0) {
        const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
        this.props.history.push({search: `?${queryString}`});
      } else {
        this.props.history.push({});
      }
    }
    this.props.createPurchasePageLastUrl(document.URL);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        selectInvestor: this.props.getToggleInvestor,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    // this.getProjectCount(params);
    if (isMobileOnly) {
      const searchParams = await this.props.getRequestSearchProjects;
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(searchParams);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
  }

  /**
   * @return {void}
   */
  async componentDidMount() {
    let filterLocation = null;
    let filterType = [];
    let filterSurface = null;
    let filterPrice = null;
    let filterRoom = null;
    let filterInvestor = false;
    let filterImmediatelyAvailable = false;
    const querySearch = queryStringFormatter(this.props.history.location.search);
    let params = await this.props.getRequestSearchProjects;
    params = {
      ...params,
      templateConfig: {
        ...this.props.templateConfig,
      },
    };

    if (querySearch.place) {
      const location = searchLocation(querySearch.place, this.props.templateConfig.country_code);
      location.then((result)=>{
        const place = result.data.results[0];
        this.props.createMapLocation({
          center: place.geometry.location,
          zoom: 12,
          place_name: querySearch.place,
        });
      });
      filterLocation = querySearch.place;
    }
    if (querySearch.type) {
      const queryType = querySearch.type;
      filterType = queryType.split(',').map((el) => {
        const n = Number(el);
        return n === 0 ? n : n || el;
      });
      params = {
        ...params,
        projectPropertiesSearchCriteria: {
          ...params.projectPropertiesSearchCriteria,
          generalizedTypeOfProperty: filterType,
        },
      };
    }
    if (querySearch.minNumberOfM2) {
      const querySurfaceMin = Number(querySearch.minNumberOfM2);
      const querySurfaceMax = Number(querySearch.maxNumberOfM2);
      params = {
        ...params,
        projectPropertiesSearchCriteria: {
          ...params.projectPropertiesSearchCriteria,
          minNumberOfM2: querySurfaceMin,
          maxNumberOfM2: querySurfaceMax,
        },
      };
      filterSurface = {
        Min: querySurfaceMin,
        Max: querySurfaceMax,
      };
    }
    if (querySearch.priceMin && querySearch.priceMax) {
      const queryPriceMin = Number(querySearch.priceMin);
      const queryPriceMax = Number(querySearch.priceMax);
      params = {
        ...params,
        projectPropertiesSearchCriteria: {
          ...params.projectPropertiesSearchCriteria,
          priceMin: queryPriceMin,
          priceMax: queryPriceMax,
        },
      };
      filterPrice = {
        min: queryPriceMin,
        max: queryPriceMax,
      };
    }
    if (querySearch.numberOfRoomsMin) {
      const queryRoomMin = Number(querySearch.numberOfRoomsMin);
      const queryRoomMax = Number(querySearch.numberOfRoomsMax);
      params = {
        ...params,
        projectPropertiesSearchCriteria: {
          ...params.projectPropertiesSearchCriteria,
          numberOfRoomsMin: queryRoomMin,
          numberOfRoomsMax: queryRoomMax,
        },
      };
      filterRoom = {
        Min: queryRoomMin,
        Max: queryRoomMax,
      };
    }
    if (querySearch.investor) {
      const queryInvestor = true;
      params = {
        ...params,
        projectPropertiesSearchCriteria: {
          ...params.projectPropertiesSearchCriteria,
          selectInvestor: queryInvestor,
        },
      };
      filterInvestor = queryInvestor;
      this
          .props
          .toggleInvestor();
    }
    if (querySearch['immediately-available']) {
      const queryImmediatelyAvailable = true;
      params = {
        ...params,
        projectPropertiesSearchCriteria: {
          ...params.projectPropertiesSearchCriteria,
          isImmediatelyAvailable: queryImmediatelyAvailable,
        },
      };
      filterImmediatelyAvailable = queryImmediatelyAvailable;
      this
          .props
          .toggleAvailable();
    }


    this.setState({
      filterLocation: filterLocation,
      filterType: filterType,
      filterSurface: filterSurface,
      filterPrice: filterPrice,
      filterRoom: filterRoom,
      filterInvestor: filterInvestor,
      filterImmediatelyAvailable: filterImmediatelyAvailable,
      templateConfig: this.props.templateConfig,
    });
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));

    this.getHeaderImage();

    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    // this.getProjectCount(params);
    if (isMobileOnly) {
      const searchParams = await this.props.getRequestSearchProjects;
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(searchParams);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
  }

  /**
   * Get CountProject from searchProject
   * @param {*} params object filter value.
   */
  async getProjectCount(params) {
    if (params.countries === undefined) {
      params.countries = [params.templateConfig.country];
    }
    if (params.projectPropertiesSearchCriteria.countries === undefined) {
      params.projectPropertiesSearchCriteria.countries = [params.templateConfig.country];
    }
    const totalProjects = await ExternalApi.countProjects(params);
    this.props.totalProjects(totalProjects.data.count);
  }
  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.manageableContents !== prevProps.manageableContents) {
      this.getHeaderImage();
    }
  }

  /**
   * Submit Type Filter.
   * @param {*} value object filter value.
   */
  async submitTypeFilter(value) {
    this.setState({
      filterType: value,
    });
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        generalizedTypeOfProperty: value,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    const projectMarker = await ExternalApi.getProjectMarkers(markerParams.payload);
    this.props.createMarkerCollection(projectMarker);

    this.getHeaderImage();
    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    } else {
      if (projectMarker.data.projectMarkerDTO.length == 0 ) {
        this.props.createProjectList({'projects': [], 'loading': false});
      }
    }
    if (value.length > 0) {
      querySearch = {...querySearch, type: value.toString()};
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      delete querySearch.type;
      if (Object.keys(querySearch).length > 0) {
        const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
        this.props.history.push({search: `?${queryString}`});
      } else {
        this.props.history.push({});
      }
    }
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Submit Price Filter.
   * @param {*} value object filter value.
   */
  async submitPriceFilter(value) {
    this.setState({
      filterPrice: value,
    });
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        priceMin: value.min,
        priceMax: value.max,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    const projectMarker = await ExternalApi.getProjectMarkers(markerParams.payload);
    this.props.createMarkerCollection(projectMarker);

    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.totalProjects(dataProjects.count);
    } else {
      if (projectMarker.data.projectMarkerDTO.length == 0 ) {
        this.props.createProjectList({'projects': [], 'loading': false});
        this.props.createTotalProjects({'loading': false, 'count': 0});
      }
    }
    querySearch = {...querySearch, priceMin: value.min, priceMax: value.max};
    const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
    this.props.history.push({search: `?${queryString}`});
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Submit Room Filter.
   * @param {*} value object filter value.
   */
  async submitRoomFilter(value) {
    this.setState({
      filterRoom: value,
    });
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        numberOfRoomsMin: value.Min,
        numberOfRoomsMax: value.Max,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    const projectMarker = await ExternalApi.getProjectMarkers(markerParams.payload);
    this.props.createMarkerCollection(projectMarker);
    // this.getProjectCount(params);
    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    } else {
      if (projectMarker.data.projectMarkerDTO.length == 0 ) {
        this.props.createProjectList({'projects': [], 'loading': false});
      }
    }
    querySearch = {...querySearch, numberOfRoomsMin: value.Min, numberOfRoomsMax: value.Max};
    const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
    this.props.history.push({search: `?${queryString}`});
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Submit Surface Filter.
   * @param {*} value object filter value.
   */
  async submitSurfaceFilter(value) {
    this.setState({
      filterSurface: value,
    });
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        minNumberOfM2: value.Min,
        maxNumberOfM2: value.Max,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    const projectMarker = await ExternalApi.getProjectMarkers(markerParams.payload);
    this.props.createMarkerCollection(projectMarker);
    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    } else {
      if (projectMarker.data.projectMarkerDTO.length == 0 ) {
        this.props.createProjectList({'projects': [], 'loading': false});
      }
    }
    querySearch = {...querySearch, minNumberOfM2: value.Min, maxNumberOfM2: value.Max};
    const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
    this.props.history.push({search: `?${queryString}`});
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Clear Type Filter.
   */
  async cancelTypeFilter() {
    this.setState({
      filterType: [],
    });
    let params = await this.props.getRequestSearchProjects;
    const querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        generalizedTypeOfProperty: [],
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    delete querySearch.type;
    if (Object.keys(querySearch).length > 0) {
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      this.props.history.push({});
    }
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));

    this.getHeaderImage();
    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Clear Price Filter.
   */
  async cancelPriceFilter() {
    this.setState({
      filterPrice: null,
    });
    let params = await this.props.getRequestSearchProjects;
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        priceMin: null,
        priceMax: null,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };

    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }

    const querySearch = queryStringFormatter(this.props.history.location.search);
    delete querySearch.priceMin;
    delete querySearch.priceMax;
    if (Object.keys(querySearch).length > 0) {
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      this.props.history.push({});
    }
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Clear Room Filter.
   */
  async cancelRoomFilter() {
    this.setState({
      filterRoom: null,
    });
    let params = await this.props.getRequestSearchProjects;
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        numberOfRoomsMin: null,
        numberOfRoomsMax: null,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };

    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    // this.props.createMarkerCollection(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }

    const querySearch = queryStringFormatter(this.props.history.location.search);
    delete querySearch.numberOfRoomsMin;
    delete querySearch.numberOfRoomsMax;
    if (Object.keys(querySearch).length > 0) {
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      this.props.history.push({});
    }
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // TODO: delete me if filter cancelation or submit not reset zoom
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Clear Surface Filter.
   */
  async cancelSurfaceFilter() {
    this.setState({
      filterSurface: null,
    });
    let params = await this.props.getRequestSearchProjects;
    const querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        minNumberOfM2: undefined,
        maxNumberOfM2: undefined,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    delete querySearch.minNumberOfM2;
    delete querySearch.maxNumberOfM2;
    if (Object.keys(querySearch).length > 0) {
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      this.props.history.push({});
    }
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    // this.props.createMarkerCollection(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    // this.getProjectCount(params);

    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
    this.props.createPurchasePageLastUrl(document.URL);
    this.props.hideMessage();
    // this.props.createMapLocation({
    //   center: this.props.getDefaultCoordinate,
    //   zoom: 7,
    // });
  }

  /**
   * Clear filters
   */
  async clearFilters() {
    if (this.props.getToggleInvestor) {
      this.props.toggleInvestor();
    }

    document.getElementById('place_search').value= '';

    this.setState({
      filterLocation: null,
      filterType: [],
      filterSurface: null,
      filterPrice: null,
      filterRoom: null,
      filterInvestor: this.props.getToggleInvestor,
    });

    let params = await this.props.getRequestSearchProjects;

    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        generalizedTypeOfProperty: [],
        priceMin: null,
        priceMax: null,
        numberOfRoomsMin: null,
        numberOfRoomsMax: null,
        minNumberOfM2: null,
        maxNumberOfM2: null,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
      sorting: {
        centeredCoorinates: {
          lat: this.props.getDefaultCoordinate.lat,
          lon: this.props.getDefaultCoordinate.lng,
        },
        orderByDateASC: false,
        orderByDateDESC: true,
        orderByDistanceASC: true,
        orderByDistanceDESC: true,
      },
    };

    this.props.history.push({});

    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));

    const location = searchLocation('', this.props.templateConfig.country_code);
    location.then((result)=>{
      const place = result.data.results[0];
      this.props.createMapLocation({
        center: place.geometry.location,
        zoom: 7,
        place_name: '',
      });
    });

    this.getHeaderImage();
    // this.getProjectCount(params);

    if (isMobileOnly) {
      const searchParams = await this.props.getRequestSearchProjects;
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(searchParams);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
  }

  /**
   * submit
   */
  async submitHandle() {
    // action
    this.setState({btnLoading: true});
    setTimeout(()=> {
      this.setState({btnLoading: false});
      this.props.toggleFilterMobile(false);
      this.props.toggleProjectListMobile(true);
    }, 2000);
  }

  /**
   * Get header image
   */
  getHeaderImage() {
    const filterType = this.state.filterType;
    const headerImages = this.props.manageableContents.header_images;
    if (headerImages) {
      let banner = headerImages[0];
      const newLogic = [1, 2];
      let cekBanner = 2; // if 0 then condition is reached
      if (filterType.length > 0) {
        for (let i = 0; i < filterType.length; i++) {
          const filterKey = filterType[i];
          const currentBanner = headerImages[filterKey];
          if ( newLogic.indexOf(filterType[i]) > -1) {
            cekBanner--;
          }
          if (i === 0) {
            banner = currentBanner;
          } else {
            //  more than one filter than hide the banner
            banner = {};
          }
        }
        if (cekBanner == 0 && filterType.length == 2) {
          banner = headerImages[0];
        }
      }
      this.props.headerImage(banner);
    }
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    return (
      <Fragment>
        <div className='purchase-filter-container-react filterContainer'>
          <LocationFilter history={this.props.history} filterValue={this.state.filterLocation}/>
          <TypeFilter submitHandle={this.submitTypeFilter.bind(this)} cancelHandle={this.cancelTypeFilter.bind(this)} filterValue={this.state.filterType}/>
          <PriceFilter submitHandle={this.submitPriceFilter.bind(this)} cancelHandle={this.cancelPriceFilter.bind(this)} filterValue={this.state.filterPrice}/>
          <RoomFilter submitHandle={this.submitRoomFilter.bind(this)} cancelHandle={this.cancelRoomFilter.bind(this)} filterValue={this.state.filterRoom}/>
          <SurfaceFilter submitHandle={this.submitSurfaceFilter.bind(this)} cancelHandle={this.cancelSurfaceFilter.bind(this)} filterValue={this.state.filterSurface}/>
          <div className="btn-filter-container clear-filter">
            <button className="btn bn-small btn-filter btn-clear-filter" onClick={ () => this.clearFilters() }>
              <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.375 12.5339C2.375 10.5643 3.17063 8.77374 4.465 7.4726L2.77875 5.77754C1.06875 7.50841 0 9.89582 0 12.5339C0 17.4042 3.62187 21.4151 8.3125 22V19.5887C4.95187 19.0157 2.375 16.0792 2.375 12.5339ZM19 12.5339C19 7.25773 14.7488 2.98426 9.5 2.98426C9.42875 2.98426 9.3575 2.9962 9.28625 2.9962L10.5806 1.69506L8.90625 0L4.75 4.17797L8.90625 8.35594L10.5806 6.67282L9.29813 5.38361C9.36938 5.38361 9.44063 5.37168 9.5 5.37168C13.4306 5.37168 16.625 8.58275 16.625 12.5339C16.625 16.0792 14.0481 19.0157 10.6875 19.5887V22C15.3781 21.4151 19 17.4042 19 12.5339Z" fill="currentColor"/>
              </svg>
            </button>
          </div>

          {/* mobile */}
          <div id="mobileFilter" className="mobile-filter">
            <TypeFilterMobile submitHandle={this.submitTypeFilter.bind(this)} cancelHandle={this.cancelTypeFilter.bind(this)} filterValue={this.state.filterType} />
            <PriceFilterMobile submitHandle={this.submitPriceFilter.bind(this)} cancelHandle={this.cancelPriceFilter.bind(this)} filterValue={this.state.filterPrice} />
            <RoomFilterMobile submitHandle={this.submitRoomFilter.bind(this)} cancelHandle={this.cancelRoomFilter.bind(this)} filterValue={this.state.filterRoom}/>
            <SurfaceFilterMobile submitHandle={this.submitSurfaceFilter.bind(this)} cancelHandle={this.cancelSurfaceFilter.bind(this)} filterValue={this.state.filterSurface}/>
            <div className="btn-filter-mobile-container">
              <button className="btn btn-filter-search" disabled={ this.state.btnLoading } onClick={ () => this.submitHandle() }>
                { this.state.btnLoading ? (<i className="fas fa-spinner fa-spin"></i>) : this.props.t('Search') } </button>
            </div>
            {(this.state.templateConfig && this.state.templateConfig.country_code === 'BE') ? (
              <>
                <div className="btn-filter-mobile-container">
                  <div className="checkbox-filter-input-mobile-rd">
                    <input checked={this.state.filterInvestor} onChange={this.investorToggler} type="checkbox"
                      className="toogle-checkbox" name="filter-investor" id="filter-investor"/>
                    <label
                      htmlFor="filter-investor">{this.props.t('Show only investor properties')}</label>
                  </div>
                </div>
                <div className="btn-filter-mobile-container">
                  <div className="checkbox-filter-input-mobile-rd">
                    <input checked={this.state.filterImmediatelyAvailable} onChange={this.availableToggler} type="checkbox"
                      className="toogle-checkbox" name="filter-available" id="filter-available"/>
                    <label
                      htmlFor="filter-available">{this.props.t('Show only immediately available properties')}</label>
                  </div>
                </div>
              </>
            ) : (<></>)}
          </div>
        </div>
      </Fragment>
    );
  }
}

ProjectFilter.propTypes = {
  history: PropTypes.object,
  getRequestSearchProjects: PropTypes.objectOf(PropTypes.any),
  createRequestSearchProjects: PropTypes.func,
  projectPropertiesSearchCriteria: PropTypes.array,
  createProjectList: PropTypes.func,
  createRequestGetProjectMarkers: PropTypes.func,
  createMarkerCollection: PropTypes.func,
  createPurchasePageLastUrl: PropTypes.func,
  createMapLocation: PropTypes.func,
  toggleProjectListMobile: PropTypes.func,
  toggleFilterMobile: PropTypes.func,
  hideMessage: PropTypes.func,
  createPopupProjectId: PropTypes.func,
  getDefaultCoordinate: PropTypes.object,
  getToggleFilterMobile: PropTypes.bool,
  templateConfig: PropTypes.object,
  getToggleInvestor: PropTypes.bool,
  getToggleAvailable: PropTypes.bool,
  toggleInvestor: PropTypes.func,
  toggleAvailable: PropTypes.func,
  manageableContents: PropTypes.array,
  headerImage: PropTypes.array,
  createTotalProjects: PropTypes.func,
  totalProjects: PropTypes.number,
  t: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createRequestSearchProjects,
      createProjectList,
      createRequestGetProjectMarkers,
      createMarkerCollection,
      createPurchasePageLastUrl,
      createMapLocation,
      toggleProjectListMobile,
      toggleFilterMobile,
      hideMessage,
      createPopupProjectId,
      toggleInvestor,
      toggleAvailable,
      createTotalProjects,
    },
)(withTranslation()(ProjectFilter));
