import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';


const Tooltip = ({property, condition, openContact}) => {
  const {t} = useTranslation();
  const [tooltip, setTooltip] = useState(false);
  const tooltipClass = tooltip ? 'act-tooltip__content-wrap--is-show' : '';

  const openTooltip = () => {
    setTooltip(true);
  };

  const closeTooltip = () => {
    setTooltip(false);
  };

  const openModal = () => {
    openContact();
  };

  return <div className="act-tooltip" >
    <div className="act-tooltip__label" >
      {(property.noTitle === undefined) && <p className="act-tooltip__label-title" >({condition.symbol}) {condition.title} <br /> <span dangerouslySetInnerHTML={{__html: condition.description}}></span></p>}
      {(property.noIcon === undefined) && <button className={`act-tooltip__label-btn`} onClick={ openTooltip }>
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 12.5917C2.6862 12.5917 0 9.90551 0 6.59171C0 3.27791 2.6862 0.591705 6 0.591705C9.3138 0.591705 12 3.27791 12 6.59171C12 9.90551 9.3138 12.5917 6 12.5917ZM5.4 5.99171V9.59171H6.6V5.99171H5.4ZM5.4 3.59171V4.79171H6.6V3.59171H5.4Z" fill="#9B9999"/>
        </svg>
      </button>}
    </div>

    {(property.noIcon === undefined) && <div className="act-tooltip__content">
      <div className={`act-tooltip__content-wrap ${ tooltipClass }`} id="tooltipProjectDetail">
        <div className="act-tooltip__content-header">
          <button className="act-tooltip__content-header-btn close-tooltip" onClick={ closeTooltip }><span></span><span></span></button>
        </div>
        <div className={`act-tooltip__content-body`}>
          {(condition.additionalCosts !== undefined && condition.additionalCosts.length > 0) && (
            <ul>
              {condition.additionalCosts.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
          <div dangerouslySetInnerHTML={{__html: condition.description}}></div>
        </div>
        <div className="act-tooltip__content-cta">
          <button className="act-tooltip__content-cta-btn close-tooltip" onClick = { closeTooltip }>{t('I understood')}</button>
          <button className="act-tooltip__content-cta-btn" onClick = { openModal }>
            {t('Contact us')}</button>
        </div>
      </div>
    </div>}
  </div>;
};


Tooltip.propTypes = {
  details: PropTypes.object,
  property: PropTypes.object,
  condition: PropTypes.object,
  openContact: PropTypes.func,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps)(Tooltip);
