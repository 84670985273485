import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProjectInvestItem from './ProjectInvestItem';
import Pager from 'react-pager/dist/pager';
import {withTranslation} from 'react-i18next';

/** class for test */
class ProjectInvestList extends Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      initial: true,
      items: [],
      pageTotal: 1,
      pageCurrent: 1,
      pageVisiblePage: 3,
    };
  }

  /**
   * Handles a page change broadcasted by the pager. Get new data here.
   * @param {number} newPage
   */
  handlePageChanged(newPage) {
    const newPageNumber = newPage + 1;
    this.setState({
      items: [],
      loading: true,
    });
    this.props.handleSearch(newPageNumber);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.items = props.getProjectInvestList.projects;
    state.loading = props.getProjectInvestList.loading;
    state.initial = props.getProjectInvestList.initial;
    state.pageTotal = props.paging.total;
    state.pageCurrent = props.paging.current;
    return state;
  }

  /**
   *
   * @param {*} value
   */
  setActiveProject(value) {
    this.props.handleActiveProject(value);
  }


  /** @return {JSX} */
  render() {
    return (
      <>
        <div
          className={`search-result-container-react`}
          id="_resultContainer"
        >
          <div className="invest-card-container">

            {this.state.items?.map((item, i) => {
              return (
                <ProjectInvestItem
                  key={i}
                  history={this.props.history}
                  project={item}
                  handleActiveProject={this.setActiveProject.bind(this)}
                />
              );
            })}

            {this.state.items.length == 0 && this.state.loading === false && this.state.initial === false && (
              <div className="purchase-filter-container-warning">{this.props.t('We did not find any property matching your search criteria')}</div>
            )}

            {this.state.items.length !== 0 && (
              <Pager
                total={this.state.pageTotal}
                current={this.state.pageCurrent - 1}
                visiblePages={this.state.pageVisiblePage}
                titles={{first: '<', last: '>'}}
                onPageChanged={this.handlePageChanged.bind(this)}
              />
            )}

          </div>
        </div>
      </>
    );
  }
}

ProjectInvestList.propTypes = {
  getProjectInvestList: PropTypes.object,
  history: PropTypes.object,
  handleActiveProject: PropTypes.func,
  isSearching: PropTypes.object,
  paging: PropTypes.object,
  handleSearch: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {})(withTranslation()(ProjectInvestList));
