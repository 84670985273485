import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  createParamsInvestFiltering,
} from '../../../pages/action';
import {connect} from 'react-redux';

/**
 * Class Province Filter.
*/
class ProvinceFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      initiated: false,
      provinces: {},
      provincesActiveFiltersObject: {},
      resetTimer: 1,
    };
  }

  /**
   * @param {*} props
   * @param {*} state
   * @return {object} state
   */
  static getDerivedStateFromProps(props, state) {
    const provinces = props.getProjectInvestFilters.provinces;
    const provincesActiveFiltersObject = {};

    if ((!state.initiated && Object.keys(provinces).length)) {
      Object.keys(provinces).forEach((key) => {
        provincesActiveFiltersObject[key] = true;
      });

      state.provincesActiveFiltersObject = provincesActiveFiltersObject;
      state.initiated = true;
      state.provinces = provinces;
    }

    if (state.resetTimer !== props.resetTimer) {
      Object.keys(provinces).forEach((key) => {
        provincesActiveFiltersObject[key] = true;
      });
      state.resetTimer = props.resetTimer;
      state.provincesActiveFiltersObject = provincesActiveFiltersObject;
      state.provinces = provinces;
    }

    return state;
  }

  /**
   *
   * @param {*} event
   */
  async updateProvinceFilter(event) {
    const key = event.target.value;

    const provincesActiveFiltersObject = this.state.provincesActiveFiltersObject;
    provincesActiveFiltersObject[key] = !provincesActiveFiltersObject[key];

    this.setState({
      provincesActiveFiltersObject: provincesActiveFiltersObject,
    });

    const provinceFilter = [];
    Object.entries(provincesActiveFiltersObject).map(([key, value]) => {
      if (value) {
        provinceFilter.push(key);
      }
    });

    let params = this.props.getParamsInvestFiltering;
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        provinceIds: provinceFilter,
      },
    };

    this.props.createParamsInvestFiltering(params);
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="investir-filter-container">
        <div className="investir-filter-box-container">
          <div className="investir-filter-body-container-react vertical-checkboxes">

            {Object.entries(this.state.provinces).map(([key, value]) => {
              return (
                <div className="checkbox-filter-input" key={key}>
                  <input type="checkbox"
                    id={'province'+key}
                    name="filterType"
                    value={key}
                    className="checkbox-control"
                    checked={this.state.provincesActiveFiltersObject[key]}
                    onChange={(e) => this.updateProvinceFilter(e)}
                  />
                  <label htmlFor={'province'+key}>
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

ProvinceFilter.propTypes = {
  history: PropTypes.object,
  resetTimer: PropTypes.number,
  templateConfig: PropTypes.object,
  getProjectInvestFilters: PropTypes.object,
  getParamsInvestFiltering: PropTypes.object,
  createParamsInvestFiltering: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(
    mapsStateToProps,
    {
      createParamsInvestFiltering,
    },
)(ProvinceFilter);
