export const createContactInformation = (params) =>{
  const data = {
    type: 'CONTACT_INFORMATION',
    payload: params,
  };
  return data;
};

export const createContactFormInformation = (params) =>{
  const data = {
    type: 'CONTACT_FORM_INFORMATION',
    payload: params,
  };
  return data;
};

export const createContactData = (params) =>{
  const arrPurpose = {
    'ask_a_question': 'AskAQuestion',
    'build_my_house': 'BuildMyHouse',
    'buy_apartment': 'BuyAppartment',
    'renovate': 'Renovate',
    'buy_house': 'BuyHouse',
    'develop_a_project': 'DevelopProject',
    'visit_showroom': 'VisitShowroom',
    'visit_demonstration_house': 'VisitDemonstrationHouse',
    'visit_demonstration_apartment': 'ProjectRequestAppointment',
    'contact_about_property': 'ContactAboutProperty',
    'contact_pre_project': 'PreProject',
    'pre_project_documentation': 'PreProjectDocumentation',
    'PropertyDocumentation': 'PropertyDocumentation',
  };
  const data = {
    type: 'CONTACT_DATA',
    payload: {
      'propertyData': {
        'id': params.cptCode, // the corret one following jquery is CPT code
        'name': params.projectName,
        'url': window.location.href,
        'pdfPlan': (typeof (params.pdf) === 'undefined') ?
            null : params.pdf,
        'sociCode': params.sociCode,
      },
      'purpose': arrPurpose[params.purpose],
      'origin': '',
      'contactData': {
        'firstName': params.firstname,
        'lastName': params.lastname,
        'eMail': params.email,
        'phoneNumber': params.phone,
        'address': {
          'postCode': params.postalcode,
          'postCodeId': params.postalcodeId,
          'city': params.city,
          'cityId': params.cityId,
          'street': params.street,
          'number': params.streetNumber,
          'country': params.country,
          'countryId': params.countryId,
          'mailbox': params.mailbox,
        },
        'allowContactByEMail': false,
        'allowContactByPhone': false,
      },
      'message': (typeof (params.message) === 'undefined') ?
          null : params.message,
      'subscriptionData': {
        'subscribeForEmail': true,
        'subscribeForNewsLetter': true,
        'subscribeForSMS': true,
        'subscribeForSocialNetwork': true,
        'subscribeForPostalMail': true,
      },
      'forInvestment': (typeof (params.goal) === 'undefined') ?
      false : params.goal,
      'dataProtectionAccepted': true,
      'appointmentData': null,
    },
  };
  return data;
};


export const createContactDataLayer = (params) => {
  return {
    type: 'CONTACT_DATA_LAYER',
    payload: {

      'event': 'contactProjet',

      'contactType': params.project.projectName, // ex: 'Contact projet {{nom du projet}}'

      'contactedDepartment': params.project.companyName, // Name of the department who will receive the contact message. ex : 'TPR' 'TPH-l' default value is entity name

      'firstname': params.firstname, // ex 'Louise'

      'name': params.lastname, // ex: 'Dupont'

      'emailAddress': params.email, // ex: 'louise.dupont@gmail.com' check validity before sending event

      'phoneNumber': params.phone, // ex: '+32498123456', check validity and add international prefix before sending event if necessary

      'address': { // user address

        'zipCode': params.postalcodeId, // ex: '5000'

        'city': params.city, // ex : 'Namur'

        'street': params.street, // ex : 'Rue de la gare'

        'streetNumber': params.streetNumber, // ex : '123b', '14/1'

        'mailbox': params.mailbox,

      },
    },
  };
};
