/**
 *
 * @param {*} map
 * @param {*} markers
 * @param {any} gMapClass
 *
 * @return {object} marker
 */
export function calculateMarkerInBound(map, markers, gMapClass) {
  const inBounds =[];
  markers.map((v)=>{
    const marker = new gMapClass.Marker({
      position: new gMapClass.LatLng(
          Number(v.projectGeopoint.lat),
          Number(v.projectGeopoint.lon),
      ),
    });
    if (map.getBounds() !== undefined && map.getBounds().contains(marker.getPosition())) {
      inBounds.push({
        projectId: v.projectId,
        marker: marker,
      });
    }
  });
  return inBounds;
}
