export const OPEN_PROJECT_FILTER = '[FILTER] OPEN PROJECT FILTER';
export const SET_PROJECT_FILTER = '[FILTER] SET PROJECT FILTER';
export const RESET_PROJECT_FILTER = '[FILTER] RESET PROJECT FILTER';

export const toggleFilter = (params) => {
  return {
    type: 'TOGGLE_FILTER',
    payload: params,
  };
};

export const toggleRightFilter = (params) => {
  return {
    type: 'TOGGLE_RIGHT_FILTER',
    payload: params,
  };
};

export const toggleLeftFilter = (params) => {
  return {
    type: 'TOGGLE_LEFT_FILTER',
    payload: params,
  };
};


export const openFilterProjectDetail = (params) => {
  return {
    type: OPEN_PROJECT_FILTER,
    payload: params,
  };
};

export const setFilterProjectDetail = (params) => {
  return {
    type: SET_PROJECT_FILTER,
    payload: params,
  };
};

export const resetFilterProjectDetail = () => {
  return {
    type: RESET_PROJECT_FILTER,
  };
};
