import axios from 'axios';
import i18n from '../i18n';

export const getTemplateConfig = async () => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL+
  '/modules/custom/tp_plugin_react/data.json';
  const result = await axios.get(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': '*/*',
    },
  });
  return Promise.resolve(result);
};

export const getMsDynamicsConfig = async () => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL+
    '/tp-plugin-react/api/config/ms-dynamics';
  const result = await axios.get(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': '*/*',
    },
  });
  return Promise.resolve(result);
};

export const submitContactForm = async (params) => {
  const url = process.env.REACT_APP_BASE_API_CONTACT_URL +
      '/tp-plugin-react/api/contact/submit';
  params.language = i18n.language;
  const data = JSON.stringify({
    'contactFormData': params.data,
    'country': params.country,
    'language': params.language,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getManageableContents = async () => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL + '/tp-plugin-react/api/manageable-contents?language=' + i18n.language;

  const result = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'accept': '*/*',
    },
  });
  return Promise.resolve(result);
};
