import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {isMobileOnly} from 'react-device-detect';

const CampaignBanner = ({banner, closeCampaignBanner}) => {
  const [isShow, setIsShow] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [showDesktop, setShowDesktop] = useState(false);

  useEffect(() => {
    if (banner.desktop !== undefined || banner.mobile !== undefined) {
      (banner.mobile !== '') ? setShowMobile(true) : setShowMobile(false);
      (banner.desktop !== '') ? setShowDesktop(true) : setShowDesktop(false);
      setIsShow(true);
    } else {
      setShowMobile(false);
      setShowDesktop(false);
      setIsShow(false);
    }
  }, [banner]);

  const closeCampaign = (e) => {
    e.preventDefault();
    closeCampaignBanner({});
    setIsShow(false);
  };

  return (isMobileOnly && isShow && showMobile) ? (
    <React.Fragment>
      <div className={ isShow ? 'banner-campaign block' : ''}>
        {banner.link !== '' ? (
          <a href={banner.link} className='banner-wrapper'>
            <button id='button-close' onClick={closeCampaign}></button>
            <img className='banner-image mobile' src={banner.mobile === '' ? '#' : banner.mobile} ></img>
          </a>
        ) : (
          <div className='banner-wrapper'>
            <button id='button-close' onClick={closeCampaign}></button>
            <img className='banner-image mobile' src={banner.mobile === '' ? '#' : banner.mobile} ></img>
          </div>
        )}
      </div>
    </React.Fragment>
  ) : (isShow && showDesktop) && (
    <React.Fragment>
      <div className='banner-campaign block'>
        {banner.link !== '' ? (
          <a href={banner.link} className='banner-wrapper'>
            <button id='button-close' onClick={closeCampaign}></button>
            <img className='banner-image desktop' src={banner.desktop === '' ? '#' : banner.desktop}></img>
          </a>
        ) : (
          <div className='banner-wrapper'>
            <button id='button-close' onClick={closeCampaign}></button>
            <img className='banner-image desktop' src={banner.desktop === '' ? '#' : banner.desktop}></img>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

CampaignBanner.propTypes = {
  banner: PropTypes.object,
  closeCampaignBanner: PropTypes.func,
};

export default CampaignBanner;
