import React from 'react';
import PropTypes from 'prop-types';
import FormControl from './FormControl';

/**
 * FormInput component renders an input field within a FormControl component.
 * It manages its own state for the input value.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.label] - The label for the input field.
 * @param {string} [props.errorMsg] - The error message to display.
 * @param {boolean|string} [props.required=false] - Whether the input field is required.
 * @param {*} [props.defaultValue] - The default value of the input field.
 * @param {Object} [props.attributes={}] - Additional attributes to pass to the input element.
 *
 * @return {JSX.Element} The rendered FormInput component.
 */
const FormInput = ({name, type, label, errorMsg, required, defaultValue, ...attributes}) => {
  return (
    <FormControl name={name} type={type} label={label} errorMsg={errorMsg} required={required}>
      <input
        className='form-input'
        type={type}
        id={name}
        name={name}
        required={required}
        aria-describedby={`${name}-validation`}
        value={defaultValue}
        {...attributes}
      />
    </FormControl>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool || PropTypes.string,
  defaultValue: PropTypes.any,
  attributes: PropTypes.object,
};

FormInput.defaultProps = {
  type: 'text',
  required: false,
  attributes: {},
};

export default FormInput;
