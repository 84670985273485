import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import JobDetailHeading from './JobDetailHeading';

/**
 * Main Component
 * @return {JSX.Element} The rendered component.
 */
const JobDetail = ({job, countries}) => {
  const {t} = useTranslation();

  return (
    <article>
      <JobDetailHeading job={job} countries={countries} />
      <h2>{t('Function description')}</h2>
      <div className='inner-html' dangerouslySetInnerHTML={{__html: job?.description}} />
    </article>
  );
};

JobDetail.propTypes = {
  job: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export default JobDetail;
