import React from 'react';
import PropTypes from 'prop-types';
import ProjectContactInfoBat from './ProjectContactInfoBat';
import ProjectContactInfoHome from './ProjectContactInfoHome';
import {COMPANY_TPHOME} from '../../helpers/constants';

const ProjectContactInfo = ({project, project: {entity}, options, listShowhouse, contactInformation}) => {
  if (Object.keys(project).length === 0 && options.length === 0) {
    return <></>;
  }
  return <>
    {contactInformation.companyName === COMPANY_TPHOME ?
    <ProjectContactInfoHome project={project} options={options} listShowhouse={listShowhouse} contactInformation={contactInformation} /> :
    <ProjectContactInfoBat project={project} contactInformation={contactInformation} /> }
  </>;
};

ProjectContactInfo.propTypes = {
  project: PropTypes.object,
  entity: PropTypes.string,
  options: PropTypes.array,
  listShowhouse: PropTypes.array,
  contactInformation: PropTypes.any,
};

export default ProjectContactInfo;
