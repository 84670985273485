export const toggleMap = (params) => {
  return {
    type: 'TOGGLE_MAP',
    payload: params,
  };
};

export const toggleInvestor = () => {
  return {
    type: 'TOGGLE_FILTER_INVESTOR',
  };
};

export const toggleAvailable = (params) => {
  return {
    type: 'TOGGLE_FILTER_AVAILABLE',
    payload: params,
  };
};


export const toggleProjectListMobile = (params) => {
  return {
    type: 'TOGGLE_PROJECT_LIST_MOBILE',
    payload: params,
  };
};
