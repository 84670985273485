import React from 'react';
import {connect} from 'react-redux';
import {collectImageWithAltDynamic} from '../HeaderBanner/setImage';
import PropTypes from 'prop-types';
import {createContactInformation} from './action';
import ScrollIntoView from 'react-scroll-into-view';
import {useTranslation} from 'react-i18next';
import {ENTITY_TPBAT} from '../../helpers/constants';

const ProjectImageFooter = ({project,
  project: {imageLinks, projectName,
  }, contactInformation, createContactInformation, getContactFormInformation}) => {
  if (Object.keys(project).length === 0) {
    return <></>;
  }
  const {t} = useTranslation();
  const imageLink = collectImageWithAltDynamic(imageLinks, 1600);
  let imgSource = '';
  let imgAlt = '';

  imageLink.map((link)=>{
    if (link.isRef || (link.url.includes('REF') && imgSource === '')) {
      imgSource = link.url;
      imgAlt = link.alt;
    }
  });

  if (imageLink.length > 0 && imgSource == '') {
    imgSource = imageLink[0].url;
    imgAlt = imageLink[0].alt;
  }

  if (imgSource === '' && imageLinks.length > 0) {
    imgSource = imageLinks[0].imageLink;
    imgAlt = imageLinks[0].alt;
  }

  const openModal = () => {
    getContactFormInformation.showModal = true;
    getContactFormInformation.sociCode =
      contactInformation.sociCode;
    getContactFormInformation.contactId =
      contactInformation.id;
    getContactFormInformation.pointContactType =
      contactInformation.pointContactType;
    getContactFormInformation.projectName =
      contactInformation.projectName;
    getContactFormInformation.purpose =
      'visit_demonstration_apartment';
    getContactFormInformation.projectId =
      contactInformation.projectId;
    getContactFormInformation.contactUs = true;
    getContactFormInformation.isAcheter = false;
    createContactInformation(getContactFormInformation);
  };
  const scrollOptions = {
    behavior: 'smooth',
    block: 'center',
  };
  let btnPrenonz = <ScrollIntoView selector="#projectDetailContact" scrollOptions={scrollOptions} className="project-image-footer__cta-black">
    {t('Make an appointment')}
  </ScrollIntoView>;
  const entity = project.entity.toLowerCase();
  if (entity === ENTITY_TPBAT.toLowerCase() || entity === 'other' || entity === 'others') {
    if (project.projectContact === null) {
      btnPrenonz = '';
    }
  }
  return <>
    <div className="project-image-footer">
      <div className="project-image-footer__img-wrap">
        <img
          src={imgSource}
          alt={imgAlt}
          className="project-image-footer__img" />
      </div>
      <div className="project-image-footer__cta">
        <div className="project-image-footer__container">
          <div className="project-image-footer__grid">
            <div className="project-image-footer__grid-item-left">
              <h2 className="project-image-footer__title">{t('Are you interested in this real estate project?')}</h2>
              <h3 className="project-image-footer__subtitle">{t('Contact us to receive a detailed presentation of this real estate project')}</h3>
            </div>
            <div className="project-image-footer__grid-item-right">
              <button className="project-image-footer__cta-border-white" id="footerContactBtn" onClick={openModal}>{t('Contact us')}</button>
              {btnPrenonz}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

ProjectImageFooter.propTypes = {
  project: PropTypes.object,
  imageLinks: PropTypes.array,
  projectName: PropTypes.string,
  contactInformation: PropTypes.object,
  createContactInformation: PropTypes.func,
  getContactFormInformation: PropTypes.object,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps,
    {createContactInformation},
)(ProjectImageFooter);
