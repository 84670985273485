export const getProjectDetail = (params) =>{
  const data = {
    type: 'GET_PROJECT_DETAIL',
    payload: {
      'projectDetails': {
        'projectId': params.projectId,
      },
      'language': (typeof params.language === 'undefined') ? 'fr':
          params.language,
    },
  };
  return data;
};
