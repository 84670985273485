import React from 'react';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader/FormHeader';
import FormBody from './FormBody/FormBody';

/**
 * Main Component
 */
class ContactForm extends React.Component {
  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      setContactForm: false,
      showContactForm: false,
    };
  }

  /**
   *
   * @param {*} value text output val.
   */
  handleOpenContactModal(value) {
    this.setState({
      setContactForm: value,
      showContactForm: value,
    });
    this.props.hideModal();
  }
  // eslint-disable-next-line require-jsdoc
  componentDidMount() {
    document.documentElement.style.overflow = 'hidden';
  }


  /** @return {JSX} */
  render() {
    const fromPropertyModal = this.props.fromPropertyModal?? false;
    return (
      <div className="modal-container-absolute-react
      form-contact-popup" id="modal-contact-form-react">
        <div className="modal-box">
          <div className="modal-header">
            <div className="form-contact-container margin-0 w-100">
              <FormHeader
                project={this.props.project}
                property={this.props.property}
                setModal={this.props.setModal}
                setContactForm={this.props.setContactForm}
                contactPdf={this.props.contactPdf}
                onCloseContactModal={(value) =>
                  this.handleOpenContactModal(value)}
              />
              <FormBody
                project={this.props.project}
                property={this.props.property}
                setModal={this.props.setModal}
                contactPdf={this.props.contactPdf}
                fromPropertyModal = {fromPropertyModal}
                setContactForm={this.props.setContactForm} />
            </div>


          </div>
        </div>
      </div>
    );
  }
}

ContactForm.propTypes = {
  project: PropTypes.object,
  property: PropTypes.object,
  setModal: PropTypes.func,
  showModal: PropTypes.bool,
  setContactForm: PropTypes.bool,
  contactPdf: PropTypes.bool,
  fromPropertyModal: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default ContactForm;
