import React from 'react';
// import * as ExternalApi from '../../../services/ExternalApi';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

/**
 * Class for detail page Promotion.
 */
class Promotion extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      mediaBanner: [],
    };
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {*} state
   */
  static getDerivedStateFromProps(props, state) {
    const result = props.getMediaBannerDataAll;
    if (Object.keys(result).length !== 0) {
      for (const media of result.medias) {
        state.mediaBanner[media.id] = {
          'div': media.div,
          'css': media.css,
        };
      }
      return state;
    }
    return state;
  }
  /**
   * get media promotion
   */
  async componentDidUpdate() {
    if (Object.keys(this.props.getMediaBannerDataAll).length != 0) {
      const result = this.props.getMediaBannerDataAll;
      // media promotion
      for (const media of result.medias) {
        this.state.mediaBanner[media.id] = {
          'div': media.div,
          'css': media.css,
        };
      }
    }
  }

  /**
     *
     * @return {JSX.Element}
     */
  render() {
    let printToPromotion = '';
    let printToPromotionCss = '';
    if (this.props.projectMedia.length > 0) {
      for (const mediaProject of this.props.projectMedia) {
        if (this.state.mediaBanner[mediaProject.mediaId]) {
          printToPromotionCss +=
            this.state.mediaBanner[mediaProject.mediaId].css;
          printToPromotion += this.state.mediaBanner[mediaProject.mediaId].div.
              replace('[[OpenningType]]', mediaProject.openningType).
              replace('[[OpenningContent]]', mediaProject.openningContent);
        }
      }
    }
    return (
      <div className="banner-promotional-detail no-event">
        <style dangerouslySetInnerHTML={{__html:
          `${printToPromotionCss}`}} />
        <div className="campaign-marketing-wrapper"
          dangerouslySetInnerHTML=
            {{__html: printToPromotion}}>
        </div>
      </div>
    );
  }
}

Promotion.propTypes = {
  mediaBanner: PropTypes.array,
  projectMedia: PropTypes.array,
  getDataMarketingMedia: PropTypes.array,
  getMediaBannerDataAll: PropTypes.object,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps)(Promotion);
