import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {createContactInformation} from '../../components/ProjectImageFooter/action';


const PriceAllTooltip = ({contactInformation, details, property, createContactInformation, getContactFormInformation}) => {
  const [tooltip] = useState(false);

  return (property && property.conditions && property.conditions.length > 0) ?
    <React.Fragment>
      <div className="tooltip-wrap-nd">

        <div className="tooltip-wrap-nd__text">
          {property.conditions.map((item, index) => (
            <PriceLabel key={`tooltip-title-${index}`} props={item} />
          ))}
          {tooltip}
        </div>

      </div>
    </React.Fragment>:
    <React.Fragment>
      <div className="tooltip-wrap-nd"></div>
    </React.Fragment>;
};


/**
 *
 * @param {any} props
 * @param {any} key
 * @return {JSX.Element}
 * @constructor
 */
function PriceLabel(props) {
  if (props.props.conditionTypeForProject == 'PriceAllIn' || props.props.conditionTypeForProject == 'Price' ) {
    return (
      <>
        <p>({props.props.symbol}) {props.props.title} </p>
        <div dangerouslySetInnerHTML= {{__html: props.props.description ?? ''}}></div>
      </>
    );
  }
  return '';
}

PriceAllTooltip.propTypes = {
  contactInformation: PropTypes.object,
  details: PropTypes.object,
  property: PropTypes.object,
  createContactInformation: PropTypes.func,
  getContactFormInformation: PropTypes.object,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps,
    {createContactInformation},
)(PriceAllTooltip);
