import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';


const Tooltips = ({details, property, wrapperClass, prefix, openContact}) => {
  return (property && property.conditions && property.conditions.length > 0) ?
    <React.Fragment>
      <div className={wrapperClass}>
        {property.conditions.map((condition, index) => (
          <Tooltip
            key={`${prefix}-${index}`}
            details={details}
            condition={condition}
            property={property}
            openContact={openContact}/>
        ))}
        {/* {tooltip} */}

      </div>
    </React.Fragment>:
    <React.Fragment>
      <div className="act-tooltip__wrap"></div>
    </React.Fragment>;
};
Tooltips.propTypes = {
  details: PropTypes.object,
  property: PropTypes.object,
  wrapperClass: PropTypes.object,
  prefix: PropTypes.object,
  openContact: PropTypes.func,
};


export default Tooltips;
