
import React from 'react';
import PropTypes from 'prop-types';
import * as ExternalApi from '../../services/ExternalApi';
import {getProjectDetail} from './action';
import {collectImageWithAltDynamic} from '../HeaderBanner/setImage';
import {BubleLoading} from '../../helpers/loading';
import {createTitleFromProject, orderImages} from '../../helpers/helper';
import {useTranslation, withTranslation} from 'react-i18next';

/**
 * Class that can make main maps.
 */
class PopupMarker extends React.Component {
  /**
   * @param {number} props The first number.
   */
  constructor(props) {
    super(props);
    this.state = {
      projectDetail: {
        projectName: '',
        countOfGeneralizedPropertyTypes: {},
      },
    };
  }

  /**
   * get project detail
   *
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount(state, props) {
    setTimeout(() => {
      this.props.onLoad();
    }, 200);
    const res = await ExternalApi.getProjectDetails(
        getProjectDetail(this.props).payload,
    );
    this.setState({
      projectDetail: res.data.projectDetails,
    });
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    const projectDetail = this.state.projectDetail;
    const propertyTypes = projectDetail.countOfGeneralizedPropertyTypes;
    let images = collectImageWithAltDynamic(projectDetail.imageLinks, 400, createTitleFromProject(projectDetail));
    images = orderImages(images);
    const mainImage = (images.length > 0) ? images[0] : '';
    // if you change url logic, dont forget to change it too in ProjectITemNd.js
    let keysOfType = Object.keys(propertyTypes);
    keysOfType = keysOfType.length < 1 ? '-' : keysOfType.join('-');
    let url= `${window.origin}/acheter/${keysOfType}/${projectDetail.projectName}/${projectDetail.provinceName}/${projectDetail.projectId}`;
    if (url !== undefined) {
      url = url.replaceAll(' ', '-');
      url = url.replaceAll(/'/g, '');
    } else {
      url = `${window.origin}/acheter/${projectDetail.projectId}`;
    }
    const link = projectDetail.miniSite? '//'+
        projectDetail.miniSite : url;
    return (
      <div className="popup-bubble-anchor-react">
        <div className="popup-bubble-react">
          {projectDetail.projectName?
          <div className="card-result-react card-result-popup card-result-popup-rd">
            <div
              className="card-result-image-container maps-result-slick-list">
              <div className="image-wrapper">
                {mainImage?
                  <img src={`${mainImage.url}`} alt={`${mainImage.alt}`}/> : ''
                }
              </div>
            </div>
            <div className="card-result-text-container">
              <div className="card-result-text-body card-result-popup">
                <h2>{projectDetail.projectName}</h2>
                <div className="location">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>
                    <span>{projectDetail.address}</span>
                  </p>
                </div>
                {(!projectDetail.noPropertyProject) && <div className="summary-number-container">
                  {Object.entries(propertyTypes).map((key, i) => {
                    return (
                      <span className="summary-number-item-span" key={key[0]}>
                        {(() => {
                          if (i > 0) {
                            return (
                              <span>&nbsp;-&nbsp;</span>
                            );
                          }
                        })()}
                        {key[1]}&nbsp;{key[0].toLowerCase()}
                      </span>
                    );
                  },
                  )}
                </div>}
                <PriceBlock data={projectDetail} price={projectDetail.minPropertyPrice}></PriceBlock>
              </div>
              <div className="card-result-text-footer card-result-popup">
                <a
                  className="btn btn-primary btn-medium-40"
                  rel="canonical"
                  style={{textTransform: 'uppercase'}}
                  href={link}
                >
                  {this.props.t('View the details')}
                </a>
                <div className="card-popup-button-close-container">
                  <button className="btn-card-popup-close" onClick={ () => {
                    this.props.removeThePopUp();
                  }}>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.75732 4.16101L8.03744 0.880899C8.20212 0.716214 8.46913 0.716214 8.63381 0.880899C8.7985 1.04558 8.7985 1.31259 8.63381 1.47728L5.3537 4.75739L8.63375 8.03744C8.79844 8.20212 8.79844 8.46913 8.63375 8.63382C8.46907 8.7985 8.20206 8.7985 8.03737 8.63382L4.75732 5.35377L1.47728 8.63381C1.31259 8.7985 1.04559 8.7985 0.880902 8.63381C0.716217 8.46913 0.716217 8.20212 0.880902 8.03743L4.16095 4.75739L0.880838 1.47728C0.716153 1.3126 0.716153 1.04559 0.880838 0.880903C1.04552 0.716218 1.31253 0.716218 1.47722 0.880903L4.75732 4.16101Z" fill="white"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>:
          <span><BubleLoading text={this.props.t('Loading')}/></span>}
        </div>
      </div>
    );
  }
}

/**
 *
 * @param {any} props
 * @return {JSX.Element}
 * @constructor
 */
function PriceBlock(props) {
  const {t} = useTranslation();
  const project = props.data;
  project.projectItemDollar = '€';
  project.projectItemPriceExc = t('Tax-included price');
  project.projectItemFrom = t('Starting at');
  if (project.priceMin > 0 && project.priceMin !== '') {
    return (
      <div className="price">
        <p className="price-text">
          {project.projectItemFrom+' '}
          {project.priceMin.toLocaleString('nl-be')} {project.projectItemDollar}*<i></i></p>
        <p className="desc-text">*
          {project.projectItemPriceExc}</p>
      </div>
    );
  }
  return <div></div>;
}

PopupMarker.propTypes = {
  projectId: PropTypes.string,
  language: PropTypes.string,
  projectDetail: PropTypes.object,
  onLoad: PropTypes.func,
  removeThePopUp: PropTypes.func,
  t: PropTypes.func,
};

PriceBlock.propTypes = {
  data: PropTypes.object,
  price: PropTypes.number,
  t: PropTypes.func,
};

export default withTranslation()(PopupMarker);
