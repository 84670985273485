import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

import fr from './locales/fr.json';
import axios from 'axios';

const localTranslations = {
  fr: {
    translation: fr,
  },
};

const fetchTranslationsFromBackend = async () => {
  try {
    const response = await axios.get(`/tp-plugin-react/api/translate-all/${document.documentElement.lang}`);
    const extraTranslations = response.data;

    i18n.addResourceBundle(document.documentElement.lang, 'translation', extraTranslations, true, true);
  } catch (error) {
    console.error('Error fetching data.json:', error);
  }
};

i18n
    .use(initReactI18next)
    .use(HttpBackend)
    .init({
      debug: false,
      resources: localTranslations,
      lng: document.documentElement.lang,
      fallbackLng: 'fr',
      keySeparator: false,
      load: 'languageOnly',
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });

fetchTranslationsFromBackend();

export default i18n;
