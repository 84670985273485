import React from 'react';
import PropTypes from 'prop-types';

const SiteplanBreadcrumb = ({breadcrumb, triggerNavigation, position}) => {
  /**
 * @param {anything} item
 * @return {void}
 */
  const handleNavigation = (item) => {
    triggerNavigation(item);
  };

  if (typeof breadcrumb === 'undefined' && !Array.isArray(breadcrumb)) {
    return null;
  }
  return (
    <div className={'siteplanBreadcrumb '+position}>
      <div className='siteplanBreadcrumb__container'>
        {breadcrumb.map((item, index) => (
          <React.Fragment key={index}>
            <div className='phase'><span onClick={()=>handleNavigation(item)} data-id={item.phase}>{item.title}</span></div>
            {index !== breadcrumb.length - 1 && <div className='separator'><i className="fas fa-chevron-right"></i></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
SiteplanBreadcrumb.propTypes = {
  breadcrumb: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        phase: PropTypes.string,
        connectedShape: PropTypes.string,
        level: PropTypes.string,
        parentLevel: PropTypes.string,
      }),
  ),
  triggerNavigation: PropTypes.func,
  position: PropTypes.string,
};

export default SiteplanBreadcrumb;
