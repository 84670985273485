import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {hideMessage} from './action';

/**
 * Class for detail page breadcrumb.
 */
class InfoMessage extends React.Component {
  /**
  * @return {void}
  */
  componentDidUpdate() {
    // TODO: if we need to timeout alert
    // if (this.props.message.state) {
    //   setTimeout(() => {
    //     this.props.hideMessage();
    //   }, this.props.message.options.autoHideDuration);
    // }
  }

  /**
     *
     * @return {JSX.Element}
     */
  render() {
    return (
      <span className={`${this.props.message.options.variant === 'ERROR' ? 'error-warning': 'info'} ${this.props.message.state ? 'show': 'hide'}`}>
        {this.props.message.options.message}
      </span>
    );
  }
}

InfoMessage.propTypes = {
  message: PropTypes.object,
  hideMessage: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};


export default connect(stateToProps,
    {hideMessage},
)(InfoMessage);
