import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {collectImageWithAltDynamic} from '../HeaderBanner/setImage';
import {isMobile} from 'react-device-detect';
import {iconURL, createTitleFromProject, orderImages} from '../../helpers/helper';


const NextArrow = ({onClick}) => {
  return (<button onClick={onClick} className="slick-next">
    <img src={iconURL('arrow-cirlce-right.svg')} alt="next" />
  </button>);
};

const PrevArrow = ({onClick}) => {
  return (<button onClick={onClick} className="slick-prev">
    <img src={iconURL('arrow-cirlce-left.svg')} alt="prev" />
  </button>);
};

const ProjectTopInfoSlider = ({project}) => {
  const slider = useRef();
  const [settingSlider] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 500,
  });

  const settingSliderMobile = {
    dots: false,
    arrows: false,
    variableWidth: true,
    rtl: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
  };
  const images = collectImageWithAltDynamic(project.imageLinks, 800, createTitleFromProject(project));
  const imageSLider = orderImages(images);

  if (isMobile) {
    return <div className="project-detail-info-slider tpr-project-detail-slick">
      <Slider {...settingSliderMobile} >
        {imageSLider.map((image, i) =>
          <div key={i}>
            <div className="project-detail-info-slider__img-wrap">
              <img src={image.url} alt={image.alt} className="project-detail-info-slider__img" />
            </div>
          </div>,
        )}
      </Slider>
    </div>;
  }

  return <div className="project-detail-info-slider">
    <Slider {...settingSlider} ref={slider} >
      {imageSLider.map((image, i) =>
        <div key={i}>
          <div className="project-detail-info-slider__img-wrap">
            <img src={image.url} alt={image.alt} className="project-detail-info-slider__img" />
          </div>
        </div>,
      )}
    </Slider>
  </div>;
};

ProjectTopInfoSlider.propTypes = {
  project: PropTypes.object,
  imageLinks: PropTypes.array,
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

export default ProjectTopInfoSlider;
