import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PagePurchase from './pages/PagePurchase';
import PageDetail from './pages/PageDetail';
import {setMsDynamicsConfig, setTemplateCode} from './pages/action';
import * as DrupalApi from './services/DrupalApi';
import {Router, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import history from './helpers/history';
import {BubleLoading} from './helpers/loading';
import PageMinisite from './pages/PageMinisite';
import PageInvestir from './pages/PageInvestir';
import {useTranslation} from 'react-i18next';
import PageJobCategory from './pages/PageJobCategory';
import PageJobDetail from './pages/PageJobDetail';

//
// const defaultPathConfig = {
//   'en': {
//     'acheter-list': '/acheter-un-bien/',
//     'acheter-detail': '/acheter/:projectid',
//     'investir-combo': '/investir/acheter-pour-investir',
//     'minisite-list': '/templates/thomaspiron/liste_lot:queryString',
//     'minisite-implantation': '/templates/thomaspiron/implantation:queryString',
//   },
// };

/**
 * @param {Object} props
 * @return {void}
 */
function App(props) {
  const {t} = useTranslation();
  const [isFinishInit, setFinishInit] = useState(false);
  const [isFinishDynamicsInit, setFinishDynamicsInit] = useState(false);
  const [textLoading, setTextLoading] = useState(t('Loading components')); // 'Chargement des composants');
  const [country, setCountry] = useState('BE');


  setTimeout(() => {
    setTextLoading('Oups, une erreur est survenue. Merci de réessayer plus tard');
  }, 60000);
  useEffect(() => {
    if (process.env.NODE_ENV == 'development') {
      setFinishDynamicsInit(true);
      setFinishInit(true);
      return;
    }

    // check with valid date
    getMsDynamicsConfig();
    if (!props.templateConfig.valid_until || props.templateConfig.valid_until === undefined || props.templateConfig.valid_until === '') {
      getTemplateConfig();
    } else {
      const validDate = Date.parse(props.templateConfig.valid_until);
      const now = Date.parse(Date('now'));
      if (validDate < now || (!props.templateConfig.template || props.templateConfig.template === undefined || props.templateConfig.template === '')) {
        getTemplateConfig();
      } else {
        setCountry(props.templateConfig.country_code);
        setFinishInit(true);
      }
    }
  }, [props.templateConfig]);

  const getMsDynamicsConfig = async () => {
    try {
      const msDynamicsConfig = await DrupalApi.getMsDynamicsConfig();
      if (msDynamicsConfig.status === 200) {
        props.setMsDynamicsConfig(msDynamicsConfig.data);
      }
      setFinishDynamicsInit(true);
    } catch (err) {
      setFinishDynamicsInit(true);
    }
  };

  const getTemplateConfig = async () => {
    try {
      const templateCode = await DrupalApi.getTemplateConfig();
      const intervalValid = new Date();//  pass the value in ui since time server and client might be different
      intervalValid.setMinutes(intervalValid.getMinutes()+1);
      templateCode.data.data.valid_until = intervalValid;
      await props.setTemplateCode(templateCode.data.data);
      setCountry(props.templateConfig.country_code);
      setFinishInit(true);
    } catch (err) {
      console.log('Error Fetching API,', err);
    }
  };

  return isFinishInit && isFinishDynamicsInit ? (
    <Router history={history} location={location}>
      <div className={'react-acheter-plugin react-' + country.toLowerCase()} id="react-acheter-plugin">
        <Switch>
          <Route path="/" exact component={PagePurchase} />
          {props.templateConfig.routes ? Object.keys(props.templateConfig.routes).map((key) =>{
            return <>
              <Route path={props.templateConfig.routes[key]['buy_list']} component={PagePurchase} />
              <Route path={props.templateConfig.routes[key]['buy_detail']} component={PageDetail} />
              <Route path={props.templateConfig.routes[key]['invest']} component={PageInvestir} />
              <Route path={props.templateConfig.routes[key]['mini_site_list']} component={PageMinisite} />
              <Route path={props.templateConfig.routes[key]['mini_site_implantation']} component={PageMinisite} />
            </>;
          }): (
            <>
              {/* fallback for when this would be deployed before drupal changes are deployed or no routes were passed in data.json */}
              <Route path="/emploi/:category/:job" component={PageJobDetail} />
              <Route path="/*/emploi/:category/:job" component={PageJobDetail} />
              <Route path="/emploi/Spontanément" component={PageJobDetail} />
              <Route path="/*/emploi/Spontanément" component={PageJobDetail} />
              <Route exact path="/emploi/:category" component={PageJobCategory} />
              <Route exact path="/*/emploi/:category" component={PageJobCategory} />
              <Route path="/acheter-un-bien" component={PagePurchase} />
              <Route path="/*/acheter-un-bien" component={PagePurchase} />
              <Route
                path="/templates/thomaspiron/liste_lot:queryString"
                component={PageMinisite}
              />
              <Route
                path="/*/templates/thomaspiron/liste_lot:queryString"
                component={PageMinisite}
              />
              <Route
                path="/templates/thomaspiron/implantation:queryString"
                component={PageMinisite}
              />
              <Route
                path="/*/templates/thomaspiron/implantation:queryString"
                component={PageMinisite}
              />
              <Route path="/acheter/:projectid" component={PageDetail} />
              <Route path="/*/acheter/:projectid" component={PageDetail} />
              <Route path="/investir/acheter-pour-investir" component={PageInvestir} />
              <Route path="/*/investir/acheter-pour-investir" component={PageInvestir} />
            </>
          )}
        </Switch>
      </div>
    </Router>
  ) : (
    <div className="react-acheter-plugin" id="react-acheter-plugin">
      <div className="preloader">
        <BubleLoading key={Math.random()} text={textLoading} />
      </div>
    </div>
  );
}

App.propTypes = {
  templateConfig: PropTypes.object,
  setTemplateCode: PropTypes.func,
  setMsDynamicsConfig: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps, {
  setMsDynamicsConfig,
  setTemplateCode,
},
)(App);
