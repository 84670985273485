import React from 'react';
import PropTypes from 'prop-types';
import HeaderMediaChild from './HeaderMediaChild';

const HeaderMedia = ({project, project: {media: {
  brochures,
  magazines,
  videos,
  virtualVisits,
}}}) => {
  if (Object.keys(project).length === 0) {
    return <div></div>;
  }
  return (
    <div className={`project-detail-header-media ${
      brochures.length === 0 &&
      magazines.length === 0 &&
      videos.length === 0 &&
      virtualVisits.length === 0 ? 'is-hide' : ''
    }`} id="projectDetailHeaderMedia">
      <div className="project-detail-header-media__container">
        <div className="project-detail-header-media__box">
          <HeaderMediaChild project={project}/>
        </div>
      </div>
    </div>
  );
};


HeaderMedia.propTypes = {
  project: PropTypes.object,
  brochures: PropTypes.array,
  magazines: PropTypes.array,
  videos: PropTypes.array,
  virtualVisits: PropTypes.array,
};

export default HeaderMedia;
