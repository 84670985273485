import httpClient from './httpClient';

export const getInitialApplicationValues = async (params = {}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/Job/InitialApplicationValues';
  const result = await httpClient.get(url, params);

  return Promise.resolve(result.data);
};

export const searchJobs = async (language, searchJobsCriteria = {
  vacancyTypeIds: [],
  jobCountryIds: [],
}) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/Job/SearchJobs';
  const result = await httpClient.post(url, {
    searchJobsCriteria,
    language,
  });

  return Promise.resolve(result.data);
};

export const jobDetails = async (language, code) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/Job/JobDetails';
  const result = await httpClient.post(url, {
    code,
    language,
  });

  return Promise.resolve(result.data);
};

export const sendApplication = async (formData) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/Job/SendApplication';
  const result = await httpClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return Promise.resolve(result);
};
