export const createMapLocation = (params) =>{
  const data = {
    type: 'MAP_LOCATION',
    payload: params,
  };
  return data;
};

export const createPopupProjectId = (params) =>{
  const data = {
    type: 'POPUP_PROJECTID',
    payload: params,
  };
  return data;
};

export const createHoveringProjectId = (params) =>{
  const data = {
    type: 'HOVERING_PROJECTID',
    payload: params,
  };
  return data;
};

export const createContactInformation = (params) =>{
  const data = {
    type: 'CONTACT_INFORMATION',
    payload: params,
  };
  return data;
};
