import i18n from '../i18n';

export const SET_TEMPLATE_CONFIG = '[TEMPLATE] SET CONFIG';
export const createRequestInit = (params)=>{
  const data = {
    type: 'REQUEST_INIT',
    payload: {
      'language': params.language,
      'countries': [
        params.country,
      ],
    },
  };
  return data;
};

export const createParamsFiltering = (params)=>{
  const data = {
    type: 'PARAMS_FILTERING',
    payload: {
      'cardinalDirectionOptionsTranslation':
      params.data.initalCriteria.cardinalDirectionOptionsTranslation,
      'cardinalDirectionResourceTranslation':
      params.data.initalCriteria.cardinalDirectionResourceTranslation,
      'exteriorSpaceResourceTranslation':
      params.data.initalCriteria.exteriorSpaceResourceTranslation,
      'floorOptionResourceTranslations':
      params.data.initalCriteria.floorOptionResourceTranslations,
      'maxNumberOfM2':
      params.data.initalCriteria.maxNumberOfM2,
      'minNumberOfM2':
      params.data.initalCriteria.minNumberOfM2,
      'numberOfRoomsMax':
      params.data.initalCriteria.numberOfRoomsMax,
      'numberOfRoomsMin':
      params.data.initalCriteria.numberOfRoomsMin,
      'parkingResourceTranslation':
      params.data.initalCriteria.parkingResourceTranslation,
      'priceMax':
      params.data.initalCriteria.priceMax,
      'priceMin':
      params.data.initalCriteria.priceMin,
      'selectInvestorResourceTranslation':
      params.data.initalCriteria.selectInvestorResourceTranslation,
      'typeOfProperty':
      params.data.initalCriteria.typeOfProperty,
      'typesOfPropertiesAvailable':
      params.data.initalCriteria.typesOfPropertiesAvailable,
    },
  };
  return data;
};

export const createProjectDetail = (params) =>{
  const data = {
    type: 'REQUEST_PROJECT_DETAIL',
    payload: {
      'projectDetails': {
        'projectId': params._project_id,
      },
      'language': (typeof params._language === 'undefined') ? i18n.language:
            params._language,
    },
  };
  return data;
};

export const createLabelTranslate = (params) =>{
  const data = {
    type: 'TRANSLATE',
    payload: params,
  };
  return data;
};

export const createRequestGetProjectMarkers = (params) =>{
  return {
    type: 'REQUEST_GET_PROJECT_MARKERS',
    payload: {
      'searchProjectCriteria': {
        'priceMin': (typeof (params.priceMin) === 'undefined') ? null : params.priceMin,
        'priceMax': (typeof (params.priceMax) === 'undefined') ? null : params.priceMax,
        'numberOfRoomsMin': (typeof (params.numberOfRoomsMin) === 'undefined') ? null : params.numberOfRoomsMin,
        'numberOfRoomsMax': (typeof (params.numberOfRoomsMax) === 'undefined') ? null : params.numberOfRoomsMax,
        'minNumberOfM2': (typeof (params.minNumberOfM2) === 'undefined') ? null : params.minNumberOfM2,
        'maxNumberOfM2': (typeof (params.maxNumberOfM2) === 'undefined') ? null : params.maxNumberOfM2,
        'selectInvestor': (typeof (params.selectInvestor) === 'undefined') ? false : params.selectInvestor,
        'isImmediatelyAvailable': (typeof (params.isImmediatelyAvailable) === 'undefined') ? false : params.isImmediatelyAvailable,
        'parking': false,
        'floorDetails': null,
        'exteriorSpace': false,
        'exteriorSpaceOrientation': null,
        'typesOfGeneralizedProperties': (typeof (params.generalizedTypeOfProperty) === 'undefined') ? [] : params.generalizedTypeOfProperty,
        'projectPropertyCode': '',
        'onlyProjectsWithActionOnGoing': false,
        'countries': [
          params.country,
        ],
      },
      'language': params.language,
    },
  };
};

export const createRequestSearchProjects = (params) => {
  const data = {
    type: 'REQUEST_SEARCH_PROJECTS',
    payload: {
      'paging': {
        'currentPage': (typeof (params.currentPage) === 'undefined') ?
          1 : params.currentPage,
        'pageSize': 7,
      },
      'projectPropertiesSearchCriteria': {
        'priceMin':
          (typeof (params.priceMin) === 'undefined') ?
          null : params.priceMin,
        'priceMax':
          (typeof (params.priceMax) === 'undefined') ?
          null : params.priceMax,
        'numberOfRoomsMin':
          (typeof (params.numberOfRoomsMin) === 'undefined') ?
          null : params.numberOfRoomsMin,
        'numberOfRoomsMax':
          (typeof (params.numberOfRoomsMax) === 'undefined') ?
          null : params.numberOfRoomsMax,
        'minNumberOfM2':
          (typeof (params.minNumberOfM2) === 'undefined') ?
          null : params.minNumberOfM2,
        'maxNumberOfM2':
          (typeof (params.maxNumberOfM2) === 'undefined') ?
          null : params.maxNumberOfM2,
        'typeOfProperty':
          (typeof (params._filtering_type_general) === 'undefined') ?
          [] : params._filtering_type_general,
        'generalizedTypeOfProperty':
          (typeof (params.generalizedTypeOfProperty) === 'undefined') ?
          [] : params.generalizedTypeOfProperty,
        'projectIds':
          (typeof (params.projectIds) === 'undefined') ?
          [] : params.projectIds,
        'onlyProjectsWithActionOnGoing':
          (typeof
          (params._filtering_onlyProjectsWithActionOnGoing) === 'undefined') ?
          false : params._filtering_onlyProjectsWithActionOnGoing,
        'selectInvestor': (typeof (params.selectInvestor) === 'undefined') ?
          false : params.selectInvestor,
        'isImmediatelyAvailable': (typeof (params.isImmediatelyAvailable) === 'undefined') ?
          false : params.isImmediatelyAvailable,
        'countries': [
          params.country,
        ],
      },
      'sorting': {
        'orderByDateASC': (typeof (params.orderByDateASC) === 'undefined') ? false : params.orderByDateASC,
        'orderByDateDESC': (typeof (params.orderByDateDESC) === 'undefined') ? true : params.orderByDateDESC,
        'orderByPriceASC': (typeof (params.orderByPriceASC) === 'undefined') ? false : params.orderByPriceASC,
        'orderByPriceDESC': (typeof (params.orderByPriceDESC) === 'undefined') ? true : params.orderByPriceDESC,
        'orderByDistanceASC': (typeof params.orderByDistanceASC === 'undefined') ? false : params.orderByDistanceASC,
        'orderByDistanceDESC': (typeof params.orderByDistanceDESC === 'undefined') ? true : params.orderByDistanceDESC,
        'centeredCoorinates': {
          'lon': params.centeredCoorinates ? params.centeredCoorinates.lon : '4.4699360',
          'lat': params.centeredCoorinates ? params.centeredCoorinates.lat : '50.5038870',
        },
      },
      'language': i18n.language,
      'countries': [
        params.country,
      ],
    },
  };

  if (data.payload.sorting.orderByPriceASC === true) {
    delete data.payload.sorting.orderByDistanceASC;
    delete data.payload.sorting.orderByDistanceDESC;
  }

  if (data.payload.sorting.orderByDistanceASC === true) {
    delete data.payload.sorting.orderByPriceASC;
    delete data.payload.sorting.orderByPriceDESC;
  }

  if (!data.payload.projectPropertiesSearchCriteria.priceMin) {
    delete data.payload.projectPropertiesSearchCriteria.priceMin;
    delete data.payload.projectPropertiesSearchCriteria.priceMax;
  }

  return data;
};

export const createGetProjectPropertiesDetail = (params) =>{
  if (params._filtering_iframe!==undefined) {
    return {
      type: 'REQUEST_GET_PROJECT_PROPERTIES_DETAIL',
      payload: {
        'projectId': params._filtering_baseProject,
        'paging': {
          'currentPage': params._filtering_page.page,
          'pageSize': 7,
        },
        'projectPropertiesSearchCriteria': {
          'priceMin': params._filtering_price.priceMin,
          'priceMax': params._filtering_price.priceMax,
          'numberOfRoomsMin': params._filtering_room.numberOfRoomsMin,
          'numberOfRoomsMax': params._filtering_room.numberOfRoomsMax,
          'minNumberOfM2': params._filtering_surface.minNumberOfM2,
          'maxNumberOfM2': params._filtering_surface.maxNumberOfM2,
          'typeOfProperty': params._filtering_type_general,
          'generalizedTypeOfProperty': params._filtering_type,
          'isImmediatelyAvailable': params.isImmediatelyAvailable,
          'countries': [
            params._filtering_country_code,
          ],
        },
        'buildingCode': params._filtering_buildingCode,
        'iFrameCode': {
          'iFrameEnseCode': params._filtering_iframe.iFrameEnseCode,
          'iFrameCptEplCode': params._filtering_iframe.iFrameCptEplCode,
        },
        'countries': [
          params._filtering_country_code,
        ],
        'language': params.language,
      },
    };
  } else {
    return {
      type: 'REQUEST_GET_PROJECT_PROPERTIES_DETAIL',
      payload: {
        'projectId': params._filtering_baseProject,
        'paging': {
          'currentPage': params._filtering_page.page,
          'pageSize': 7,
        },
        'projectPropertiesSearchCriteria': {
          'priceMin': params._filtering_price.priceMin,
          'priceMax': params._filtering_price.priceMax,
          'numberOfRoomsMin': params._filtering_room.numberOfRoomsMin,
          'numberOfRoomsMax': params._filtering_room.numberOfRoomsMax,
          'minNumberOfM2': params._filtering_surface.minNumberOfM2,
          'maxNumberOfM2': params._filtering_surface.maxNumberOfM2,
          'typeOfProperty': params._filtering_type_general,
          'generalizedTypeOfProperty': params._filtering_type,
          'propertiesAvailableSoldOption': params._filtering_available,
          'isImmediatelyAvailable': params.isImmediatelyAvailable,
        },
        'countries': [
          params._filtering_country_code,
        ],
        'buildingCode': params._filtering_buildingCode,
        'language': params.language,
      },
    };
  }
};


export const createProjectList = (params) =>{
  const data = {
    type: 'REQUEST_PROJECT_LIST',
    payload: params,
  };
  return data;
};


export const setTemplateCode = (params) =>{
  return {
    type: SET_TEMPLATE_CONFIG,
    payload: params,
  };
};

export const setMsDynamicsConfig = (params) =>{
  return {
    type: 'SET_DYNAMICS_CONFIG',
    payload: params,
  };
};

export const createTemplateConfig = (params) =>{
  return {
    type: 'SET_TEMPLATE_CONFIG',
    payload: params,
  };
};


export const createPurchasePageLastUrl=(url)=>{
  const data = {
    type: 'PURCHASE_PAGE_LAST_URL',
    payload: url,
  };
  return data;
};

export const createTotalProjects = (params) =>{
  const data = {
    type: 'TOTAL_PROJECTS',
    payload: params,
  };
  return data;
};

export const createParamsInvestFiltering = (params)=>{
  const data = {
    type: 'PARAMS_INVEST_FILTERING',
    payload: params,
  };
  return data;
};

export const createRequestSearchInvestProjects = (params) => {
  const data = {
    type: 'REQUEST_SEARCH_INVEST_PROJECTS',
    payload: {
      'paging': {
        'currentPage': (typeof (params.paging.currentPage) === 'undefined') ?
            1 : params.paging.currentPage,
        'pageSize': (typeof (params.paging.pageSize) === 'undefined') ?
            9 : params.paging.pageSize,
      },
      'projectPropertiesSearchCriteria': {
        'priceMin':
            (typeof (params.projectPropertiesSearchCriteria.priceMin) === 'undefined') ?
                null : params.projectPropertiesSearchCriteria.priceMin,
        'priceMax':
            (typeof (params.projectPropertiesSearchCriteria.priceMax) === 'undefined') ?
                null : params.projectPropertiesSearchCriteria.priceMax,
        'generalizedTypeOfProperty':
            (typeof (params.projectPropertiesSearchCriteria.generalizedTypeOfProperty) === 'undefined') ?
                [] : params.projectPropertiesSearchCriteria.generalizedTypeOfProperty,
        'provinceIds':
            (typeof (params.projectPropertiesSearchCriteria.provinceIds) === 'undefined') ?
                [] : params.projectPropertiesSearchCriteria.provinceIds,
        'countries': params.projectPropertiesSearchCriteria.countries,
      },
      'language': i18n.language,
      'countries': params.projectPropertiesSearchCriteria.countries,
    },
  };

  return data;
};

export const createProjectInvestList = (params) =>{
  const data = {
    type: 'REQUEST_PROJECT_INVEST_LIST',
    payload: params,
  };
  return data;
};

export const createProjectInvestFilters = (params) =>{
  const data = {
    type: 'PROJECT_INVEST_AVAILABLE_FILTERS',
    payload: params,
  };
  return data;
};
