import React from 'react';
import PropTypes from 'prop-types';

/**
 * Main Component
 * @return {JSX.Element} The rendered component.
 */
const JobDetailCard = ({job, countries}) => {
  return (
    <div className='job-card--detail'>
      <span className='job-card__country'>{job.jobCountry}</span>
      <span className='job-card__title'>{job.title}</span>
      <span className='job-card__employer'>{job.employer}</span>
      <span className='job-card__area'>
        <i className="fas fa-map-marker-alt" aria-hidden />
        <span>{job.geographicArea ?? job.geographicalArea}</span>
      </span>
      {job.jobContractType ? (
        <div className='job-card__type'>
          <i className="far fa-file-alt" aria-hidden />
          <span>{job.jobContractType}</span>
        </div>
      ) : null }
    </div>
  );
};

JobDetailCard.propTypes = {
  job: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export default JobDetailCard;
