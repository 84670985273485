import React from 'react';
import PropTypes from 'prop-types';

const ProjectDetailBackButtonND = ({url, label}) => {
  return <div className="project-detail-back-button-nd">
    <div className="project-detail-back-button-nd__container">
      <a href={url} className="project-detail-back-button-nd__btn">
        <i className="fas fa-chevron-left"></i>{label}
      </a>
    </div>
  </div>;
};

ProjectDetailBackButtonND.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
};
export default ProjectDetailBackButtonND;
