import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  createContactInformation,
  createContactFormInformation,
  createContactData,
} from './action';
import {
  getCalendarAvailabelSlotbyMonthYear,
  getZipCodes,
  getCities,
  getZipCodeByCity,
  getCalendarAvailabelSlot,
  getCitiesForZipCode,
  getPostalCodeApi,
} from '../../services/ExternalApi';
import {submitContactForm} from '../../services/DrupalApi';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatable from 'react-select/async-creatable';
import SimpleReactValidator from 'simple-react-validator';
import DayPicker from 'react-day-picker';
import TagManager from 'react-gtm-module';
import {createContactDataLayer} from '../ContactForm/action';
import {
  COMPANY_TPBAT, COMPANY_TPHOME,
  ENTITY_TPBAT,
  ENTITY_TPHOME,
  PHONE_INFO_BY_COUNTRY_CODE,
} from '../../helpers/constants';
import {withTranslation} from 'react-i18next';

/**
 * Main Component
 */
class BasicContactForm extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          value: '',
          label: props.t('Choose'),
        },
      ],
      termsCheckbox: false,
      showModal: false,
      contactId: null,
      hourOptions: [],
      minuteOptions: [],
      contactphase: 'openForm',
      disabledHour: true,
      disabledMinute: true,
      errorAppointment: false,
      showDatepicker: false,
      submitResult: {
        image: '',
        message: '',
        messageSecond: '',
      },
      defaultTel: '',
      defaultHour: null,
      defaultMinute: null,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      hour: '',
      minute: '',
      dateAppointment: '',
      dateAppointmentFormated: '',
      message: '',
      cityValue: {
        value: '',
        label: '',
      },
      timeout: '',
      cptCode: '',
      project: {},
      arrTrackedEntity: [ENTITY_TPBAT, ENTITY_TPHOME],
      goal: null,
      inconvenient: false,
      mailbox: '',
      phoneInfo: PHONE_INFO_BY_COUNTRY_CODE.hasOwnProperty(props.templateConfig.country_code) ?
        PHONE_INFO_BY_COUNTRY_CODE[props.templateConfig.country_code]: PHONE_INFO_BY_COUNTRY_CODE['BE'],
    };

    this.setValidation();
  }

  /**
   * initialize validation for contactform
   * @return {void}
   */
  setValidation() {
    const self = this;
    const companyName = this.props.contactInformation.companyName;

    this.validator = new SimpleReactValidator({
      validators: {
        intlTelNumber: {
          message: this.props.t('Please add your national prefix. eg: {{phoneFormat}}', {phoneFormat: this.state.phoneInfo.format}),
          rule: (val, params, validator) => {
            const numbers = val.split(/\d/).length - 1;
            return (
              8 <= numbers && numbers <= 20 &&
                validator.helpers.testRegex(val,
                    /^(\+){0,1}(\d|\s|\(|\)){8,20}$/)
            );
          },
        },
        gdpr: {
          message: this.props.t('Please accept our privacy policy.'),
          rule: (val) => {
            return (val === true);
          },
        },
        datenotnull: {
          message: this.props.t('Please enter the appointment date.'),
          rule: (val) => {
            return ((val !== '' && val !== null) || this.state.inconvenient);
          },
          required: true,
        },
        hourtime: {
          message: this.props.t('Please enter your :attribute.'),
          rule: (val) => {
            return ((val !== null && val !== '' ) || this.state.inconvenient);
          },
          required: true,
        },
        errorAppointment: {
          message:
              this.props.t('This slot is no longer available. Please choose another one.'),
          rule: (val) => {
            return (val === false);
          },
        },
        letterWithAccents: {
          message: this.props.t('Your first name may only contain letters.'),
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-.]+$/) && params.indexOf(val) === -1;
          },
          required: true,
        },
        zipNoResult: {
          message: this.props.t('The postal code entered is invalid.'),
          rule: (val, params, validator) => {
            if (this.props.templateConfig.country_code === 'LU') {
              return true; // Lux doesn't need any postal code, all are valid.
            }

            if (val !== '') {
              if (self.props.getContactFormInformation.cityOptions !== undefined) {
                if (self.props.getContactFormInformation.cityOptions.length === 0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
          },
          required: this.props.templateConfig.country_code !== 'LU', // Only required if not on Lux.
        },
        localite: {
          message: this.props.t('The postal code entered is invalid.'),
          rule: (val, params, validator) => {
            if (this.props.templateConfig.country_code === 'LU') {
              return true; // Lux doesn't need any postal code, all are valid.
            }
            if (val === '' && (companyName !== COMPANY_TPBAT || companyName !== COMPANY_TPHOME)) {
              return false;
            }
          },
        },
        goal: {
          required: true,
          rule: (val, params, validator) => {
            return val !== null && val !== '';
          },
          message: this.props.t('Please enter your choice: to live in or to invest in'),
        },
        inconvenient: {
          required: true,
          rule: (val, params, validator) => {
            return this.state.inconvenient === true || (this.state.dateAppointment !== null && this.state.dateAppointment !== '');
          },
          message: this.props.t('Please select a date and time or indicate to be contacted if nothing fits your schedule.'),
        },
      },
      messages: {
        required: this.props.t('Please enter your :attribute.'),
        lastname: this.props.t('Please enter your Name.'),
        email: this.props.t('Please enter your email address.'),
        localite: this.props.t('The postal code entered is invalid.'),
      },
    });
  }

  /**
   * @return {void}
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);

    this.setState({
      projectId: this.props.getProjectDetail.projectDetails?
      this.props.getProjectDetail.projectDetails.projectId : null,
      projectName: this.props.getProjectDetail.projectDetails?
      this.props.getProjectDetail.projectDetails.projectName: null,
      sociCode: this.props.getProjectDetail.projectDetails?
      this.props.getProjectDetail.projectDetails.sociCode: null,
    });
  }

  /**
   * @return {void}
   * @param {*} prevProps
   * @param {*} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    // when contactId changed contactform will load appointment data
    if (this.props.getContactFormInformation.contactId !==
        this.state.contactId) {
      this.setState({
        projectId: this.props.getContactFormInformation.projectId,
        contactId: this.props.getContactFormInformation.contactId,
        purpose: this.props.getContactFormInformation.purpose,
        projectName: this.props.getContactFormInformation.projectName,
        sociCode: this.props.getContactFormInformation.sociCode,
      });
    }
    if (this.state.showModal !==
        this.props.getContactFormInformation.showModal) {
      this.setState({
        showModal: this.props.getContactFormInformation.showModal,
      });
      if (this.props.getContactFormInformation.showModal) {
        if (this.props.getContactFormInformation.contactId !== undefined) {
          await this.getAppointmentSlots();
        }
      }
    }
  }

  /**
   * @param {Object} props
   * @param {Object} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.projectId = props.getContactFormInformation.projectId;
    state.contactId = props.getContactFormInformation.contactId;
    state.purpose = props.getContactFormInformation.purpose;
    state.projectName = props.getContactFormInformation.projectName;
    state.sociCode = props.getContactFormInformation.sociCode;
    state.cptCode = props.propertyModal.property.cptCode;
    state.project = props.project != undefined ? props.project : props.contactInformation;

    return state;
  }

  /**
   * get available slots for appointment
   * @return {void}
   */
  async getAppointmentSlots() {
    this.setState({
      hour: null,
      minute: null,
      dateAppointment: null,
      hourOptions: [],
      minuteOptions: [],
      disabledHour: true,
      disabledMinute: true,
    });
    const newDate = new Date((new Date()).valueOf() + 1000*3600*24);
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const availableSlot = await getCalendarAvailabelSlot({
      id: this.props.getContactFormInformation?.contactId,
    });
    this.props.getContactFormInformation.showAppointment = true;
    if (!availableSlot.data.success || availableSlot.data.firstAvailableSlot.date === null) {
      this.props.getContactFormInformation.showAppointment = false;
    }
    let res = await getCalendarAvailabelSlotbyMonthYear(
        {
          id: this.props.getContactFormInformation.contactId,
          month: month,
          year: year,
        },
    );
    res = res.data;
    if (res.success) {
      const dateTimeData = [];
      res.availableDays.forEach(function(item, index) {
        if (item !== undefined && item !== null) {
          const rawDate = item.date.split('.');
          const idx = rawDate[0] + '/' + rawDate[1] + '/' + rawDate[2];
          if ( dateTimeData[idx] == undefined ) {
            dateTimeData[idx] = [];
          }
          // fill the time into array date
          item.slots.forEach(function(time, idxtime) {
            if ( time !== undefined ) {
              const explodeTime = time.time.split(':');
              const hour = parseInt(explodeTime[0]);
              if ( dateTimeData[idx][hour] == undefined ) {
                dateTimeData[idx][hour] = [];
              }
              dateTimeData[idx][hour].push(explodeTime[1]);
            }
          });
        }
      });
      if (Object.keys(dateTimeData).length > 0) {
        this.props.getContactFormInformation.slots = dateTimeData;
      } else {
        this.props.getContactFormInformation.slots = dateTimeData;
      }
    } else {
      this.props.getContactFormInformation.slots = [];
    }
    this.props.createContactInformation(
        this.props.getContactFormInformation,
    );
  }

  /**
   * @return {void}
   * @param {*} date
   */
  async changeMonth(date) {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (year > currentYear || (year === currentYear && month > currentMonth)) {
      await this.getAppointmentSlotsbyMonth(month, year);
    }
  }

  /**
   * get available slots for appointment with param,
   * @return {void}
   * @param {int} month
   * @param {int} year
   */
  async getAppointmentSlotsbyMonth(month, year) {
    this.setState({
      hour: null,
      minute: null,
      dateAppointment: null,
      hourOptions: [],
      minuteOptions: [],
      disabledHour: true,
      disabledMinute: true,
    });
    const availableSlot = await getCalendarAvailabelSlot({
      id: this.props.getContactFormInformation?.contactId,
    });
    this.props.getContactFormInformation.showAppointment = true;
    if (!availableSlot.data.success || availableSlot.data.firstAvailableSlot.date === null) {
      this.props.getContactFormInformation.showAppointment = false;
    }
    let res = await getCalendarAvailabelSlotbyMonthYear(
        {
          id: this.props.getContactFormInformation.contactId,
          month: month,
          year: year,
        },
    );
    res = res.data;
    if (res.success) {
      const dateTimeData = this.props.getContactFormInformation.slots;
      res.availableDays.forEach(function(item, index) {
        if (item !== undefined && item !== null) {
          const rawDate = item.date.split('.');
          const idx = rawDate[0] + '/' + rawDate[1] + '/' + rawDate[2];
          if ( dateTimeData[idx] == undefined ) {
            dateTimeData[idx] = [];
          }
          // fill the time into array date
          item.slots.forEach(function(time, idxtime) {
            if ( time !== undefined ) {
              const explodeTime = time.time.split(':');
              const hour = parseInt(explodeTime[0]);
              if ( dateTimeData[idx][hour] == undefined ) {
                dateTimeData[idx][hour] = [];
              }
              dateTimeData[idx][hour].push(explodeTime[1]);
            }
          });
        }
      });
      this.props.getContactFormInformation.slots = dateTimeData;
      this.props.createContactInformation(
          this.props.getContactFormInformation,
      );
    }
  }

  /**
   *
   * @return {void};
   */
  closeModal() {
    this.props.getContactFormInformation.showModal = false;
    this.props.getContactFormInformation.staticSelect = false;
    this.props.getContactFormInformation.postalCode = '';
    this.props.getContactFormInformation.idpostalCode = '';
    this.props.getContactFormInformation.cityOptions = [];
    this.props.getContactFormInformation.showAppointment = false;
    this.props.getContactFormInformation.slots = [];
    this.props.createContactInformation(this.props.getContactFormInformation);
    this.resetForm('openForm');
  }

  /**
   *
   * @param {string} statusForm;
   * @return {void};
   */
  resetForm(statusForm) {
    this.setState({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      contactphase: statusForm,
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      hour: '',
      minute: '',
      dateAppointment: '',
      hourOptions: [],
      minuteOptions: [],
      disabledHour: true,
      disabledMinute: true,
      termsCheckbox: false,
      showDatepicker: false,
      dateAppointmentFormated: '',
      message: '',
      cityValue: {
        value: '',
        label: '',
      },
      defaultHour: null,
      defaultMinute: null,
      cptCode: '',
      mailBox: '',
    });
    this.validator.hideMessages();
    this.forceUpdate();
    document.getElementById('popupProjectDetailContactForm').reset();
  }

  /**
   * @return {void}
   */
  createDatalayer() {
    if (this.state.arrTrackedEntity.indexOf(this.props.contactInformation.entity) !== -1 ) {
      const contactDataLayer = this.props.createContactDataLayer(this.state);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM,
        dataLayer: contactDataLayer.payload,
      });
    }
  }
  /**
   *
   * @return {void};
   */
  async submitForm() {
    if (this.validator.allValid()) {
      const formOdoo = this.props.createContactData(this.state);

      if (this.props.contactInformation.entity && this.props.contactInformation.entity.toLowerCase() === ENTITY_TPBAT.toLowerCase()) {
        formOdoo.payload.purpose = 'ContactAboutProject';
      }

      this.createDatalayer();
      let contactphase = 'loading';
      let errorAppointment = false;
      this.setState({
        contactphase,
      });
      const urlNew = formOdoo.payload.propertyData.url.split( '?' );
      formOdoo.payload.propertyData.url = urlNew[0];
      let res = await submitContactForm(
          {
            data: formOdoo.payload,
            country: this.props.templateConfig.country,
          },
      );
      res = res.data;
      let image = 'tp-icons-newsletter-new-success.svg';
      let message = this.props.t('Your request has been sent');
      let messageSecond = this.props.t('We will contact you as soon as possible');

      contactphase = 'showResult';
      if (!res.success) {
        image = 'tp-icons-newsletter-new-fail.svg';
        message = this.props.t('Our website is experiencing a problem.');
        messageSecond = this.propts.t('An email will be sent to you to confirm your appointment');
        res.processResults.forEach(function(item) {
          if (item.process === 'CreateAppointment' && item.result === 'Error') {
            contactphase = 'openForm';
            errorAppointment = true;
          }
        });
        this.validator.showMessages();
      } else {
        this.resetForm('loading');
      }
      await this.setState({
        submitResult: {
          image,
          message,
          messageSecond,
        },
        contactphase,
        errorAppointment,
      });
      if (errorAppointment) {
        await this.getAppointmentSlots();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {void};
   * @param {*} values;
   */
  async handleInputChange(values) {
    const inputValue = document.getElementsByName('your-locality')[0].value;
    if (inputValue) {
      let res = await getZipCodes(
          {id: inputValue},
      );
      res = res.data;
      if (res.success) {
        this.props.getContactFormInformation.postalCode = res.hits[0].name;
        this.props.getContactFormInformation.idpostalCode = res.hits[0].id;
        this.props.createContactInformation(
            this.props.getContactFormInformation,
        );
        this.setState({
          postalcode: res.hits[0].name,
          postalcodeId: res.hits[0].id,
          city: inputValue,
          cityId: inputValue,
        });
      }
    }
  };

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {array};
   *
   * @param {string} input
   * @param {*} callback
   */
  async loadOptions(input, callback) {
    let defaultOption = this.state.options;
    if (input.length > 2) {
      let res = await getCities(
          {name: input},
      );
      res = res.data;
      defaultOption = res.hits.map(function(item) {
        return {
          value: item.id,
          label: item.name,
        };
      });
    } else {
      if (this.props.getContactFormInformation.postalCode) {
        const res = await getCities(
            {id: this.props.getContactFormInformation.postalCode},
        );
        defaultOption = res.hits.map(function(item) {
          return {
            value: item.id,
            label: item.name,
          };
        });
      }
    }
    return defaultOption;
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {void};
   * @param {*} e
   */
  async changeOptions(e) {
    const input = e.target.value;
    this.setState({
      postalcode: input,
    });
    this.props.getContactFormInformation.staticSelect = false;
    const minLength = 3;
    if (input.length > minLength) {
      this.props.getContactFormInformation.loadingOptions = true;
      this.props.createContactInformation(
          this.props.getContactFormInformation,
      );
      let resCities = await getCitiesForZipCode(
          {id: input},
      );
      //  reset form zip code
      this.setState({
        postalcode: this.props.templateConfig.country_code === 'LU' ? input : null, // keep existing data, because own input is allowed.
        postalcodeId: null,
        city: null,
        cityId: null,
      });
      if (resCities.data.success) {
        resCities = resCities.data.hits;
        const formatZipcode = resCities.map(function(item) {
          return {
            value: item.id,
            label: item.name,
          };
        });
        if (resCities.length > 0) {
          this.props.getContactFormInformation.idpostalCode =
              resCities[0].zipCode.id;
          this.props.getContactFormInformation.staticSelect = true;
          this.props.getContactFormInformation.cityOptions = formatZipcode;
          this.props.getContactFormInformation.loadingOptions = false;
          this.setState({
            postalcode: input,
            postalcodeId: resCities[0].zipCode.id,
            city: formatZipcode[0].label,
            cityId: formatZipcode[0].value,
            cityValue: formatZipcode[0],
          });
        } else {
          this.props.getContactFormInformation.cityOptions = [];
          this.setState({
            postalcode: this.props.templateConfig.country_code === 'LU' ? input : null, // keep existing data, because own input is allowed.
            postalcodeId: null,
            city: null,
            cityId: null,
            cityValue: {
              value: '',
              label: '',
            },
          });
        }
      }
    } else {
      this.props.getContactFormInformation.cityOptions = [];
      this.setState({
        postalcode: this.props.templateConfig.country_code === 'LU' ? input : null, // keep existing data, because own input is allowed.
        postalcodeId: null,
        city: null,
        cityId: null,
        cityValue: {
          value: '',
          label: '',
        },
      });
    }
    this.props.createContactInformation(
        this.props.getContactFormInformation,
    );
  }

  /**
   *
   * @param {*} value
   */
  dateChange(value) {
    this.setState({
      inconvenient: false,
    });
    const selectedYear = '' + value.getFullYear();
    const selectedMonth = '' + (value.getMonth()+1);
    const selectedDay = '' + value.getDate();
    const selectedDate = selectedDay.padStart(2, '0') + '/' +
        selectedMonth.padStart(2, '0') + '/' + selectedYear;
    const hourOptions = [];
    hourOptions.push({
      value: '--',
      label: '--',
    });
    if (
      this.props.getContactFormInformation.slots[selectedDate] !== undefined
    ) {
      this.props.getContactFormInformation.slots[
          selectedDate].forEach(function(item, key) {
        hourOptions.push({
          value: key,
          label: key,
        });
      });
      this.setState({
        hourOptions,
        dateAppointment: value,
        dateAppointmentFormated: selectedDate,
        disabledHour: false,
        disabledMinute: false,
        errorAppointment: false,
        showDatepicker: false,
        hour: null,
        minute: null,
      });
    } else {
      this.validator.showMessages();
    }
  }

  /**
   *
   * @param {*} e
   */
  changeStaticOptions(e) {
    this.setState({
      city: e.label,
      cityId: e.value,
      cityValue: e,
    });
    this.validatePostalCode(e.value);
  }

  /**
   * @return {void};
   * @param {*} e
   */
  changePostalCode(e) {
    const self = this;
    const promise = new Promise( (resolve) => {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: setTimeout(() => {
          resolve(self.getPostalCode(e));
        }, 1000),
      });
    });
    return promise;
  }

  /**
   *
   * @param {*} e
   */
  async getPostalCode(e) {
    const minLength = 3;
    if (e.length < minLength) {
      return;
    }
    if (this.state.postalcode!==null && this.state.postalcode===[]) {
      return;
    }

    const dt = await getPostalCodeApi(e, 'ALL', this.props.i18n.language);
    const formatZipcode = dt.data.hits.map(function(item) {
      return {
        value: item.id,
        label: item.name,
      };
    });

    if (formatZipcode.length > 0) {
      return formatZipcode;
    } else {
      return [];
    }
  }

  /**
  *
  * @param {*} e
  */
  async validatePostalCode(e) {
    if (!isNaN(parseInt(e))) {
      const dt = await getZipCodeByCity(e, this.props.i18n.language);
      if (dt.data.hits.length) {
        if (this.state.postalcode !== dt.data.hits[0].name) {
          this.setState({
            postalcode: dt.data.hits[0].name,
          });
        }
      }
    }
  }

  /**
   *
   * @param {*} e
   */
  setFirstname(e) {
    this.setState({
      firstname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setLastname(e) {
    this.setState({
      lastname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setPhone(e) {
    this.setState({
      defaultTel: e.target.value,
      phone: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setDefaultValuePhone(e) {
    if (e.target.value === '') {
      this.setState({
        defaultTel: this.state.phoneInfo.code,
        phone: this.state.phoneInfo.code,
      });
    }
  }

  /**
   *
   * @param {*} e
   */
  setStreet(e) {
    this.setState({
      street: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setStreetNumber(e) {
    this.setState({
      streetNumber: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setMailBox(e) {
    this.setState({
      mailbox: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTextMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  async setPostalCode(e) {
    this.setState({
      postalcode: e.target.value,
    });
    this.changeOptions(e);
  }

  /**
   *
   * @param {*} e
   */
  setLocality(e) {
    this.setState({
      city: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTermsCheckbox(e) {
    this.setState({
      termsCheckbox: e.target.checked,
    });
  }

  /**
   *
   * @param {*} e
   */
  setInconvenient(e) {
    this.setState({
      inconvenient: e.target.checked,
    });

    if (e.target.checked) {
      this.setState({
        dateAppointmentFormated: '',
        dateAppointment: null,
        hour: null,
        minute: null,
        defaultHour: null,
        defaultMinute: null,
      });
    }
  }

  /**
   *
   * @param {*} value
   */
  setHour(value) {
    const dateSlots = this.props.getContactFormInformation.slots;
    const hour = document.getElementsByName('appointment-hours')[0].value;
    const selectedYear = '' + this.state.dateAppointment.getFullYear();
    const selectedMonth = '' + (this.state.dateAppointment.getMonth()+1);
    const selectedDay = '' + this.state.dateAppointment.getDate();
    const selectedDate = selectedDay.padStart(2, '0') + '/' +
        selectedMonth.padStart(2, '0') + '/' + selectedYear;
    const selectedHour = dateSlots[selectedDate][hour];
    if (selectedHour !== undefined) {
      const minuteOptions = selectedHour.map(function(item) {
        return {
          value: item,
          label: item,
        };
      });
      this.setState({
        disabledMinute: false,
        hour,
        minuteOptions,
      });
    }
  }

  /**
   *
   * @param {*} value
   */
  setMinute(value) {
    const minute = document.getElementsByName('appointment-minutes')[0].value;
    this.setState({
      minute: minute,
    });
  }

  /**
   *
   * @param {*} e
   */
  setGoal(e) {
    this.setState({
      goal: e.target.value === '0' ? false : true,
    });
  }

  /**
   *
   * @param {*} day
   * @return {boolean}
   */
  slotsAppointment(day) {
    if (this.props.getContactFormInformation.slots!== undefined ) {
      const selectedYear = '' + day.getFullYear();
      const selectedMonth = '' + (day.getMonth()+1);
      const selectedDay = '' + day.getDate();
      const selectedDate = selectedDay.padStart(2, '0') + '/' +
          selectedMonth.padStart(2, '0') + '/' + selectedYear;
      return (
        this.props.getContactFormInformation.slots[selectedDate] !== undefined
      );
    }
  }

  /**
   * @return {void}
   */
  openDatepicker() {
    this.setState({
      showDatepicker: !this.state.showDatepicker,
      hour: '',
      defaultHour: null,
      defaultMinute: null,
    });
  }

  /**
   * @param {*} defaultHour
   */
  handleChangeHour(defaultHour) {
    this.setState({defaultHour});
  };

  /**
   * @param {*} defaultMinute
   */
  handleChangeMinute(defaultMinute) {
    this.setState({defaultMinute});
  };

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    this.validator.purgeFields();
    const styleModal = {
      display: (this.props.getContactFormInformation.showModal) ?
          'block' : 'none',
    };
    const styleAppointment = {
      display: (this.props.getContactFormInformation.showAppointment) ?
          'block' : 'none',
    };
    const styleDatepicker = {
      display: (this.state.showDatepicker) ?
          'block' : 'none',
    };
    const postalCode = this.props.getContactFormInformation.postalCode;

    let zipcodeSelect = '';
    let subtitleInfo = '';
    let timeClass= '';
    if (this.state.disabledHour) {
      timeClass = '';
    } else {
      timeClass = 'event-form';
    }
    const hourSelect = <Select
      id="selectInputHour"
      className= "select-input-time"
      classNamePrefix="select-input-time"
      name="appointment-hours"
      placeholder="--"
      options={this.state.hourOptions}
      onInputChange={this.setHour.bind(this)}
      onChange={this.handleChangeHour.bind(this)}
      isDisabled={this.state.disabledHour}
      value={this.state.defaultHour}
    />;
    const minuteSelect = <Select
      id="selectInputMinute"
      className= "select-input-time"
      classNamePrefix="select-input-time"
      name="appointment-minutes"
      placeholder="--"
      options={this.state.minuteOptions}
      onInputChange={this.setMinute.bind(this)}
      onChange={this.handleChangeMinute.bind(this)}
      isDisabled={this.state.disabledMinute}
      value={this.state.defaultMinute}
    />;
    if (this.props.getContactFormInformation.staticSelect) {
      if (this.props.templateConfig.country_code === 'LU') {
        zipcodeSelect = <Creatable
          type="text"
          id="selectLocality"
          name="your-locality"
          isClearable
          allowCreate={true}
          formatCreateLabel={(userInput) => `${userInput}`}
          allowCreateWhileLoading={true}
          options={this.props.getContactFormInformation.cityOptions}
          onChange={this.changeStaticOptions.bind(this)}
          value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
          noOptionsMessage={() => this.props.t('Enter your locality')}
          placeholder={this.props.t('Your locality')}
        />;
      } else {
        zipcodeSelect = <Select
          type="text"
          id="selectLocality"
          name="your-locality"
          options={this.props.getContactFormInformation.cityOptions}
          onChange={this.changeStaticOptions.bind(this)}
          value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
          noOptionsMessage={() => this.props.t('Enter your locality')}
          placeholder={this.props.t('Your locality')}
        />;
      }
    } else {
      if (this.props.templateConfig.country_code === 'LU') {
        zipcodeSelect = <AsyncCreatable
          isClearable
          type="text"
          id="selectLocality"
          name="your-locality"
          allowCreate={true}
          formatCreateLabel={(userInput) => `${userInput}`}
          allowCreateWhileLoading={true}
          defaultOptions={this.props.getContactFormInformation.cityOptions}
          onChange={this.changeStaticOptions.bind(this)}
          loadOptions={this.changePostalCode.bind(this)}
          value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
          cacheOptions
          noOptionsMessage={() => this.props.t('Enter your locality')}
          placeholder={this.props.t('Your locality')}
        />;
      } else {
        zipcodeSelect = <AsyncSelect
          type="text"
          id="selectLocality"
          name="your-locality"
          defaultOptions={this.props.getContactFormInformation.cityOptions}
          onChange={this.changeStaticOptions.bind(this)}
          loadOptions={this.changePostalCode.bind(this)}
          value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
          cacheOptions
          noOptionsMessage={() => this.props.t('Enter your locality')}
          placeholder={this.props.t('Your locality')}
        />;
      }
    }
    let phaseFormClass = 'hide';
    let phaseLoadingClass = 'hide';
    let phaseResultClass = 'hide';
    switch (this.state.contactphase) {
      case 'openForm':
        phaseFormClass = '';
        break;
      case 'loading':
        phaseLoadingClass = '';
        break;
      case 'showResult':
        phaseResultClass = '';
        break;
      default:
        phaseFormClass = '';
        break;
    }
    if (this.props.getContactFormInformation.pointContactType) {
      subtitleInfo = this.props.t('Let’s meet on site in our') +
          '<span class="subtitle" id="titleContactType">' +
        this.props.getContactFormInformation.pointContactType +
          '</span>';
      if (this.props.getContactFormInformation.pointContactType == 'Maison expo') {
        if (this.props.getContactFormInformation.showhouseName !== undefined) {
          subtitleInfo = this.props.t('Let’s meet in the exhibition house of') +
              '<span class="subtitle" id="titleContactType">' +
              this.props.getContactFormInformation.showhouseName +
              '</span>';
        } else {
          subtitleInfo = this.props.t('Let’s meet in the exhibition house of') +
              '<span class="subtitle" id="titleContactType">' +
              this.props.getContactFormInformation.pointContactType +
              '</span>';
        }
      }
    }
    if (this.props.getContactFormInformation.isAcheter && this.props.getContactFormInformation.pointContactType) {
      subtitleInfo = this.props.getContactFormInformation.pointContactType +
          ' DU PROJET :' +
          '<span class="subtitle" id="titleContactType">' +
        this.props.getContactFormInformation.projectName +
          '</span>';
    }
    const tomorrow = new Date(
        ( new Date()).valueOf() + 1000*3600*24,
    );
    const nextYear = new Date((
      new Date()).valueOf() + 1000*3600*24*365,
    );
    //  if companyName is Thomas & Piron BATIMENT or Thomas & Piron HOME localite and postal code no need to required
    const checkCompanyNameTpHome = COMPANY_TPHOME.toLowerCase();
    const checkCompanyNameTpBatiment = COMPANY_TPBAT.toLowerCase();
    const companyName = this.props.contactInformation.companyName;
    const validationSpan = <span className="color-green-secondary">*</span>;
    let postalCodeValidation = '|required';
    let telephoneValidation = '|required';
    let localiteValidation = '|required';

    if (this.props.templateConfig.country_code === 'LU') {
      postalCodeValidation = '';
      localiteValidation = '';
    }

    if (companyName !== undefined&&
      (companyName.toLowerCase() === checkCompanyNameTpHome||
      companyName.toLowerCase() === checkCompanyNameTpBatiment) &&
      (this.props.templateConfig.template != 'THOFR')) {
      postalCodeValidation = '';
      localiteValidation = '';
      if (this.props.getContactFormInformation.contactUs) {
        telephoneValidation = '';
      }
    }
    let entity = '';
    if (this.props.contactInformation.entity) {
      entity = this.props.contactInformation.entity.toLowerCase();
    }
    return (
      <div className="modal-container-absolute-react hide form-contact-popup"
        id="modal-contact-form-react"
        style={styleModal}
      >
        <div className="modal-box">
          <div className="modal-body">
            <div className="form-contact-container margin-0 w-100">
              <div className="form-contact-header">
                <p className="title">
                  <span id="titleTopModal" style={{textTransform: 'uppercase'}} dangerouslySetInnerHTML={{
                    __html: this.props.t('You wish to contact us about the project {{project}}', {
                      project: `<span class="color-green-secondary" id="nameOfProperty">${this.props.contactInformation.projectName}</span>`,
                    }),
                  }} />
                </p>
                <p className="form-type-text project-detail hide">
                  {this.props.t('Visit an apartment on display')}
                  <span id="unit_code"></span>
                </p>
                <button className="modal-absolute-react-close-button"
                  data-modal-close="modal-contact-form-react"
                  onClick={this.closeModal.bind(this)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className={'form-contact-body ' + phaseFormClass}>
                <div className="column-2 flex-100">
                  {((!this.props.getContactFormInformation.contactUs && !this.props.getContactFormInformation.isAcheter)||
                  (this.props.getContactFormInformation.contactUs && this.props.getContactFormInformation.isAcheter)) && (
                    <div className="form-title">
                      <span id="modalTextTitle"
                        dangerouslySetInnerHTML={{__html: subtitleInfo}}
                      />
                    </div>
                  )}
                  <form id="popupProjectDetailContactForm"
                    type="post"
                  >
                    <input type="hidden"
                      id="project_type"
                      name="project_type"
                      value=
                        {this.props.getContactFormInformation.pointContactType}
                    />
                    <div className="form-container">
                      <div className="form-group">
                        <input type="hidden" name="office" value="39" />
                        <label htmlFor="firstNameInput_appointment">
                          {this.props.t('First name')}
                          <span className="color-green-secondary">*</span>
                        </label>
                        <input type="text"
                          name="firstname"
                          className="form-control"
                          placeholder={this.props.t('Your first name')}
                          id="firstNameInput_appointment"
                          value={this.state.firstname}
                          onChange={this.setFirstname.bind(this)}
                        />
                        {this.validator.message(
                            this.props.t('First name'), this.state.firstname,
                            'required|letterWithAccents',
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastNameInput_appointment">
                          {this.props.t('Last name')}
                          <span className="color-green-secondary">*</span>
                        </label>
                        <input type="text"
                          name="lastname"
                          className="form-control"
                          placeholder={this.props.t('Your last name')}
                          id="lastNameInput_appointment"
                          value={this.state.lastname}
                          onChange={this.setLastname.bind(this)}
                        />
                        {this.validator.message(
                            this.props.t('Last name'), this.state.lastname,
                            'required|letterWithAccents',
                        )}
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <label htmlFor="emailInput_appointment">
                          {this.props.t('E-mail')}
                          <span className="color-green-secondary">*</span>
                        </label>
                        <input type="email"
                          className="form-control"
                          placeholder={this.props.t('E-mail')}
                          id="emailInput_appointment"
                          name="email"
                          value={this.state.email}
                          onChange={this.setEmail.bind(this)}
                        />
                        {this.validator.message(
                            this.props.t('E-mail'), this.state.email,
                            'required|email',
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="telephoneInput">
                          {this.props.t('Phone')}
                          {(telephoneValidation === '|required') && validationSpan}
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder={this.props.t('Eg.: {{phoneFormat}}', {
                            phoneFormat: this.state.phoneInfo.format,
                          })}
                          id="telephoneInput"
                          name="phone"
                          onChange={this.setPhone.bind(this)}
                          onClick={this.setDefaultValuePhone.bind(this)}
                          value={this.state.phone}
                          defaultValue={this.state.defaultTel}
                        />
                        {this.validator.message(
                            this.props.t('Phone number'), this.state.phone,
                            'intlTelNumber'+telephoneValidation,
                        )}
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <label htmlFor="streetInput">
                          {this.props.t('Street name')}
                        </label>
                        <input type="text"
                          name="street"
                          className="form-control"
                          placeholder={this.props.t('Your street name')}
                          id="streetInput"
                          onChange={this.setStreet.bind(this)}
                        />
                      </div>
                      {entity === ENTITY_TPBAT.toLowerCase() && (
                        <div className="form-container divide">
                          <div className="form-group">
                            <label htmlFor="streetNumberInput">
                              {this.props.t('Street number')}
                            </label>
                            <input type="text"
                              name="street-number"
                              className="form-control"
                              placeholder={this.props.t('Your street number')}
                              id="streetNumberInput"
                              onChange={this.setStreetNumber.bind(this)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="mailBox">
                              {this.props.t('Mailbox')}
                            </label>
                            <input type="text"
                              name="mail-box"
                              className="form-control"
                              placeholder={this.props.t('Your mailbox')}
                              id="mailBox"
                              onChange={this.setMailBox.bind(this)}
                            />
                          </div>
                        </div>
                      )}
                      {entity !== ENTITY_TPBAT.toLowerCase() && (
                        <div className="form-group">
                          <label htmlFor="streetNumberInput">
                            {this.props.t('Street number')}
                          </label>
                          <input type="text"
                            name="street-number"
                            className="form-control"
                            placeholder={this.props.t('Your street number')}
                            id="streetNumberInput"
                            onChange={this.setStreetNumber.bind(this)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <label htmlFor="yourPostalCode">
                          {this.props.t('Postal code')}
                          {(postalCodeValidation === '|required') && validationSpan}
                        </label>
                        <input type="text"
                          className="form-control"
                          placeholder={this.props.t('Your postal code')}
                          id="yourPostalCode"
                          name="your-postal-code"
                          defaultValue={postalCode}
                          value={this.state.postalcode}
                          onChange={this.setPostalCode.bind(this)}
                        />
                        {this.validator.message(
                            this.props.t('Postal code'), this.state.postalcode,
                            'zipNoResult'+postalCodeValidation,
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="selectLocality">
                          {this.props.t('Locality')}
                          {(localiteValidation === '|required') && validationSpan}
                        </label>
                        <div className="select-container
                        select-locality-container"
                        >
                          {zipcodeSelect}
                          {this.validator.message(
                              this.props.t('Locality'), this.state.cityId,
                              'localite'+localiteValidation,
                          )}
                        </div>
                      </div>
                    </div>

                    {entity === ENTITY_TPBAT.toLowerCase() && (
                      <div className='form-container'>
                        <div className="form-group">
                          <label htmlFor="appartmentGoal">
                            {this.props.t('I\'m looking for an apartment')}
                            <span className="color-green-secondary">*</span>
                          </label>
                          <div className="form-group-radio">
                            <input type="radio" checked={this.state.goal === false} name="appartmentGoal" id="radio1" onChange={this.setGoal.bind(this)} value="0" ></input>
                            <label htmlFor="radio1"><span>{this.props.t('to live in')}</span></label>
                            <input type="radio" checked={this.state.goal === true} name="appartmentGoal" id="radio2" onChange={this.setGoal.bind(this)} value="1" ></input>
                            <label htmlFor="radio2"><span>{this.props.t('to invest in')}</span></label>
                          </div>
                          {this.validator.message(this.props.t('to live in') + '/' + this.props.t('to invest in'), this.state.goal, 'goal')}
                        </div>
                      </div>
                    )}

                    {!this.props.getContactFormInformation.isAcheter && (
                      <div id="messageField">
                        <div className="form-container">
                          <div className="form-group form-full">
                            <label htmlFor="messageTextArea">{this.props.t('Message')}</label>
                            <textarea name="message" id="messageTextArea" className="form-control" onChange={this.setTextMessage.bind(this)}></textarea>
                          </div>
                        </div>
                      </div>
                    )}

                    {!this.props.getContactFormInformation.contactUs && this.props.getContactFormInformation.showAppointment && (
                      <>
                        <div
                          className="available-slot-container"
                          style= {styleAppointment}
                        >
                          <div className="form-container calendar-title">
                            <div className="form-group form-full">
                              <h4 className="form-calendar-title">
                                {this.props.t('Choose one of our still available slots:')}
                              </h4>
                              <h4 className="error" id="error-apointment"></h4>
                            </div>
                          </div>
                          <div className="form-container">
                            <div className="form-group calendar">
                              <label htmlFor="contactCalendarInput">
                                {this.props.t('Choose the day')}
                              </label>
                              <div className="input-container">
                                <input type="text"
                                  className={`form-control event-input ${this.state.showDatepicker ? 'is-show' : ''}`}
                                  readOnly
                                  name="appointment-date"
                                  id="contactCalendarInput"
                                  placeholder="Choisissez un jour"
                                  onClick={this.openDatepicker.bind(this)}
                                  value={this.state.dateAppointmentFormated}
                                />
                              </div>
                              <div id="datepicker"
                                className="hasDatepicker"
                                style={styleDatepicker}
                              >
                                <div className="datepicker__wrap event-input">
                                  <DayPicker
                                    onDayClick={this.dateChange.bind(this)}
                                    disabledDays={{
                                      before: tomorrow,
                                      after: nextYear,
                                    }}
                                    modifiers={ {
                                      disabled: {daysOfWeek: [0]},
                                      selected: this.slotsAppointment.bind(this),
                                      highlighted: new Date(2020, 9, 19),
                                    }}
                                    onMonthChange = {this.changeMonth.bind(this)}
                                    renderDay={(day) => {
                                      const date = day.getDate();
                                      return <div className="datepicker__date">
                                        {date}
                                      </div>;
                                    }}
                                  />
                                </div>
                              </div>
                              {this.validator.message(
                                  this.props.t('Appointment date'), this.state.dateAppointment,
                                  'datenotnull',
                              )}
                              {this.validator.message(
                                  this.props.t('Appointment date'), this.state.errorAppointment,
                                  'errorAppointment',
                              )}
                            </div>
                            <div className="form-group calendar">
                              <label htmlFor="selectInputHour">
                                {this.props.t('Choose the time')}
                              </label>
                              <div className="select-calendar-container">
                                <div className={`select-calendar-wrapper ${timeClass}`}>
                                  {hourSelect}
                                  {this.validator.message(
                                      this.props.t('hour'), this.state.hour,
                                      'hourtime',
                                  )}
                                </div>
                                <span>{this.props.t('h')}</span>
                                <div className={`select-calendar-wrapper ${timeClass}`}>
                                  {minuteSelect}
                                  {this.validator.message(
                                      'minute', this.state.minute,
                                      'hourtime',
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="checkbox-group-container">
                            <div className="form-container">
                              <div className="form-group inline
                        form-full form-group-checkbox">
                                <input className="form-control"
                                  type="checkbox"
                                  name="checkbox-inconvenient"
                                  id="checkboxInconvenient"
                                  checked={this.state.inconvenient}
                                  onClick={this.setInconvenient.bind(this)}
                                />
                                <label htmlFor="checkboxInconvenient" style={{textTransform: 'uppercase'}}>
                                  <i>{this.props.t('The time slots offered do not suit me. I would like to be contacted to set up an appointment.')}</i>
                                </label>
                              </div>
                              {this.validator.message(
                                  'inconvenient', this.state.inconvenient,
                                  'inconvenient',
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="checkbox-group-container">
                      <div className="purchase-policy-heading">
                        <span
                          style={{textTransform: 'uppercase'}}
                          dangerouslySetInnerHTML=
                            {{
                              __html: this.props.t('Privacy policy (for more information see our {{policy}}):', {
                                policy: `<a href="https://www.thomas-piron.eu/politique-de-confidentialite" target="_blank">${this.props.t('Privacy policy')}</a>`,
                              }),
                            }}
                        /> :
                        <span className="color-green-secondary">*</span>
                      </div>
                      <div className="form-container">
                        <div className="form-group inline
                        form-full form-group-checkbox">
                          <input className="form-control"
                            type="checkbox"
                            name="checkbox-privacy"
                            id="checkboxPrivacy"
                            onClick={this.setTermsCheckbox.bind(this)}
                          />
                          <label htmlFor="checkboxPrivacy" style={{textTransform: 'uppercase'}}>
                            <i>{this.props.t('I accept your privacy policy')}</i>
                          </label>
                        </div>
                        {this.validator.message(
                            'terms', this.state.termsCheckbox,
                            'gdpr',
                        )}
                        <i className="note">
                          {this.props.t('You can always modify your consent by contacting us at privacy@thomas-piron.eu.')}
                        </i>
                      </div>
                    </div>
                    <div className="checkbox-group-container">
                      <div className="form-group">
                        {this.props.t('* required field')}
                      </div>
                    </div>
                    <div className="action-container full">
                      <div className="captcha-container">
                      </div>
                      <div className="button-container">
                        <button type="button"
                          className="btn btn-xlarge btn-secondary"
                          onClick={this.submitForm.bind(this)}
                        >
                          {this.props.t('Send')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className={'loading-container ' + phaseLoadingClass}>
                <div className="loading">
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
                <div className="loading-text">
                  <span className='loading-text__title'>{this.props.t('Loading')}</span>
                </div>
              </div>
              <div className={'message-container ' + phaseResultClass}>
                <div className="image-message-container">
                  <img id='imageMessage'
                    src={
                      process.env.REACT_APP_HOST +
                      '/static/assets/images/icons/' +
                      this.state.submitResult.image
                    }
                    alt=""
                  />
                </div>
                <div className="text-message-container">
                  <p id="textMessage">{this.state.submitResult.message}</p>
                  <p id="textMessageSecondary">
                    {this.state.submitResult.messageSecond}
                  </p>
                </div>
                <div className="text-message-cta">
                  <button
                    className="btn btn-secondary
                    btn-large modal-absolute-react-close-button"
                    data-modal-close="modal-contact-form-react"
                    onClick={this.closeModal.bind(this)}
                  >
                    {this.props.t('Close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BasicContactForm.propTypes = {
  createContactInformation: PropTypes.func,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  contactInformation: PropTypes.object,
  getProjectDetail: PropTypes.object,
  createContactData: PropTypes.func,
  propertyModal: PropTypes.object,
  createContactDataLayer: PropTypes.func,
  project: PropTypes.object,
  templateConfig: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) =>{
  return state;
};

export default connect(mapStateToProps,
    {
      createContactInformation,
      createContactFormInformation,
      createContactData,
      createContactDataLayer,
    },
)(withTranslation()(BasicContactForm));
