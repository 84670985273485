import React, {useRef} from 'react';
import PropTypes from 'prop-types';

/**
 * FormFileInput component renders a file input field within a FormControl component.
 * It manages its own state for the input value.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.label] - The label for the input field.
 * @param {string} [props.errorMsg] - The error message to display.
 * @param {boolean|string} [props.required=false] - Whether the input field is required.
 * @param {Object} [props.attributes={}] - Additional attributes to pass to the input element.
 *
 * @return {JSX.Element} The rendered FormFileInput component.
 */
const FormFileInput = ({name, label, errorMsg, required, accept, disabled, ...attributes}) => {
  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    fileInputRef.current.value = null;
  };

  return (
    <div className='form-file-input'>
      <label htmlFor={name} className="sr-only">
        {label || 'File'}
      </label>
      <button
        type='button'
        className='tp-btn--stroked form-file-input__upload'
        onClick={handleButtonClick}
        disabled={disabled}
        aria-label={`Upload ${label || 'file'}`}
      >
        <span className='form-file-input__text'>{label || 'File'}</span>
        {accept ? (<span aria-hidden className='form-file-input__accept'>({accept})</span>) : null}
        {required ? <span aria-hidden className='form-file-input__required'>*</span> : null}
      </button>
      <input
        className='form-file-input__input'
        type='file'
        id={name}
        name={name}
        required={required}
        accept={accept}
        aria-describedby={`${name}-validation`}
        ref={fileInputRef}
        disabled={disabled}
        {...attributes}
      />
      <button
        type='button'
        className='form-file-input__clear'
        onClick={handleClearClick}
        aria-label={`Clear ${label || 'file'} input`}
      >
        <i className='fas fa-trash'/>
        <span className='sr-only'>Clear file input</span>
      </button>
    </div>
  );
};

FormFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool || PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool || PropTypes.string,
  attributes: PropTypes.object,
};

FormFileInput.defaultProps = {
  required: false,
  disabled: false,
  attributes: {},
};

export default FormFileInput;
