import React from 'react';
import PropTypes from 'prop-types';

/**
 * FormCheckbox component renders a checkbox field within a FormControl component.
 * It manages its own state for the checkbox value.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name of the checkbox field.
 * @param {string} [props.label] - The label for the checkbox field.
 * @param {string} [props.errorMsg] - The error message to display.
 * @param {boolean|string} [props.required=false] - Whether the checkbox field is required.
 * @param {boolean} [props.defaultChecked] - The default checked state of the checkbox field.
 * @param {Object} [props.attributes={}] - Additional attributes to pass to the checkbox element.
 *
 * @return {JSX.Element} The rendered FormCheckbox component.
 */
const FormRadio = ({name, label, labelLink, errorMsg, required, defaultChecked, ...attributes}) => {
  return (
    <div className='form-radio'>
      <input
        className='form-radio__input'
        type='radio'
        id={name}
        name={name}
        required={required}
        aria-describedby={`${name}-validation`}
        defaultChecked={defaultChecked}
        {...attributes}
      />
      <label className='form-radio__label' htmlFor={name}>
        <span>
          {label}
          {required === true || required === 'true' ? <span aria-hidden className='form-label__required'>*</span> : null }
        </span>
        { labelLink ? (<a href='/politique-de-confidentialite' target='_blank' rel="noreferrer" >{labelLink}</a>) : null }
      </label>
      { errorMsg ? <small id={`${name}-validation`} className='form-error'>{errorMsg}</small> : null }
    </div>
  );
};

FormRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelLink: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool || PropTypes.string,
  defaultChecked: PropTypes.bool,
  attributes: PropTypes.object,
};

FormRadio.defaultProps = {
  required: false,
  defaultChecked: false,
  attributes: {},
};

export default FormRadio;
