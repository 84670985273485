import React, {Component} from 'react';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toggleFilter} from '../action';
import {rangePriceLabel, validatorPriceRange} from '../../../helpers/helper';
import Modal from 'react-modal';
import {withTranslation} from 'react-i18next';


Modal.setAppElement('#acheter-plugin');
/**
 * Class Price Filter.
*/
class PriceFilterMobile extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelButton: props.t('Price'),
      isFilter: false,
      value: {
        min: 0,
        max: 0,
      },
      priceDefaultMin: 0,
      priceDefaultMax: 1,
      showFilter: false,
      modalIsOpen: false,
    };
    this._openModal = this._openModal.bind(this);
    this._afterOpenModal = this._afterOpenModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  /**
   * Open Modal
   */
  _openModal() {
    this.setState({modalIsOpen: true});
    this.showPriceFilter();
  }

  /**
   * After Open Modal
   */
  _afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  /**
   * Close modal
   */
  _closeModal() {
    this.setState({modalIsOpen: false});
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.filterValue !== null && (state.value.min === 0 && state.value.max === 0)) {
      state.isFilter = true;
      state.value = {min: Number(props.filterValue.min), max: Number(props.filterValue.max)};
      state.labelButton = `€ ${props.filterValue.min} - € ${props.filterValue.max} `;
    }
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.filterValue !== prevProps.filterValue) {
      if (!this.props.filterValue) {
        this.setState({
          labelButton: this.props.t('Price'),
          isFilter: false,
          value: {
            Min: 0,
            Max: 0,
          },
        });
      }
    }
  }

  /**
   * Show Price Filter.
   * @param {number} value text output val.
   */
  showPriceFilter() {
    const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
    if (_getFaramFiltering.priceMin !== undefined) {
      this.setState({
        showFilter: !this.state.showFilter,
        isFilterClass: 'is-collapsed',
        priceDefaultMin: _getFaramFiltering.priceMin,
        priceDefaultMax: _getFaramFiltering.priceMax,
      });

      if (this.state.value.min===0 && this.state.value.max===0) {
        this.setState({
          value: {
            min: _getFaramFiltering.priceMin,
            max: _getFaramFiltering.priceMax,
          },
        });
      }
      this.props.toggleFilter({
        ...this.props.getFilter,
        'filterType': false,
        'filterPrice': !this.props.getFilter.filterPrice,
        'filterRoom': false,
        'filterSurface': false,
      });
    }
  }

  /**
   * Change Price Filter.
   * @param {number} value text output val.
   */
  handleChangePrice(value) {
    this.setState({
      value: value,
      labelButton: rangePriceLabel(value.min, value.max),
    });
  }

  /**
   * Submit Price Filter.
   * @param {number} value text output val.
   */
  async submitPriceFilter() {
    if (!validatorPriceRange(this.state.value.min, this.state.value.max)) {
      return;
    }
    this.setState({
      showFilter: !this.state.showFilter,
      isFilter: true,
      value: this.state.value,
      labelButton: `€ ${this.state.value.min} - € ${this.state.value.max} `,
      modalIsOpen: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterPrice': !this.props.getFilter.filterPrice,
    });
    this.props.submitHandle(this.state.value);
  }

  /**
   * Clear Price Filter.
   * @param {number} value text output val.
   */
  async cancelPriceFilter() {
    await this.setState({
      labelButton: this.props.t('Price'),
      isFilter: false,
      modalIsOpen: false,
      value: {
        min: this.props.filterOptions.priceMin,
        max: this.props.filterOptions.priceMax,
      },
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterPrice': !this.props.getFilter.filterPrice,
    });
    this.props.cancelHandle(this.state.value);
  }

  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': false,
      'filterPrice': !this.props.getFilter.filterPrice,
      'filterRoom': false,
      'filterSurface': false,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-mobile-container">
        <div className={`btn-filter-mobile ${this.state.isFilter ? 'is-filter' : '' }`} onClick={()=> this._openModal() }>
          <div className="btn-filter-mobile-text">
            <span className="filter-type-text">{this.props.t('Price')}</span>
            <span className="filter-type-text-active">{this.state.isFilter ? `entre ${ this.state.value.min }€ at ${ this.state.value.max }€ ` : '' }</span>
          </div>
          <div className="btn-filter-mobile-image"><i className="icon-filter-mobile archer"></i></div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this._afterOpenModal}
          onRequestClose={this._closeModal}
          className="modal-acheter"
        >
          <div className="modal-acheter__dialog">
            <div className="modal-acheter__content">
              <div className="modal-acheter__header">
                <h5 className="modal-acheter__title" id="modalAcheterTypeFilterLabel">{this.props.t('Price')}</h5><button className="close" onClick={()=> this._closeModal() } ><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-acheter__body">
                <div className="input-range-mobile">
                  <InputRange
                    maxValue={this.state.priceDefaultMax}
                    minValue={this.state.priceDefaultMin}
                    formatLabel={(value) => (value !== null)?`€ ${new Intl.NumberFormat('de-DE').format(value).replace('.', ' ')}`:``}
                    value={this.state.value}
                    allowSameValues={true}
                    step={1000}
                    onChange={this.handleChangePrice.bind(this)} />
                </div>
              </div>
              <div className="modal-acheter__footer">
                <button className="btn modal-acheter__btn-close" onClick={()=> this.cancelPriceFilter()}>{this.props.t('Close')}</button>
                <button className="btn modal-acheter__btn-apply" onClick={()=> this.submitPriceFilter()} >{this.props.t('Apply')}</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PriceFilterMobile.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
  t: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps, {
  toggleFilter,
},
)(withTranslation()(PriceFilterMobile));
