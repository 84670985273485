import React from 'react';
import {connect} from 'react-redux';
import {collectImageWithAltDynamic} from '../HeaderBanner/setImage';
import PropTypes from 'prop-types';
import ScrollIntoView from 'react-scroll-into-view';
import {createTitleFromProject, orderImages} from '../../helpers/helper';
import {useTranslation} from 'react-i18next';

const HeroProjectDetail = ({
  project,
  project: {
    imageLinks,
    projectName,
    projectCoordinate,
    address,
    percentageSold,
    projectTitleSEO,
    logoUrl,
    isNew,
    noPropertyProject,
  },
  handleListTabViews,
}) => {
  const {t} = useTranslation();
  const handleCtaListTab = () => {
    handleListTabViews();
  };

  let imageLink = collectImageWithAltDynamic(imageLinks, 1600, createTitleFromProject(project));

  if (!imageLink.length) {
    imageLink = collectImageWithAltDynamic(imageLinks, 1200, createTitleFromProject(project));
  }

  if (!imageLink.length) {
    imageLink = collectImageWithAltDynamic(imageLinks, 800, createTitleFromProject(project));
  }

  imageLink = orderImages(imageLink);
  let imgSource = '';
  let imgAlt = '';

  imageLink.map((link) => {
    if (link.isRef || (link.url.includes('_REF_') && imgSource == '')) {
      imgSource = link.url;
      imgAlt = link.alt;
    }
  });

  if (imageLink.length > 0 && imgSource === '') {
    imgSource = imageLink[0].url;
    imgAlt = imageLink[0].alt;
  }

  if (imgSource === '' && imageLinks.length > 0) {
    // last fallback. Just take the first of initial array.
    imgSource = imageLinks[0].imageLink;
    imgAlt = imageLinks[0].alt;
  }

  if (Object.keys(project).length === 0) {
    return <div></div>;
  }
  let bannerTitle = projectName;
  if (projectTitleSEO !== null) {
    bannerTitle = projectTitleSEO;
  }

  return (
    <div className="project-detail-new-banner">
      <div className="project-detail-new-banner__img-wrapper">
        <img
          src={imgSource}
          alt={imgAlt}
          className="project-detail-new-banner__img"
        />
      </div>
      <div className="project-detail-new-banner__content">
        <div className="project-detail-new-banner__container">
          {logoUrl && (<img src={logoUrl} alt="company logo" className="project-detail-new-banner__logo"/>)}

          <div className="project-detail-new-banner__title">{bannerTitle}</div>
          <address className="project-detail-new-banner__adress">
            <a
              href={`https://maps.google.com/?q=${projectCoordinate.lat},${projectCoordinate.lon}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-map-marker-alt"></i>
              <span>{address}</span>
            </a>
          </address>
          <HeroProjectDetailCTA
            clickFuntion={handleCtaListTab}
            project={project}
            t={t}
          ></HeroProjectDetailCTA>
          {((percentageSold >= 25) && (percentageSold <= 75) && (noPropertyProject == false)) && (
            <p className="project-detail-new-banner__marketing-note"
              dangerouslySetInnerHTML={{
                __html: t('Already <strong>{{percentageSold}}%</strong> of the properties of this project have been acquired.', {percentageSold: percentageSold}),
              }} />
          )}

        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {any} props
 * @return {JSX.Element}
 * @constructor
 */
function HeroProjectDetailCTA(props) {
  const scrollOptions = {
    behavior: 'smooth',
    block: 'center',
  };
  const project = props.project;
  const handleCtaListTab = props.clickFuntion;
  if (project.noPropertyProject != true) {
    if (Object.keys(project.countOfGeneralizedPropertyTypes).length > 0) {
      return (
        <div className="project-detail-new-banner__cta-wrap">
          <ScrollIntoView selector="#FilterProjects" scrollOptions={scrollOptions} onClick={handleCtaListTab}>
            <div className="project-detail-new-banner__cta">
              <span className="project-detail-new-banner__cta-icon">
                <i></i>
                <i></i>
                <i></i>
              </span>
              <span className="project-detail-new-banner__cta-text">{props.t('View all properties')}</span>
            </div>
          </ScrollIntoView>
        </div>
      );
    }
  }
  return '';
}

HeroProjectDetail.propTypes = {
  project: PropTypes.object,
  imageLinks: PropTypes.array,
  projectName: PropTypes.string,
  projectCoordinate: PropTypes.string,
  address: PropTypes.string,
  handleListTabViews: PropTypes.func,
};

HeroProjectDetailCTA.propTypes = {
  clickFuntion: PropTypes.func,
  project: PropTypes.object,
  t: PropTypes.func,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps)(HeroProjectDetail);
