import React from 'react';
import MainMap from '../components/MainMap/MainMap';
import {getInitialProjectSearchCriteria} from '../services/ExternalApi';
import {connect} from 'react-redux';
import ProjectList from '../components/ProjectList/ProjectList';
import * as ExternalApi from '../services/ExternalApi';
import * as DrupalApi from '../services/DrupalApi';
import {
  createRequestInit,
  createParamsFiltering,
  createGetProjectPropertiesDetail,
  createPurchasePageLastUrl,
  createRequestSearchProjects,
  createTemplateConfig,
} from '../pages/action';
import {
  createMarkerCollection,
} from '../components/MainMap/action';
import PropTypes from 'prop-types';
import ProjectFilter from '../components/ProjectFilter/ProjectFilter';
import InfoMessage from '../components/InfoMessage/InfoMessage';
import BasicContactForm from '../components/BasicContactForm/BasicContactForm';
import {createContactFormInformation} from '../components/BasicContactForm/action';
import {hideMessage} from '../components/InfoMessage/action';
import {isMobileOnly} from 'react-device-detect';
import CampaignBanner from '../components/CampaignBanner/CampaignBanner';
import {Trans, withTranslation} from 'react-i18next';

/**
 * Page Purchase Element
 */
class PagePurchase extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      contactInformation: {},
      getMediaBannerDataAll: {},
      manageableContents: {},
      campaignBanner: {},
      totalProjects: {},
    };
  }

  /**
   * Load Componnet
   */
  async componentDidMount() {
    const contactState = {
      showModal: false,
    };
    this.props.createContactFormInformation(contactState);
    await this.loadTranslate();
    // await this.loadProjectMarkers();
    await this.props.createPurchasePageLastUrl(document.URL);
    const contactInformation = {};
    contactInformation.showModal = false;
    contactInformation.staticSelect = false;
    contactInformation.postalCode = '';
    contactInformation.idpostalCode = '';
    contactInformation.cityOptions = [];
    contactInformation.showAppointment = false;
    contactInformation.slots = [];
    this.setState({
      contactInformation: contactInformation,
    });
    window.addEventListener('scroll', this.handleScroll, true);
    const [mediaBanner] = await Promise.all([
      ExternalApi.getPromotionBanner(),
    ]);
    const getMediaBannerDataAll = mediaBanner.data;
    this.setState({
      getMediaBannerDataAll: getMediaBannerDataAll,
    });

    // Load manageable contents from API.
    const manageableContents = await DrupalApi.getManageableContents();
    this.setState({
      manageableContents: manageableContents.data,
    });
  }

  /**
   * Load Componnet
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Load Componnet
   */
  handleScroll = () => {
    const blockScroll = document.getElementById('block_scroll');
    const filterContainer = document.getElementById('list-header-result-filter-container');

    if (!blockScroll) {
      return;
    }

    const st = blockScroll.scrollTop;
    if (st > 100 && window.innerWidth < 520) {
      filterContainer.classList.add('fixed-position');
    } else {
      filterContainer.classList.remove('fixed-position');
    }
  }

  /**
   *
   * @param {any} props
   * @param {any} state
   * @return {*}
   */
  static getDerivedStateFromProps(props, state) {
    state.contactInformation = props.getContactFormInformation;
    state.totalProjects = props.getTotalProjects;
    return state;
  }

  /**
   *
   */
  async loadProjectMarkers() {
    // load inital marker reqyest
    const paramsMarker = this.props.getRequestGetProjectMarkers;
    const allMarkers = await ExternalApi.getProjectMarkers(paramsMarker);

    // dispatch the marker collection
    this.props.createMarkerCollection(allMarkers);
  }

  /**
   * Load translate
   * @return {any} data
   */
  async loadTranslate() {
    const data = await getInitialProjectSearchCriteria(
        createRequestInit({...this.props.templateConfig, language: this.props.i18n.language}).payload,
    );

    this.props.createParamsFiltering(data);

    if (this.props.getParamsFiltering.centeredCoorinates === undefined) {
      this.props.getParamsFiltering.centeredCoorinates =
      this.props.getDefaultCoordinate;
    }
    return data;
  }

  /**
   * @param {any} banner
   */
  setHeaderImage(banner) {
    this.setState({
      campaignBanner: banner,
    });
  }

  /** @return {JSX} */
  render() {
    const amount = this.state.totalProjects.count ? this.state.totalProjects.count : 0;
    return (
      <main id="main" className="purchase-property-main">
        <CampaignBanner banner={this.state.campaignBanner} closeCampaignBanner={this.setHeaderImage.bind(this)}/>
        <div className={this.props.getToggleFilterMobile ? '' : 'hide-mobile'}>
          <div className="purchase-filter-title-container-react">
            <h3>{this.props.t('Find the property that suits you')}</h3>
            {/* <div className="total-projects">{this.props.t('We have <strong>{{- amount}} properties</strong> available to offer you', {'interpolation': {'escapeValue': false}, 'amount': this.state.totalProjects.loading ? '<i className="fas fa-spinner fa-spin"></i>' : this.state.totalProjects.count ? this.state.totalProjects.count : 0})}</div>*/}
            <div className="total-projects">
              {
                this.state.totalProjects.loading ? (
                    <>
                      <span dangerouslySetInnerHTML={{__html: this.props.t('We have <strong>[spinner] properties</strong> available to offer you').replace('[spinner]', '<i class="fas fa-spinner fa-spin"></i>')}}></span>
                    </>
                ) : (
                    <>
                      <Trans
                        i18nKey="We have <strong>{{amount}} properties</strong> available to offer you"
                        values={{amount: amount}}
                        components={{strong: <strong />, italic: <italic />}}
                      >We have <strong>{{amount}} properties</strong> available to offer you</Trans>
                    </>
                )
              }
            </div>
          </div>
          <ProjectFilter
            history = {this.props.history}
            manageableContents={this.state.manageableContents}
            headerImage={this.setHeaderImage.bind(this)}
            totalProjects={this.state.totalProjects}
          />

          <div className={this.props.message.state ? 'purchase-filter-container-warning' : ''}>
            <InfoMessage/>
          </div>
        </div>

        {
          isMobileOnly ? (
          <div className={this.props.getToggleFilterMobile ? 'hide-mobile' : ''}>
            <div className="result-map-container-react">
              <ProjectList history = {this.props.history} getMediaBannerDataAll={this.state.getMediaBannerDataAll}/>
              <div className={`map-container ${this.props.getShowMap ? '': 'is-hide'}`}>
                <MainMap/>
              </div>
            </div>
          </div>) : (
            <div className="result-map-container-react">
              <ProjectList history = {this.props.history}
                getMediaBannerDataAll={this.state.getMediaBannerDataAll}/>
              <div
                className={`map-container 
                  ${this.props.getShowMap ? '': 'is-hide'}`}>
                <MainMap/>
              </div>
            </div>
          )
        }
        <BasicContactForm
          contactInformation={this.state.contactInformation}
        />
      </main>
    );
  }
}

PagePurchase.propTypes = {
  paramsFiltering: PropTypes.object,
  getShowMap: PropTypes.bool,
  createParamsFiltering: PropTypes.func,
  getParamsFiltering: PropTypes.object,
  getDefaultCoordinate: PropTypes.object,
  getRequestSearchProjects: PropTypes.object,
  getRequestGetProjectMarkers: PropTypes.object,
  createMarkerCollection: PropTypes.func,
  history: PropTypes.object,
  createPurchasePageLastUrl: PropTypes.func,
  getPurchasePagelastUrl: PropTypes.string,
  createRequestSearchProjects: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  getTotalProjects: PropTypes.func,
  createContactFormInformation: PropTypes.func,
  message: PropTypes.object,
  hideMessage: PropTypes.func,
  getToggleFilterMobile: PropTypes.bool,
  getToggleProjectListMobile: PropTypes.bool,
  getMediaBannerDataAll: PropTypes.object,
  createTemplateConfig: PropTypes.func,
  templateConfig: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps,
    {
      createParamsFiltering,
      createGetProjectPropertiesDetail,
      createMarkerCollection,
      createPurchasePageLastUrl,
      createRequestSearchProjects,
      createContactFormInformation,
      createTemplateConfig,
      hideMessage,
    },
)(withTranslation()(PagePurchase));
