import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-contexify/dist/ReactContexify.css';
import {openPropertyModal} from '../ProjectDetail/Property/action';
import {withTranslation} from 'react-i18next';

/**
 * class
 */
class PropertyInvestItem extends React.Component {
  /**
   * @param {*} props props object.
   */
  constructor(props) {
    super(props);
  }

  /**
   * Relays open modal action to parent.
   */
  handleModal() {
    this.props.openPropertyModal({project: this.props.projectDetails, property: this.props.property, fromImage: false, rendementTab: true});
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    return (
      <div className="invest-card-wrapper">
        <a href="javascript:void(0)" className="invest-card invest-prop-card"
          onClick={() => this.handleModal()}
          projectproperyid={this.props.property.projectPropertyId}
          cptcode={this.props.property.cptCode}
          projectsocicode={this.props.property.projectSociCode}
          unitcode={this.props.property.unitCode}
          floor={this.props.property.floor}

        >
          <div className="invest-card-header">
            {this.props.property.propertyImages.length && (
              <img src={this.props.property.propertyImages[0].imageLink} alt={this.props.property.propertyImages[0].alt }/>
            )}
            <div className="invest-card-header-info">
              <div className="invest-card-city">{this.props.property.projectPropertyName}<br/>{this.props.property.numberOfRooms}{this.props.t('ro.').toUpperCase()}</div>
            </div>
          </div>
          <div className="invest-card-body">
            <div className="invest-card-price-wrapper">
              <div className="invest-card-price-container">
                <div className="price-left">
                  <div className="invest-price-big">{new Intl.NumberFormat('de-DE').format(this.props.property.price)} €</div>
                  {this.props.property.oldPrice !== 0 && this.props.property.oldPrice !== this.props.property.price && (
                    <div className="invest-price-small">{new Intl.NumberFormat('de-DE').format(this.props.property.oldPrice)} €</div>
                  )}
                </div>
                <div className="info-right">
                  <div className="invest-card-properties">
                    <ul>
                      <li>{this.props.property.surfaceM2} m²</li>
                      <li>{this.props.property.numberOfRooms}{this.props.t('ro.')}</li>
                      <li>{this.props.property.floor}° {this.props.t('Floor')}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <button className="btn btn-invest-card btn-property-card" onClick={ () => {} }>
                {this.props.t('Calculate your yield')}
              </button>
            </div>
          </div>
        </a>
      </div>
    );
  };
}

PropertyInvestItem.propTypes = {
  history: PropTypes.object,
  property: PropTypes.object,
  projectDetails: PropTypes.object,
  project: PropTypes.object,
  handleActiveProject: PropTypes.func,
  openPropertyModal: PropTypes.func,
  t: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {
      openPropertyModal,
    },
)(withTranslation()(PropertyInvestItem));
