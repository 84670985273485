import axios from 'axios';

/**
 *
 * @param {string} locationName
 * @param {string} countryCode
 * @return {Promise<AxiosResponse<any>>}
 */
export const searchLocation= async (locationName, countryCode)=>{
  const data = new URLSearchParams({
    address: locationName,
    components: `country:${countryCode}`,
  });

  // const url = `https://maps.googleapis.com/maps/api/geocode/json?${data.toString()}`; // moved to proxy due to webservice with IP filtering
  const url = `/geocode/address?${data.toString()}`; // Use own site proxy

  const request = await axios.get(url);
  return Promise.resolve(request);
};
